<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <invoicable-form
                ref="invoicableComponent"
                type="invoice"
                :is-creating-new-document="isCreatingNew"
                :document-input="currentInvoice"
                :invoicable-number-text="$t('general.invoice_number')"
                :button-text-publish="$t('addIncome.create_invoice')"
                @submit-create-draft="handleSubmitCreateDraft"
                @submit-edit="runMiddleware(editMiddleware, $event)"
                @submit-publish="runMiddleware(submitMiddleware, $event)"
            />

            <confirmation-dialog
                v-if="showDialog"
                v-model="showDialog"
                v-bind="dialogAttributes.attributes"
                @click-positive="dialogAttributes.actions.clickPositive"
                @click-negative="dialogAttributes.actions.clickNegative"
            />
        </template>
    </view-layout>
</template>

<script>
import dayjs from 'dayjs';
// --- State ---
import { mapState, mapActions } from 'vuex';
// --- Components ---
import InvoicableForm from '@/components/Forms/InvoicableForm.vue';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { invoicableFormMixin } from '@/mixins/invoicableFormMixin.js';
import { middlewareMixin } from '@/mixins/middlewareMixin';

import { currentInvoicableNewInstance } from '../../../models/invoicable';

// --- Helper ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling.js';
// import { notify } from '@/helpers/successNotification';
import { invoicableTransformCurrencyFieldToIntegers } from '@/helpers/invoicableHelper.js';
import { invoicableTypes, popupNotificationNames } from '@/config/constants';
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader.vue';
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import { informationpopupMixin } from '@/mixins/informationpopupMixin';
import { invoiceProactiveSmartnotificationsMixin } from '@/mixins/invoiceProactiveSmartnotificationsMixin';
import { getInvoice } from '@/services/invoice';
import { getQuotation } from '@/services/quotation';

export default {
    components: {
        InvoicableForm,
        ViewLayout,
        PageHeader,
        ConfirmationDialog
    },
    mixins: [
        formRulesMixin,
        invoicableFormMixin,
        middlewareMixin,
        informationpopupMixin,
        invoiceProactiveSmartnotificationsMixin
    ],

    data() {
        return {
            previousInvoice: null,
            currentInvoice: currentInvoicableNewInstance(invoicableTypes.INVOICE),
            editMiddleware: [this.invoiceProactiveSmartnotifications, this.handleEditSubmitMiddleware],
            submitMiddleware: [this.invoiceProactiveSmartnotifications, this.handleSubmit]
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        informationpopupPriority() {
            return [popupNotificationNames.INVOICESETTINGS_OPENFIRSTTIME];
        }
    },

    async created() {
        this.startLoading();
        try {
            if (this.$route.query.quotationId) {
                await this.setInvoiceFromQuotation();
            } else if (this.$route.query.copyFromInvoiceId) {
                await this.setInvoiceFromOtherInvoice();
            } else {
                if (this.$route.params.id) {
                    this.isCreatingNew = false;
                    const fetchedInvoice = await getInvoice(this.$route.params.id);
                    this.currentInvoice = fetchedInvoice;
                    this.previousInvoice = fetchedInvoice;
                } else {
                    this.isCreatingNew = true;
                    this.setInitialData();

                    const depreciationficheId = this.$route.query?.depreciationficheId;
                    if (depreciationficheId) {
                        this.currentInvoice.depreciationficheId = parseInt(depreciationficheId);
                    }
                }
            }
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
            if (e.response && (e.response.status === 404 || e.response.status === 403)) {
                this.stopLoading();
                this.$router.push({ name: 'dashboard' });
            }
        } finally {
            this.stopLoading();
        }
    },

    methods: {
        ...mapActions(['startLoading', 'stopLoading']),
        async setInvoiceFromQuotation() {
            this.isCreatingNew = true;
            this.setInitialData();

            const fetchedQuotation = await getQuotation(this.$route.query.quotationId);

            this.currentInvoice = {
                ...this.currentInvoice,
                ...this.setInvoiceFromOtherInvoicable(fetchedQuotation),
                quotationId: this.$route.query.quotationId
            };

            const depreciationficheId = this.$route.query?.depreciationficheId;
            if (depreciationficheId) {
                this.currentInvoice.depreciationficheId = parseInt(depreciationficheId);
            }
        },
        async setInvoiceFromOtherInvoice() {
            this.isCreatingNew = true;
            this.setInitialData();

            const fetchedInvoice = await getInvoice(this.$route.query.copyFromInvoiceId);

            this.currentInvoice = {
                ...this.currentInvoice,
                ...this.setInvoiceFromOtherInvoicable(fetchedInvoice)
            };

            const depreciationficheId = this.$route.query?.depreciationficheId;
            if (depreciationficheId) {
                this.currentInvoice.depreciationficheId = parseInt(depreciationficheId);
            }
        },
        setInvoiceFromOtherInvoicable(invoicable) {
            return {
                creator: this.currentUserData.company,
                customer: invoicable.customer,
                comment: invoicable.comment,
                draft: false,
                items: invoicable.items,
                discount: invoicable.discount,
                isDiscountPercentage: invoicable.isDiscountPercentage,
                amountEuroInclVAT: invoicable.amountEuroInclVAT,
                vatOnInvoicable: false,
                itemsAllSameVat: false,
                allItemsVat: 0,
                btwMedecontractant: invoicable.btwMedecontractant,
                quotationId: null,
                languageId: invoicable.languageId
            };
        },
        handleEditSubmitMiddleware(next, invoice) {
            return this.handleSubmitEdit(invoice);
        },
        handleSubmit(next, invoice) {
            return this.handleSubmitPublish(invoice);
        },
        handleAfterSubmit(newInvoice) {
            this.$store.dispatch('incomes/newInvoiceCreated');

            this.isCreatingNew = false;
            this.currentInvoice = newInvoice;

            if (newInvoice.draft === false) {
                this.increaseLocalInvoiceCounter();
            }

            this.$router.push({
                name: 'invoicable-view',
                params: {
                    id: this.currentInvoice.id,
                    type: 'invoice'
                },
                query: {
                    newlyCreated: true
                }
            });
        },
        setInitialData() {
            this.currentInvoice.date = dayjs.utc().format('YYYY-MM-DD');
            this.currentInvoice.dueDate = dayjs
                .utc()
                .add(this.currentUserData.settings.defaultPaymentDays, 'day')
                .format('YYYY-MM-DD');
        },
        setCorrectValues(invoicableCopy) {
            invoicableCopy.invoiceNumber = null;
            invoicableCopy.historical = false;
            invoicableCopy.creator = this.currentUserData.company;
            invoicableCopy.type = 'invoice';

            return invoicableTransformCurrencyFieldToIntegers(invoicableCopy, 'invoice');
        },
        increaseLocalInvoiceCounter() {
            // Increment the counter
            this.currentUserData.settings.invoiceCounter++;
        }
    }
};
</script>

<style lang="scss" scoped>
.items {
    width: 100%;
}
</style>
