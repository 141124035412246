<template>
    <div class="billed_income-table">
        <pagination-data-table
            ref="paginationDataTable"
            :loading="loading"
            :headers="headers"
            :pagination="pagination"
            :pagination-data="expenses"
            @refetch-data="$_handlePaginationRefresh"
            @sort-by="$_filtering_handleSortColumn"
            @sort-desc="$_filtering_handleSortDirectionDesc"
            @click-row="handleClickRow"
            @selected-items="markSelectedItems"
        >
            <template #[`item.date`]="{ item }">
                {{ item.date | formatDate }}
            </template>

            <template #[`item.amountEuroInclVAT`]="{ item }">
                € {{ (item.amountEuroInclVAT || 0) | numericFormat }}
            </template>

            <template #[`item.amountEuroExclVAT`]="{ item }">
                € {{ (item.amountEuroExclVAT || 0) | numericFormat }}
            </template>

            <template #[`item.dueDate`]="{ item }">
                {{ item.dueDate | formatDate }}
            </template>

            <template #[`item.supplier`]="{ item }">
                {{ item.supplier.company.name }}
            </template>

            <template #[`item.type`]="{ item }">
                {{
                    $te('expenseCategory.' + item.expensecategory.number)
                        ? $t('expenseCategory.' + item.expensecategory.number)
                        : item.expensecategory.name
                }}
            </template>
            <template #[`item.actions`]="{ item }">
                <v-menu bottom left>
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-list dense>
                            <v-list-item v-if="item.expensefile" @click="downloadExpensePDF(item.expensefile.url)">
                                <v-list-item-title>
                                    {{ $t('general.download') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item :to="/edit-expense/ + item.id">
                                <v-list-item-title>
                                    {{ $t('general.edit') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="!item.payed" @click="togglePayment(item)">
                                <v-list-item-title>
                                    {{ $t('income.add_payment') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="item.payed" @click="togglePayment(item)">
                                <v-list-item-title>
                                    {{ $t('income.remove_payment') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                @click.stop="
                                    {
                                        dialog = true;
                                        selectedExpenseId = item.id;
                                    }
                                "
                            >
                                <v-list-item-title class="red--text">
                                    {{ $t('general.delete') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </template>
        </pagination-data-table>

        <confirmation-dialog
            v-if="dialog"
            v-model="dialog"
            :loading="loading"
            :title="$t('general.delete')"
            :message="$t('general.confirm_delete_message')"
            :negative-button="$t('general.cancel')"
            :positive-button="$t('general.delete')"
            @click-positive="removeExpense"
            @click-negative="dialog = false"
        />
    </div>
</template>

<script>
// --- State ---
import { mapState, mapActions } from 'vuex';
// --- Components ---
import ConfirmationDialog from '../Dialogs/ConfirmationDialog.vue';

import { filteringMixin } from '../../mixins/filtering';

// --- Helpers ---
import { notify } from '@/helpers/successNotification';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import PaginationDataTable from './PaginationDataTable.vue';

import { deleteExpense, getExpensesNotYetBookedPagination, togglePayedExpense } from '@/services/expenses';

export default {
    components: {
        ConfirmationDialog,
        PaginationDataTable
    },
    mixins: [filteringMixin],

    data() {
        const sortableColumns = ['date', 'dueDate'];

        return {
            headers: [
                {
                    text: this.$t('general.supplier'),
                    value: 'supplier',
                    sortable: sortableColumns.includes('supplier')
                },
                { text: this.$t('general.date'), value: 'date', sortable: sortableColumns.includes('date') },
                { text: this.$t('general.type'), value: 'type', sortable: sortableColumns.includes('type') },
                {
                    text: this.$t('general.amount_incl_vat'),
                    value: 'amountEuroInclVAT',
                    sortable: sortableColumns.includes('amountEuroInclVAT')
                },
                {
                    text: this.$t('general.amount_excl_vat'),
                    value: 'amountEuroExclVAT',
                    sortable: sortableColumns.includes('amountEuroExclVAT')
                },
                { text: this.$t('general.status'), value: 'status', sortable: sortableColumns.includes('status') },
                {
                    text: this.$t('general.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            dialog: false,
            selectedExpenseId: '',
            query: {
                status: null,
                type: null
            },
            sortableColumns,
            loading: false,
            expenses: {
                data: []
            },
            selectedExpenses: []
        };
    },

    computed: {
        ...mapState('expenses', ['newExpenseEvents']),
        ...mapState(['selectedYear']),
        ...mapState('auth', ['currentUserData']),
        hasSelectedItems() {
            return Array.isArray(this.selectedExpenses) && this.selectedExpenseId.length > 0;
        }
    },

    watch: {
        newExpenseEvents: {
            handler() {
                this.wrapperFunction();
            },
            deep: true
        }
    },

    created() {
        this.wrapperFunction();
    },

    methods: {
        ...mapActions(['startLoading', 'stopLoading']),
        async removeExpense() {
            this.startLoading();
            this.loading = true;
            try {
                await deleteExpense(this.selectedExpenseId);
                notify.call(this, {
                    title: this.$t('income.deleted_invoice_succesfuly'),
                    text: this.$t('income.deleted_invoice_succesfuly')
                });

                await this.wrapperFunction();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
                this.dialog = false;
                this.loading = false;
            }
        },
        async wrapperFunction() {
            try {
                this.startLoading();
                this.loading = true;

                this.expenses = await getExpensesNotYetBookedPagination(this.selectedYear, {
                    pagination: {
                        force: true,
                        currentPage: this.pagination.currentPage,
                        limit: this.pagination.limit
                    },
                    query: this.query
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
                this.loading = false;
            }
        },
        downloadExpensePDF(url) {
            window.open(url);
        },
        async togglePayment(item) {
            try {
                this.startLoading();
                await togglePayedExpense(item.id);
                item.payed = !item.payed;

                this.$notify({
                    title: this.$t('expense.payed_success'),
                    text: this.$t('expense.payed_success'),
                    type: 'success'
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
            }
        },
        handleClickRow(item) {
            this.$emit('click-row', item);
        },
        markSelectedItems(items) {
            this.selectedExpenses = items;
        }
    }
};
</script>
