<template>
    <base-bottom-sheet
        v-if="isAddExpenseCategoryOpen && currentExpenseCategory && currentUserData"
        v-model="isAddExpenseCategoryOpen"
        transition="scale-transition"
        inset
        retain-focus
        scrollable
        eager
        class="add-income-billed-sheet"
    >
        <template #header>
            <bottom-sheet-header>
                {{
                    isCreatingNewExpenseCategory
                        ? $t('expenseCategories.add_expensecategories_title')
                        : $t('expenseCategories.edit_expensecategories_title')
                }}
            </bottom-sheet-header>
        </template>
        <template #content>
            <v-card-text>
                <v-form v-if="expensecategories" ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-select
                                    v-model="currentExpenseCategory.parentId"
                                    :label="$t('expenseCategories.maincategory')"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    item-text="name"
                                    item-value="id"
                                    :items="expensecategories"
                                    required
                                    :rules="requiredRules"
                                    :disabled="
                                        currentExpenseCategory.deletable === false && currentExpenseCategory.id !== null
                                    "
                                />
                            </v-col>
                            <v-col cols="12">
                                <text-field
                                    v-model="currentExpenseCategory.name"
                                    :label="$t('expenseCategories.name')"
                                    outlined
                                    dense
                                    required
                                    :disabled="currentExpenseCategory.deletable === false"
                                    :rules="nameRules"
                                />
                            </v-col>

                            <template v-if="$can('UPDATE', 'Expensecategory_Percentages')">
                                <v-col cols="12" :md="canHaveVAT(currentUserData.settings.vatLiable) ? 6 : 12">
                                    <text-field
                                        v-model="currentExpenseCategory.businessPercentage"
                                        name="businessPercentage"
                                        :label="$t('expenseCategories.business_percentage')"
                                        outlined
                                        dense
                                        type="number"
                                        suffix="%"
                                        persistent-hint
                                        required
                                        :rules="percentagesRules"
                                    />
                                </v-col>

                                <v-col v-if="canHaveVAT(currentUserData.settings.vatLiable)" cols="12" md="6">
                                    <text-field
                                        v-model="currentExpenseCategory.VATDeductible"
                                        name="VATDeductible"
                                        :label="$t('expenseCategories.VAT_deductible')"
                                        outlined
                                        dense
                                        type="number"
                                        suffix="%"
                                        persistent-hint
                                        required
                                        :rules="percentagesRules"
                                    />
                                </v-col>
                            </template>

                            <v-col cols="12" md="6">
                                <v-select
                                    v-model="currentExpenseCategory.frequency"
                                    :items="frequencyItems"
                                    :label="$t('expenseCategories.frequency')"
                                    outlined
                                    item-text="label"
                                    item-value="value"
                                    dense
                                    clearable
                                    persistent-hint
                                />
                            </v-col>

                            <v-col cols="12" md="6">
                                <number-field
                                    v-model="currentExpenseCategory.expectedAmount"
                                    :label="$t('expenseCategories.expectedAmount')"
                                    outlined
                                    dense
                                    clearable
                                    persistent-hint
                                    value-as-integer
                                />
                            </v-col>

                            <v-col cols="12" md="12">
                                <v-btn
                                    color="primary"
                                    data-action="submit"
                                    small
                                    :disabled="!valid"
                                    @click="handleSubmit"
                                >
                                    {{
                                        isCreatingNewExpenseCategory
                                            ? $t('expenseCategories.create')
                                            : $t('expenseCategories.save')
                                    }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <loader v-if="loading" />
        </template>
    </base-bottom-sheet>
</template>

<script>
// --- State ---
import { mapActions, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
// --- Components ---
import BottomSheetHeader from './BottomSheetHeader';
import NumberField from '../FormComponents/NumberField.vue';
import Loader from '../Loader';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
// --- Constants ---
import { BELGIAN_COMPANY, COMPANY_INSIDE_EU, COMPANY_OUTSIDE_EU, PRIVATE } from '@/config/constants';
// --- Helper ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { percentageValidator, required } from '@/helpers/validationRules';
import { isVatLiable } from '@/helpers/VATHelper';

export default {
    components: {
        BottomSheetHeader,
        Loader,
        NumberField
    },

    mixins: [formRulesMixin],

    data() {
        return {
            valid: true,
            loading: false,
            BELGIAN_COMPANY: BELGIAN_COMPANY,
            typeOfSupplier: [
                {
                    label: this.$t('customer.private'),
                    type: PRIVATE
                },
                {
                    label: this.$t('customer.belgian_company'),
                    type: BELGIAN_COMPANY
                },
                {
                    label: this.$t('customer.company_inside_eu'),
                    type: COMPANY_INSIDE_EU
                },
                {
                    label: this.$t('customer.company_outside_eu'),
                    type: COMPANY_OUTSIDE_EU
                }
            ],
            expensecategories: null,
            frequencyItems: [
                {
                    label: this.$t('expenseCategories.frequencies.yearly'),
                    value: 'YEARLY'
                },
                {
                    label: this.$t('expenseCategories.frequencies.monthly'),
                    value: 'MONTHLY'
                },
                {
                    label: this.$t('expenseCategories.frequencies.quarterly'),
                    value: 'QUARTERLY'
                },
                {
                    label: this.$t('expenseCategories.frequencies.halfyearly'),
                    value: 'HALFYEARLY'
                }
            ]
        };
    },
    computed: {
        ...mapFields('bottomSheets', [
            'isAddExpenseCategoryOpen',
            'currentExpenseCategory',
            'isCreatingNewExpenseCategory'
        ]),
        ...mapState('auth', ['currentUserData'])
    },
    created() {
        this.loading = true;
        this.getExpenseCategories()
            .then((expensecategories) => {
                this.expensecategories = expensecategories;
            })
            .finally(() => {
                this.loading = false;
            });
    },
    methods: {
        ...mapActions('expenseCategories', [
            'createExpenseCategories',
            'updateExpenseCategories',
            'getExpenseCategories'
        ]),
        canHaveVAT: isVatLiable,

        resetForm() {
            this.$refs.form.reset();
        },
        async handleSubmit() {
            try {
                this.loading = true;

                if (!isVatLiable(this.currentUserData.settings.vatLiable)) {
                    this.currentExpenseCategory.VATDeductible = 0;
                }

                if (this.isCreatingNewExpenseCategory) {
                    await this.createExpenseCategories(this.currentExpenseCategory);
                    notify.call(this, {
                        title: this.$t('expenseCategories.succesfuly_added'),
                        text: this.$t('expenseCategories.succesfuly_added')
                    });
                    this.$emit('new-item');
                } else {
                    await this.updateExpenseCategories(this.currentExpenseCategory);
                    notify.call(this, {
                        title: this.$t('expenseCategories.succesfuly_updated'),
                        text: this.$t('expenseCategories.succesfuly_updated')
                    });
                    this.$emit('updated-item');
                }

                // Close bottom sheet when submit successful.
                this.isAddExpenseCategoryOpen = false;
                this.resetForm();
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        },
        VATDeductibleRules(stringError) {
            if (!this.currentExpenseCategory.businessPercentage) {
                return [required, percentageValidator];
            }
            return [
                function (value) {
                    return value <= this.currentExpenseCategory.businessPercentage || stringError;
                }.bind(this),
                required,
                percentageValidator
            ];
        },
        businessExpenseRules(stringError) {
            if (!this.currentExpenseCategory.VATDeductible) {
                return [required, percentageValidator];
            }

            return [
                function (value) {
                    return value >= this.currentExpenseCategory.VATDeductible || stringError;
                }.bind(this),
                required,
                percentageValidator
            ];
        }
    }
};
</script>
