<template>
    <div class="creditnotes-table">
        <pagination-data-table
            v-if="creditnoteIncomes.data"
            id="creditnotesTable"
            ref="paginationDataTable"
            :loading="loading"
            :headers="headers"
            :pagination="pagination"
            :pagination-data="creditnoteIncomes"
            @refetch-data="$_handlePaginationRefresh"
            @sort-by="$_filtering_handleSortColumn"
            @sort-desc="$_filtering_handleSortDirectionDesc"
            @click-row="handleClickRow"
        >
            <template #[`item.creditnoteNumber`]="{ item }">
                {{ item.draft === false ? item.creditnoteNumber : '-' }}
            </template>

            <template #[`item.invoiceNumber`]="{ item }">
                <span v-if="item.invoiceId">
                    <v-btn small color="primary" :to="/edit-invoice/ + item.invoiceId">{{ item.invoiceNumber }}</v-btn>
                </span>
                <span v-else>
                    {{ item.invoiceNumber }}
                </span>
            </template>

            <template #[`item.date`]="{ item }">
                {{ item.date | formatDate }}
            </template>
            <template #[`item.amountEuroExclVAT`]="{ item }">
                € {{ item.amountEuroExclVAT || 0 | numericFormat }}
            </template>
            <template #[`item.amountEuroInclVAT`]="{ item }">
                € {{ item.amountEuroInclVAT || 0 | numericFormat }}
            </template>
            <template #[`item.total`]="{ item }"> € {{ item.amountEuroInclVAT || 0 | numericFormat }} </template>

            <template #[`item.actions`]="{ item }">
                <v-menu bottom left>
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" data-action="open-menu" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-list dense>
                            <v-list-item v-if="item.url" @click="downloadIncomePDF(item.url)">
                                <v-list-item-title>
                                    {{ $t('general.download') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="item.historical === false" :to="/edit-creditnote/ + item.id">
                                <v-list-item-title>
                                    {{ $t('general.edit') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="item.historical" :to="/edit-historical-creditnote/ + item.id">
                                <v-list-item-title>
                                    {{ $t('general.edit') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="!item.payedback && !item.draft" @click="togglePayment(item)">
                                <v-list-item-title>
                                    {{ $t('income.add_payment_back') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="item.payedback && !item.draft" @click="togglePayment(item)">
                                <v-list-item-title>
                                    {{ $t('income.undo_payment_back') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="!item.sendtocustomer && item.draft === false"
                                @click="toggleSendStatus(item)"
                            >
                                <v-list-item-title>
                                    {{ $t('income.did_send_to_customer') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="item.sendtocustomer && item.draft === false"
                                @click="toggleSendStatus(item)"
                            >
                                <v-list-item-title>
                                    {{ $t('income.did_not_send_to_customer') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                data-action="delete"
                                @click.stop="
                                    {
                                        dialog = true;
                                        selectedIncome = item.id;
                                    }
                                "
                            >
                                <v-list-item-title class="red--text">
                                    {{ $t('general.delete') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </template>
        </pagination-data-table>

        <confirmation-dialog
            v-if="dialog"
            v-model="dialog"
            :title="$t('general.delete')"
            :message="$t('general.confirm_delete_message')"
            :negative-button="$t('general.cancel')"
            :positive-button="$t('general.delete')"
            @click-positive="removeCreditnote"
            @click-negative="dialog = false"
        />
    </div>
</template>

<script>
// --- State ---
import { mapActions, mapState } from 'vuex';
// --- Components ---
import ConfirmationDialog from '../Dialogs/ConfirmationDialog.vue';
import PaginationDataTable from './PaginationDataTable.vue';
// --- helpers ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { filteringMixin } from '../../mixins/filtering';

import {
    deleteCreditnote,
    getCreditnotesNotYetBookedPagination,
    togglePayedCreditnote,
    toggleSendToCustomerCreditnote
} from '@/services/creditnote';

import { isVatLiable } from '@/helpers/VATHelper';

export default {
    components: {
        ConfirmationDialog,
        PaginationDataTable
    },
    mixins: [filteringMixin],

    clickable: {
        type: Boolean,
        default: false
    },
    data() {
        const currentUserData = this.$store.state.auth.currentUserData;

        const VATLiable = isVatLiable(currentUserData.settings.vatLiable);

        let sortableColumns = ['date', 'dueDate', 'invoiceNumber'];

        if (VATLiable) {
            sortableColumns.push('amountEuroInclVAT', 'amountEuroExclVAT');
        } else {
            sortableColumns.push('total');
        }

        const headers = [
            {
                text: this.$t('accountantTerms.credit_note_number'),
                align: 'start',
                value: 'creditnoteNumber'
            },
            {
                text: this.$t('general.invoice'),
                value: 'invoiceNumber'
            },
            {
                text: this.$t('general.client'),
                value: 'customer.company.name'
            },
            { text: this.$t('general.date'), value: 'date' },
            VATLiable
                ? [
                      {
                          text: this.$t('general.total_excl_vat'),
                          value: 'amountEuroExclVAT',
                          sortable: sortableColumns.includes('amountEuroExclVAT')
                      },
                      {
                          text: this.$t('general.total_incl_vat'),
                          value: 'amountEuroInclVAT',
                          sortable: sortableColumns.includes('amountEuroInclVAT')
                      }
                  ]
                : {
                      text: this.$t('general.total'),
                      value: 'amountEuroExclVAT',
                      sortable: sortableColumns.includes('total')
                  },
            { text: this.$t('general.status'), value: 'status', sortable: sortableColumns.includes('status') },

            {
                text: this.$t('general.actions'),
                value: 'actions',
                sortable: false
            }
        ].flat();

        return {
            headers,
            selectedIncome: '',
            dialog: false,
            creditnoteIncomes: {
                data: []
            },
            query: {
                status: null
            },
            sortableColumns
        };
    },

    computed: {
        ...mapState(['selectedYear'])
    },

    created() {
        this.wrapperFunction();
    },
    methods: {
        ...mapActions(['startLoading', 'stopLoading']),
        ...mapActions('incomes', ['deleteIncome']),

        async removeCreditnote() {
            this.startLoading();
            this.dialog = false;
            try {
                await deleteCreditnote(this.selectedIncome);
                await this.wrapperFunction();
                notify.call(this, {
                    title: this.$t('general.succesfuly_deleted'),
                    text: this.$t('general.succesfuly_deleted')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
            }
        },
        async wrapperFunction() {
            this.loading = true;
            this.creditnoteIncomes = await getCreditnotesNotYetBookedPagination(this.selectedYear, {
                pagination: {
                    force: true,
                    currentPage: this.pagination.currentPage,
                    limit: this.pagination.limit
                },
                query: this.query
            });
            this.loading = false;
        },
        async togglePayment(item) {
            try {
                this.startLoading();
                await togglePayedCreditnote(item.id);
                item.payedback = !item.payedback;

                notify.call(this, {
                    title: this.$t('income.payed_success'),
                    text: this.$t('income.payed_success')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
                this.dialogDeletePayment = false;
            }
        },
        async toggleSendStatus(item) {
            try {
                this.startLoading();
                await toggleSendToCustomerCreditnote(item.id);
                item.sendtocustomer = !item.sendtocustomer;

                notify.call(this, {
                    title: this.$t('income.updated_success'),
                    text: this.$t('income.updated_success')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
                this.dialogDeletePayment = false;
            }
        },
        downloadIncomePDF(url) {
            window.open(url);
        },
        handleClickRow(item) {
            this.$emit('click-row', item);
        }
    }
};
</script>
