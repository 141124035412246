<template>
    <base-bottom-sheet
        v-if="currentUserData"
        v-model="isSelectInvoiceitemOpen"
        transition="scale-transition"
        inset
        retain-focus
        scrollable
        eager
        class="add-income-billed-sheet"
    >
        <template #header>
            <v-card-text>
                <bottom-sheet-header :show-add="true" @new-item="handleClickNew">
                    {{ $t('general.select_invoiceitems') }}
                </bottom-sheet-header>
            </v-card-text>
        </template>
        <template #content>
            <invoiceitem-table @click-row="selectInvoiceitem" />
            <loader v-if="loading" />
            <add-invoiceitem :units="units" />
        </template>
    </base-bottom-sheet>
</template>

<script>
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { mapActions, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import BottomSheetHeader from '../BottomSheets/BottomSheetHeader';
import InvoiceitemTable from '../DataTables/InvoiceitemTable.vue';
import Loader from '../Loader';
import AddInvoiceitem from './AddInvoiceitem.vue';

export default {
    components: {
        Loader,
        InvoiceitemTable,
        BottomSheetHeader,
        AddInvoiceitem
    },

    mixins: [formRulesMixin],

    data() {
        return {
            valid: true,
            loading: false,
            units: []
        };
    },
    computed: {
        ...mapState('auth', ['currentUserData']),
        ...mapFields('bottomSheets', ['isSelectInvoiceitemOpen'])
    },
    created() {
        this.loading = true;
        this.fetchUnits().finally(() => {
            this.loading = false;
        });
    },
    methods: {
        ...mapActions('bottomSheets', ['closeSelectInvoiceitem', 'newInvoiceitem']),
        ...mapActions('units', ['getUnits']),
        resetForm() {
            this.$refs.form.reset();
        },
        selectInvoiceitem(item) {
            this.$emit('select-invoiceitem', item);
            this.closeSelectInvoiceitem();
        },
        handleClickNew() {
            this.newInvoiceitem({
                VATPercentage: this.currentUserData.settings.defaultVATPercentage
            });
        },
        fetchUnits() {
            return this.getUnits().then((units) => (this.units = units));
        }
    }
};
</script>
