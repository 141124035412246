
import { PropOptions } from 'vue';
import UnitTableVue from './DataTables/UnitTable.vue';
import SelectTableComponent from './SelectTableComponent.vue';
import { formRulesMixin } from '../mixins/formRulesMixin';
import mixins from 'vue-typed-mixins';
import { mapActions } from 'vuex';
import SearchInputValue from './SearchInputValue.vue';
import Editor from '@/components/Editor/DefaultEditor.vue';
import { CreateInvoiceItem } from '../services/invoiceitems.types';
import { DeepNullable } from '../helpers/types';
import { Unit } from '../services/units.types';
import { isNil } from 'lodash';

export default mixins(formRulesMixin).extend({
    components: { SelectTableComponent, SearchInputValue, Editor },
    mixins: [formRulesMixin],
    props: {
        inputData: {
            required: true
        } as PropOptions<any>,
        isCreatingNewInvoiceitem: {
            required: true
        } as PropOptions<boolean>,
        canUserHaveVat: {
            required: true
        } as PropOptions<boolean>
    },
    data() {
        return {
            currentInvoiceitem: null as null | DeepNullable<CreateInvoiceItem>,
            unitTable: UnitTableVue,
            valid: false,
            vatPercentages: [0, 6, 12, 21],
            priceInclVAT: null as null | number
        };
    },

    watch: {
        inputData: {
            handler(newValue) {
                this.currentInvoiceitem = newValue;
            },
            immediate: true
        }
    },

    methods: {
        ...mapActions('bottomSheets', ['newUnit']),
        handleSubmit() {
            this.$emit('submit', this.inputData);
        },
        searchUnit() {
            (this.$refs.selectTableComponent as any).open();
        },
        setInclVAT(totalExclVAT: number, item: DeepNullable<CreateInvoiceItem>) {
            if (item.VATPercentage === null) {
                return;
            }

            this.priceInclVAT = totalExclVAT * (item.VATPercentage !== null ? item.VATPercentage / 100 + 1 : 1);
        },
        setExclVAT(totalInclVAT: number, item: DeepNullable<CreateInvoiceItem>) {
            if (item.VATPercentage === null) {
                return;
            }

            item.priceExclVAT = totalInclVAT / (1 + (item.VATPercentage !== null ? item.VATPercentage : 1) / 100);
        },
        handleSelectedUnit(unit: Unit) {
            if (isNil(this.currentInvoiceitem)) {
                throw new Error('Not defined');
            }

            this.currentInvoiceitem.measuringUnit = unit.plural;

            (this.$refs.selectTableComponent as any).close();
        }
    }
});
