<template>
    <div>
        <div v-for="(item, index) in document.items" :key="index" class="mobile-invoice-item">
            <div class="mobile-invoice-item__title-wrapper">
                <h5>{{ item.name ? item.name : '-' }}</h5>

                <div class="mobile-invoice-item__icons">
                    <v-btn icon color="primary" @click.stop="editInvoiceitem(index)">
                        <v-icon small> mdi-pencil </v-icon>
                    </v-btn>
                    <v-btn icon color="red" @click.stop="deleteItem(index)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </div>
            </div>

            <div class="mobile-invoice-item__content-wrapper">
                <div class="items-information">
                    <span class="unit">Aantal: {{ item.amount }}</span>
                    <span class="unit">Prijs: €{{ item.priceExclVAT }}</span>
                    <template v-if="item.measuringUnit"
                        ><span class="unit">Eenheid: {{ item.measuringUnit }}</span></template
                    >
                    <span class="unit">BTW: {{ item.VATPercentage }}%</span>

                    <template v-if="item.discount">
                        <span class="unit"
                            >Korting: {{ !item.isDiscountPercentage ? '€' : '' }}{{ item.discount
                            }}{{ item.isDiscountPercentage ? '%' : '' }}</span
                        >
                    </template>
                </div>
                <div class="total">
                    {{ calculateTotalPriceExclVATItem(item) | numericFormat | addEuroSign }}
                </div>
            </div>
        </div>
        <div class="mobile-invoice-item-container">
            <v-btn id="add-item" small outlined @click.stop="addNewItem">
                <v-icon small> mdi-plus </v-icon>
                {{ addInvoicableItemText(type) }}
            </v-btn>
        </div>

        <v-row>
            <v-col cols="12" md="12">
                <v-simple-table id="invoiceItemTable" dense>
                    <template #default>
                        <tr>
                            <td colspan="6" class="text-right">
                                {{ $t('general.amount_money') }}
                            </td>
                            <td class="text-center">
                                <h3>
                                    {{ calculations.totalExclVatWithoutDiscount | numericFormat | addEuroSign }}
                                </h3>
                            </td>
                            <td></td>
                        </tr>

                        <tr>
                            <td colspan="6" class="text-right">
                                {{ $t('general.discount_items') }}
                            </td>
                            <td class="text-center">
                                <h3>
                                    {{ calculations.discountItems | numericFormat | addEuroSign }}
                                </h3>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="6" class="text-right">
                                {{ $t('general.amount_excl_vat') }}
                            </td>
                            <td class="text-center">
                                <h3>
                                    {{
                                        (calculations.totalAmountInclVatWithDiscount - calculations.totalVATOnInvoice)
                                            | numericFormat
                                            | addEuroSign
                                    }}
                                </h3>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="6" class="text-right">
                                {{ $t('accountantTerms.btw_amount') }}
                            </td>
                            <td class="text-center">
                                <h3>
                                    {{ calculations.totalVATOnInvoice | numericFormat | addEuroSign }}
                                </h3>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="6" class="text-right">
                                {{ $t('general.amount_incl_vat') }}
                            </td>
                            <td class="text-center">
                                <h3>
                                    {{ calculations.totalAmountInclVatWithDiscount | numericFormat | addEuroSign }}
                                </h3>
                            </td>
                            <td></td>
                        </tr>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </div>
</template>
<script>
// --- State ---
import { mapState } from 'vuex';
// --- Components ---
// --- Mixins ---
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
// --- Helper ---
import { calculateDiscountItem } from '@/common';

// --- Constants ---
import { BELGIAN_COMPANY, COMPANY_INSIDE_EU, COMPANY_OUTSIDE_EU, PRIVATE } from '@/config/constants';

import { isVatLiable } from '@/helpers/VATHelper';

export default {
    components: {},

    mixins: [formRulesMixin, screenSizeMixin],

    props: {
        value: {
            required: true,
            type: Object
        },
        calculations: {
            required: true,
            type: Object
        },
        type: {
            required: true,
            type: String
        },
        historical: {
            default: false,
            type: Boolean
        },
        isCreatingNewDocument: {
            default: true,
            type: Boolean
        }
    },

    data() {
        return {
            PRIVATE: PRIVATE,
            BELGIAN_COMPANY: BELGIAN_COMPANY,
            COMPANY_INSIDE_EU: COMPANY_INSIDE_EU,
            COMPANY_OUTSIDE_EU: COMPANY_OUTSIDE_EU,
            discountItems: 0,
            totalVATOnInvoice: 0,
            totalExclVatWithoutDiscount: 0,
            totalAmountInclVatWithDiscount: 0
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        document: {
            get() {
                return this.value;
            },
            set(localDocument) {
                this.$emit('input', localDocument);
            }
        },
        isVATLiable() {
            return isVatLiable(this.currentUserData.settings.vatLiable);
        }
    },

    watch: {
        document: {
            handler(newVal) {
                this.$emit('input', newVal);
            },
            deep: true
        }
    },

    methods: {
        addInvoicableItemText(type) {
            if (type === 'quotation') {
                return this.$t('addIncome.addInvoicable.add_quotation_item');
            }

            return this.$t('addIncome.addInvoicable.add_item');
        },
        deleteItem(index) {
            this.$emit('delete-item', index);
        },
        addNewItem() {
            this.$emit('add-new-item');
        },
        editInvoiceitem(index) {
            this.$emit('edit-item', index);
        },
        calculateTotalPriceExclVATItem(item) {
            return item.priceExclVAT * item.amount - calculateDiscountItem(item);
        }
    }
};
</script>

<style lang="scss" scoped>
.items {
    width: 100%;
}

#invoiceItemTable {
    table {
        th {
            padding: 5px 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        }
        td {
            padding: 8px 10px;
        }
    }
}

tbody {
    tr:hover {
        background-color: transparent !important;
    }
}

td.td-price {
    padding-left: 0px;
    padding-right: 0px;
}

td.td-action {
    vertical-align: baseline;
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 1%;
    white-space: nowrap;
}

td.no-items-td {
    padding: 20px !important;
}

td.add-item-td {
    padding: 10px !important;
}

.mobile-invoice-item-container {
    padding: 20px 20px;
    margin-top: 10px;
}

.mobile-invoice-item {
    padding: 20px 20px;
    border-bottom: 1px solid $grey-100;

    button {
        height: unset !important;
        width: unset !important;
    }

    &__title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;

        h5 {
            font-size: 1rem;
        }
    }
    &__content-wrapper {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;

        display: flex;
        justify-content: space-between;
        .items-information {
        }

        .total {
            min-width: 100px;
            text-align: right;
        }
    }
}

.unit {
    display: inline-block;
    margin-right: 5px;
}
</style>
