<template>
    <div data-id="CustomersTable" class="billed_income-table">
        <v-row class="filtering-container">
            <v-col cols="12" md="12">
                <pagination-search v-model="query.q" data-action="search" />
            </v-col>
        </v-row>
        <pagination-data-table
            v-if="customers.data"
            id="CustomerPaginationTable"
            ref="paginationDataTable"
            :headers="headers"
            :pagination="pagination"
            :pagination-data="customers"
            :loading="loading"
            sort-by="date"
            :class="{ clickableFirstItem: clickable }"
            @refetch-data="$_handlePaginationRefresh"
            @sort-by="$_filtering_handleSortColumn"
            @sort-desc="$_filtering_handleSortDirectionDesc"
            @click-row="handleClickRow"
        >
            <template #[`item.address`]="{ item }">
                {{ getFormattedAddress(item.address) }}
            </template>
            <template #[`item.company.number`]="{ item }">
                <template v-if="item.type !== PRIVATE"> {{ item.address.country }}{{ item.company.number }} </template>
            </template>

            <template #[`item.actions`]="{ item }">
                <v-menu bottom left>
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-list dense>
                            <v-list-item @click="openEditCustomerDialog(item)">
                                <v-list-item-title>
                                    {{ $t('general.edit') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                @click.stop="
                                    {
                                        dialog = true;
                                        selectedCustomer = item.id;
                                    }
                                "
                            >
                                <v-list-item-title class="red--text">
                                    {{ $t('general.delete') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </template>
        </pagination-data-table>

        <confirmation-dialog
            v-if="dialog"
            v-model="dialog"
            :title="$t('general.delete')"
            :message="$t('general.confirm_delete_message')"
            :loading="loading"
            :negative-button="$t('general.cancel')"
            :positive-button="$t('general.delete')"
            @click-positive="removeCustomer"
            @click-negative="dialog = false"
        />

        <add-customer-modal
            v-if="openAddCustomerDialog"
            v-model="openAddCustomerDialog"
            :current-customer="selectedCustomerForEdit"
        />
    </div>
</template>

<script>
// --- State ---
import { mapState, mapActions } from 'vuex';
// --- Components ---
import ConfirmationDialog from '../Dialogs/ConfirmationDialog.vue';
import PaginationSearch from '../PaginationSearch.vue';
// --- Helper ---
import { formatAddress } from '@/helpers/stringFormats';
import { filteringMixin, tableName } from '../../mixins/filtering';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import PaginationDataTable from './PaginationDataTable.vue';
import { clickableRowTableMixin } from '../../mixins/clickableRowTableMixin';

import { PRIVATE } from '@/config/constants';
import AddCustomerModal from '@/views/customers/add/components/AddCustomer.modal.vue';
import { sendDeleteCustomerRequest, sendGetAllCustomersPaginationRequest } from '@/services/customer';

export default {
    components: {
        ConfirmationDialog,
        PaginationSearch,
        PaginationDataTable,
        AddCustomerModal
    },
    mixins: [filteringMixin, clickableRowTableMixin],

    props: {
        clickable: {
            type: Boolean,
            default: false
        }
    },

    data() {
        const sortableColumns = [];

        return {
            PRIVATE,
            headers: [
                {
                    text: this.$t('general.company_name'),
                    align: 'start',
                    value: 'company.name',
                    sortable: sortableColumns.includes('company.name')
                },
                {
                    text: this.$t('general.company_number'),
                    value: 'company.number',
                    sortable: sortableColumns.includes('company.number')
                },
                {
                    text: this.$t('general.email'),
                    value: 'company.email',
                    sortable: sortableColumns.includes('company.email')
                },
                {
                    text: this.$t('general.address'),
                    value: 'address',
                    sortable: sortableColumns.includes('address')
                },
                {
                    text: this.$t('general.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            dialog: false,
            loading: false,
            toDeleteUid: '',
            selectedCustomer: '',
            customers: {
                data: []
            },
            [tableName]: 'customerTable',
            sortableColumns,
            openAddCustomerDialog: false,
            selectedCustomerForEdit: null
        };
    },

    created() {
        this.wrapperFunction();
    },

    computed: {
        ...mapState('customers', ['newCustomerEvents'])
    },

    watch: {
        newCustomerEvents: {
            deep: true,
            handler: function () {
                this.wrapperFunction();
            }
        }
    },

    methods: {
        ...mapActions(['startLoading', 'stopLoading']),
        getFormattedAddress(address) {
            return formatAddress(address);
        },
        async wrapperFunction() {
            this.loading = true;
            this.customers = await sendGetAllCustomersPaginationRequest({
                pagination: {
                    force: true,
                    currentPage: this.pagination.currentPage,
                    limit: this.pagination.limit
                },
                query: this.query
            });
            this.loading = false;
        },
        async removeCustomer() {
            this.loading = true;
            try {
                await sendDeleteCustomerRequest(this.selectedCustomer);

                await this.wrapperFunction();
                notify.call(this, {
                    title: this.$t('customer.customer_succesfuly_deleted'),
                    text: this.$t('customer.customer_succesfuly_deleted')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
                this.dialog = false;
            }
        },
        handleClickRow(selectedCustomerRow) {
            this.openEditCustomerDialog(selectedCustomerRow);
        },
        openEditCustomerDialog(customer) {
            this.selectedCustomerForEdit = this.mapCustomerToEditModalCustomer(customer);
            this.openAddCustomerDialog = true;
        },
        mapCustomerToEditModalCustomer(customer) {
            return customer;
        }
    }
};
</script>
