<template>
    <v-form
        :id="isCreatingNewCustomer ? 'creatingCustomerForm' : 'updatingCustomerForm'"
        ref="form"
        v-model="valid"
        autocomplete="off"
    >
        <v-row>
            <v-col cols="12" :md="currentCustomer.type === COMPANY_INSIDE_EU ? 6 : 12">
                <v-select
                    v-model="currentCustomer.type"
                    class="typeOfCustomer required"
                    :items="typeOfCustomer"
                    item-text="label"
                    item-value="type"
                    hide-details="auto"
                    outlined
                    dense
                    clearable
                    :label="$t('addCustomer.type')"
                    required
                    :rules="requiredRules"
                    :disabled="!isCreatingNewCustomer && currentCustomer.type !== null"
                />
            </v-col>

            <template v-if="currentCustomer.type === COMPANY_INSIDE_EU">
                <v-col
                    cols="12"
                    :class="{ removePadding: currentCustomer.type !== COMPANY_INSIDE_EU }"
                    :md="currentCustomer.type === COMPANY_INSIDE_EU ? 6 : 12"
                    data-select-container="addressCountry"
                >
                    <v-select
                        v-if="currentCustomer.type === COMPANY_INSIDE_EU"
                        v-model="currentCustomer.address.country"
                        :label="$t('forms.country')"
                        outlined
                        dense
                        :items="countrycodes"
                        item-text="name"
                        item-value="value"
                        hide-details="auto"
                        data-form="addressCountry"
                    />
                </v-col>
            </template>

            <template v-if="canShowMainInforForm">
                <v-col v-if="currentCustomer.type && currentCustomer.type !== PRIVATE" cols="12" md="6">
                    <div class="d-flex align-center">
                        <company-number-field
                            v-model="currentCustomer.company.number"
                            :selected-country="selectedCountry"
                            :label="$t('general.company_number')"
                            :hint="companyNumberHintText"
                            data-form="companyNumber"
                            :rules="
                                canHaveEUCompanyNumber(currentCustomer.type)
                                    ? companyNumberRulesNotRequired(currentCustomer.address.country)
                                    : []
                            "
                            @change="lookupCompany"
                        />
                        <v-progress-circular
                            v-if="lookingUpCompany"
                            class="ml-3"
                            size="20"
                            indeterminate
                            color="primary"
                        />
                    </div>
                </v-col>

                <v-col cols="12" md="6">
                    <text-field
                        v-model="currentCustomer.company.name"
                        :label="currentCustomer.type === PRIVATE ? $t('general.name') : $t('general.company_name')"
                        outlined
                        dense
                        hide-details="auto"
                        required
                        :rules="nameRules"
                        data-form="companyName"
                    />
                </v-col>

                <v-col cols="12" md="6">
                    <text-field
                        v-model="currentCustomer.company.email"
                        :label="$t('general.email')"
                        outlined
                        dense
                        hide-details="auto"
                        type="email"
                        :rules="emailNotRequiredRules"
                        data-form="companyEmail"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <text-field
                        v-model="currentCustomer.company.phone"
                        :label="$t('general.phone')"
                        outlined
                        dense
                        hide-details="auto"
                        type="text"
                        data-form="companyPhone"
                    />
                </v-col>

                <v-col cols="12" md="12" class="removePadding" />

                <v-col cols="12" md="6">
                    <text-field
                        v-model="currentCustomer.address.street"
                        :label="$t('forms.streetName')"
                        outlined
                        dense
                        hide-details="auto"
                        data-form="addressStreet"
                    />
                </v-col>

                <v-col cols="12" md="3">
                    <text-field
                        v-model="currentCustomer.address.number"
                        :label="$t('forms.houseNumber')"
                        outlined
                        dense
                        hide-details="auto"
                        data-form="addressNumber"
                    />
                </v-col>

                <v-col cols="12" md="3">
                    <text-field
                        v-model="currentCustomer.address.busNumber"
                        :label="$t('forms.busNumber')"
                        outlined
                        dense
                        hide-details="auto"
                        data-form="busNumber"
                    />
                </v-col>

                <v-col cols="12" md="6">
                    <text-field
                        v-model="currentCustomer.address.zipcode"
                        :label="$t('forms.zipcode')"
                        outlined
                        dense
                        hide-details="auto"
                        data-form="addressZipcode"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <text-field
                        v-model="currentCustomer.address.city"
                        :label="$t('forms.city')"
                        outlined
                        dense
                        hide-details="auto"
                        data-form="addressCity"
                    />
                </v-col>

                <v-col cols="12">
                    <text-area
                        v-model="currentCustomer.notes"
                        :label="$t('forms.notes')"
                        outlined
                        dense
                        hide-details="auto"
                        data-form="notes"
                    />
                </v-col>

                <v-col cols="12" md="12">
                    <submit-button
                        :data-action="isCreatingNewCustomer ? 'createCustomer' : 'editCustomer'"
                        color="primary"
                        :disabled="!valid"
                        @click="handleSubmit"
                    >
                        {{ isCreatingNewCustomer ? $t('customer.add_customer') : $t('customer.save_customer') }}
                    </submit-button>
                </v-col>
            </template>
        </v-row>
    </v-form>
</template>

<script>
// --- State ---
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { lookupCompanynumberMixin } from '@/mixins/lookupCompanynumberMixin';

// --- Constants ---
import { PRIVATE, BELGIAN_COMPANY, COMPANY_INSIDE_EU, COMPANY_OUTSIDE_EU } from '@/config/constants';
// --- Helper ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { getEuCountries } from '@/helpers/countries';

import _, { isEmpty } from 'lodash';
import SubmitButton from '@/components/SubmitButton.vue';

import { companyLookup } from '@/services/customer';

import { checkValidCompanyNumber } from '@/helpers/checkValidCompanyNumber';
import CompanyNumberField from '@/components/FormComponents/CompanyNumberField.vue';

export default {
    components: {
        SubmitButton,
        CompanyNumberField
    },

    mixins: [formRulesMixin, lookupCompanynumberMixin],

    props: {
        value: { required: true }
    },

    data() {
        return {
            lookingUpCompany: false,
            valid: true,
            BELGIAN_COMPANY,
            PRIVATE,
            COMPANY_INSIDE_EU,
            COMPANY_OUTSIDE_EU,
            typeOfCustomer: [
                {
                    label: this.$t('customer.private'),
                    type: PRIVATE
                },
                {
                    label: this.$t('customer.belgian_company'),
                    type: BELGIAN_COMPANY
                },
                {
                    label: this.$t('customer.company_inside_eu'),
                    type: COMPANY_INSIDE_EU
                },
                {
                    label: this.$t('customer.company_outside_eu'),
                    type: COMPANY_OUTSIDE_EU
                }
            ],
            countrycodes: _.sortBy(getEuCountries.call(this), 'name')
        };
    },
    computed: {
        currentCustomer: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        isCreatingNewCustomer() {
            return Boolean(this.currentCustomer.id) === false;
        },
        selectedCountry() {
            const country = this.currentCustomer.address.country;

            if (this.currentCustomer.type === BELGIAN_COMPANY) {
                return 'BE';
            }

            return country;
        },
        canShowMainInforForm() {
            const currentCustomerType = this.currentCustomer.type;
            const currentCustomerCountry = this.currentCustomer.address.country;

            if (currentCustomerType == null) {
                return false;
            }

            if (currentCustomerType === COMPANY_INSIDE_EU) {
                if (isEmpty(currentCustomerCountry)) {
                    return false;
                }
            }

            return true;
        },
        companyNumberHintText() {
            if (this.currentCustomer.type === BELGIAN_COMPANY) {
                return this.$i18n.t('general.search_by_company_number');
            }

            return '';
        }
    },

    watch: {
        currentCustomer(newValue, oldValue) {
            if (newValue !== oldValue) {
                throw new Error('Must reinstantiate form when using different data');
            }
        },
        'currentCustomer.type'(newValue) {
            // ! Form must be re-insantiated for each customer, because when refreshing the data with another customer, this trigger wel be triggered hence resetting the next form.
            if (newValue === PRIVATE) {
                this.currentCustomer.company.number = null;
                this.currentCustomer.address.country = null;
            } else if (newValue === BELGIAN_COMPANY) {
                this.currentCustomer.address.country = 'BE';
            } else if (newValue === COMPANY_INSIDE_EU) {
                this.currentCustomer.address.country = null;
                this.currentCustomer.company.number = null;
            }
        }
    },

    methods: {
        canHaveEUCompanyNumber(type) {
            return [BELGIAN_COMPANY, COMPANY_INSIDE_EU].includes(type);
        },
        canLookupCompanyNumber(companyNumber, companyType) {
            if (companyType !== BELGIAN_COMPANY) {
                return false;
            }

            if (!checkValidCompanyNumber(companyNumber)) {
                return false;
            }

            return true;
        },

        async lookupCompany() {
            try {
                const companyNumber = this.currentCustomer.company.number;

                if (!this.canLookupCompanyNumber(companyNumber, this.currentCustomer.type)) {
                    return;
                }

                this.lookingUpCompany = true;

                const result = await companyLookup(companyNumber);

                this.fillInMissingCompanyInfoBasedOnLookup(this.currentCustomer, result);
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.lookingUpCompany = false;
            }
        },
        handleSubmit() {
            this.$emit('submit');
        }
    }
};
</script>
