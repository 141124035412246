import { changeIncomeErrorHandler } from '@/helpers/changeIncome/changeincome';
import { convertToLocaleValuta } from '@/helpers/number-helpers/numberHelpers';
import { changeIncomeCheck } from '@/services/proactivesmartnotifications';
import { calculateProratedVatExemptBorder } from '@dexxter/accountancy-tools';
import dayjs from 'dayjs';

export const creditnoteProactiveSmartnotificationsMixin = {
    methods: {
        async creditnoteProactiveSmartnotifications(next, creditnote) {
            const amountEuroExclVAT = -this.getTotalEuroExclVAT(creditnote);

            const postChecks = await changeIncomeCheck({
                year: dayjs(creditnote.date, 'YYYY-MM-DD').year(),
                amountEuroExclVAT
            }).catch(changeIncomeErrorHandler);

            if (postChecks.length === 0) {
                return next();
            }

            this.showDialog = true;

            this.dialogAttributes = {
                attributes: {
                    title: this.$i18n.t('general.confirmation'),
                    message: postChecks
                        .map((code) => {
                            if (code === 'TOTAL_REVENUE_ABOVE_LIMIT_NONVATLIABLE') {
                                return this.$i18n.t(
                                    `proactiveSmartNotifications.addIncome.TOTAL_REVENUE_ABOVE_LIMIT_NONVATLIABLE`,
                                    {
                                        grens: convertToLocaleValuta(
                                            calculateProratedVatExemptBorder(
                                                25000,
                                                dayjs(creditnote.date, 'YYYY-MM-DD').year(),
                                                this.currentUserData.settings.companyStartDate
                                            ),
                                            {
                                                currency: 'EUR'
                                            }
                                        )
                                    }
                                );
                            }

                            return this.$i18n.t(`proactiveSmartNotifications.addIncome.${code}`);
                        })
                        .join('\n\n'),
                    negativeButton: this.$i18n.t('general.cancel'),
                    positiveButton: this.$i18n.t('general.confirm'),
                    negativeColor: 'message',
                    positiveColor: 'orange'
                },
                actions: {
                    clickPositive: this.clickPositive.bind(this, next),
                    clickNegative: this.clickNegative
                }
            };
        },

        async deleteProactiveSmartnotifications(next, creditnote) {
            const amountEuroExclVAT = this.getTotalEuroExclVAT(creditnote);

            const postChecks = await changeIncomeCheck({
                year: dayjs(creditnote.date, 'YYYY-MM-DD').year(),
                amountEuroExclVAT
            }).catch(changeIncomeErrorHandler);

            if (postChecks.length === 0) {
                return next();
            }

            this.showDialog = true;

            this.dialogAttributes = {
                attributes: {
                    title: this.$i18n.t('general.confirmation'),
                    message: postChecks
                        .map((code) => {
                            if (code === 'TOTAL_REVENUE_ABOVE_LIMIT_NONVATLIABLE') {
                                return this.$i18n.t(
                                    `proactiveSmartNotifications.addIncome.TOTAL_REVENUE_ABOVE_LIMIT_NONVATLIABLE`,
                                    {
                                        grens: convertToLocaleValuta(
                                            calculateProratedVatExemptBorder(
                                                25000,
                                                dayjs(creditnote.date, 'YYYY-MM-DD').year(),
                                                this.currentUserData.settings.companyStartDate
                                            ),
                                            {
                                                currency: 'EUR'
                                            }
                                        )
                                    }
                                );
                            }

                            return this.$i18n.t(`proactiveSmartNotifications.addIncome.${code}`);
                        })
                        .join('\n\n'),
                    negativeButton: this.$i18n.t('general.cancel'),
                    positiveButton: this.$i18n.t('general.confirm'),
                    negativeColor: 'message',
                    positiveColor: 'orange'
                },
                actions: {
                    clickPositive: this.clickPositive.bind(this, next),
                    clickNegative: this.clickNegative
                }
            };
        },

        getTotalEuroExclVAT(creditnote) {
            let amountEuroExclVAT = 0;

            if (this.previousCreditnote) {
                amountEuroExclVAT = creditnote.amountEuroExclVAT - this.previousCreditnote.amountEuroExclVAT;
            } else {
                amountEuroExclVAT = creditnote.amountEuroExclVAT;
            }

            // Negative since this has a NEGATIVE impact on the income (revenue)
            return amountEuroExclVAT;
        }
    }
};
