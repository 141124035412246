<template>
    <base-bottom-sheet
        v-if="invoicableItemOpen"
        v-model="invoicableItemOpen"
        transition="scale-transition"
        inset
        retain-focus
        scrollable
        class="create-invoicableitem"
    >
        <template #header>
            <bottom-sheet-header
                :show-add="true"
                :button-text="$t('addIncome.addInvoicable.add_invoicableitem')"
                @new-item="clickInvoiceitemSearch(item)"
            >
                {{ index === -1 ? $t('addIncome.create_invoiceitem') : $t('addIncome.update_invoiceitem') }}
            </bottom-sheet-header>
        </template>
        <template #content>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col cols="12">
                        <text-field
                            v-model="item.name"
                            class="description"
                            hide-details="auto"
                            outlined
                            dense
                            required
                            :label="$t('general.description')"
                            type="text"
                            :rules="descriptionRules"
                            data-form="name"
                        />
                    </v-col>
                    <v-col cols="12" md="2">
                        <number-field
                            v-model="item.priceExclVAT"
                            class="price"
                            :label="$t('general.price')"
                            prefix="€"
                            persistent-hint
                            hide-details="auto"
                            required
                            :rules="numberRulesGreaterThanZero"
                            data-form="priceExclVAT"
                        />
                    </v-col>

                    <v-col cols="12" md="2">
                        <number-field
                            v-model="item.amount"
                            class="amount"
                            :label="$t('general.amount')"
                            hide-details="auto"
                            required
                            :rules="numberRulesGreaterThanZero"
                            data-form="amount"
                        />
                    </v-col>
                    <v-col cols="12" md="2">
                        <number-field
                            :value="calculateTotalForItem"
                            class="totalPrice"
                            :label="$t('general.total')"
                            prefix="€"
                            :readonly="true"
                            :disabled="true"
                            persistent-hint
                            hide-details="auto"
                        />
                    </v-col>
                    <v-col cols="12" md="3">
                        <search-input-value
                            v-model="item.measuringUnit"
                            :label="$t('general.unitMeasurementsExample')"
                            @clicked-search="searchUnit"
                        />
                    </v-col>
                    <v-col cols="12" md="1">
                        <div data-wrapper="VATPercentage">
                            <v-select
                                v-model="item.VATPercentage"
                                class="vatPercentage"
                                :label="$t('general.VAT')"
                                hide-details="auto"
                                :items="vatPercentages"
                                outlined
                                dense
                                suffix="%"
                                :hint="vatDisabledText"
                                persistent-hint
                                required
                                :rules="requiredRules"
                                :disabled="!canChangeVatOfItem"
                                append-icon
                                data-form="VATPercentage"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" md="2">
                        <number-field
                            v-model="item.discount"
                            class="discount"
                            :label="$t('general.discount')"
                            hide-details="auto"
                            outlined
                            dense
                            :prepend-inner-icon="item.isDiscountPercentage ? 'mdi-percent' : 'mdi-currency-eur'"
                            persistent-hint
                            :rules="discountRules"
                            data-form="discount"
                            :hint="$t('general.switch_discount')"
                            @click:prepend-inner="item.isDiscountPercentage = !item.isDiscountPercentage"
                        />
                    </v-col>
                    <v-col cols="12">
                        <editor
                            v-model="item.description"
                            :label="$t('general.description')"
                            :rules="descriptionRules"
                            data-form="description"
                        />
                    </v-col>
                    <v-col cols="12">
                        <base-icon-right i18n-path="tooltips.addInvoicable.saveAsInvoicableitem">
                            <v-checkbox
                                v-model="save"
                                hide-details="auto"
                                data-form="save-invoicableitem"
                                :label="$t('addIncome.save_as_fixed_invoiceitem')"
                            />
                        </base-icon-right>
                    </v-col>
                    <v-col cols="12">
                        <submit-button
                            id="insert"
                            data-action="insert-invoiceitem"
                            :disabled="!valid"
                            :block="isPhone"
                            class="mr-4"
                            @click="handleSubmit"
                        >
                            {{ creating ? $t('general.insert') : $t('general.update') }}
                        </submit-button>
                    </v-col>
                </v-row>
            </v-form>

            <loader v-if="loading" />

            <select-invoiceitem ref="selectInvoice" @select-invoiceitem="handleSelectInvoiceitem" />

            <select-table-component
                ref="selectTableComponent"
                :title="$t('general.unitMeasurements')"
                :input-component="unitTable"
                @selected-item="handleSelectedUnit"
                @new-item="newUnit"
            />
        </template>
    </base-bottom-sheet>
</template>

<script>
// --- State ---
// import BottomSheetHeader from './BottomSheetHeader';
import Loader from '../Loader';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import { mapActions, mapState } from 'vuex';

import _, { cloneDeep, isNil } from 'lodash';
import SubmitButton from '../SubmitButton.vue';
import NumberField from '../FormComponents/NumberField.vue';
import SelectInvoiceitem from '../BottomSheets/SelectInvoiceitem.vue';
import BottomSheetHeader from '../BottomSheets/BottomSheetHeader';
import SelectTableComponent from '../SelectTableComponent.vue';
import { notify } from '@/helpers/successNotification';
import UnitTable from '../DataTables/UnitTable.vue';
import SearchInputValue from '../SearchInputValue.vue';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { isVatLiable } from '@/helpers/VATHelper';
import Editor from '@/components/Editor/DefaultEditor.vue';

export default {
    components: {
        SubmitButton,
        Editor,
        NumberField,
        SelectInvoiceitem,
        BottomSheetHeader,
        Loader,
        SelectTableComponent,
        SearchInputValue
    },
    mixins: [formRulesMixin, screenSizeMixin],
    props: {
        invoiceitemInput: {
            type: Object,
            default: null
        },
        creating: {
            type: Boolean,
            default: true
        },
        index: {
            required: false,
            type: Number,
            default: -1
        },
        canChangeVatOfItem: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            invoicableItemOpen: false,
            item: null,
            valid: false,
            vatPercentages: [0, 6, 12, 21],
            selectedItem: null,
            units: [],
            isFetchingUnits: false,
            unitTable: UnitTable,
            loadingCreateInvoicableItem: false,
            save: false
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        calculateTotalForItem() {
            if (isNil(this.item.priceExclVAT) || isNil(this.item.amount)) {
                return null;
            }

            return this.item.priceExclVAT * this.item.amount;
        },
        loading() {
            return this.isFetchingUnits || this.loadingCreateInvoicableItem;
        },
        vatDisabledText() {
            if (!isVatLiable(this.currentUserData.settings.vatLiable)) {
                return this.$t('accountantTerms.non_vat_liable');
            }

            return this.canChangeVatOfItem ? '' : this.$t('accountantTerms.btw_verlegd');
        }
    },

    watch: {
        invoiceitemInput: {
            handler(newValue) {
                if (newValue) {
                    this.item = cloneDeep(newValue);
                }
            },
            immediate: true
        },
        invoicableItemOpen: {
            handler() {
                this.save = false;
            },
            immediate: true
        }
    },

    created() {
        this.isFetchingUnits = true;
        this.getUnits()
            .then((data) => (this.units = data))
            .finally(() => (this.isFetchingUnits = false));
    },

    methods: {
        ...mapActions('units', ['getUnits']),
        ...mapActions('invoiceitems', ['createInvoiceitem']),
        ...mapActions('bottomSheets', ['openSelectInvoiceitem', 'newUnit']),
        async handleSubmit() {
            try {
                if (this.save) {
                    this.loadingCreateInvoicableItem = true;
                    await this.handleSaveAndSubmit();
                }
                this.$emit(this.creating ? 'create' : 'update', { item: this.item, index: this.index });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loadingCreateInvoicableItem = false;
            }
        },
        async handleSaveAndSubmit() {
            const invoiceitemCopy = _.cloneDeep(this.item);

            await this.createInvoiceitem(invoiceitemCopy);
            notify.call(this, {
                title: this.$t('general.succesfuly_created'),
                text: this.$t('general.succesfuly_created')
            });

            this.$store.dispatch('invoiceitems/newInvoiceitemEvent');
        },
        openModal() {
            this.invoicableItemOpen = true;
        },
        closeModal() {
            this.invoicableItemOpen = false;
        },
        handleSelectInvoiceitem(selectedItem) {
            const copySelectedItem = _.cloneDeep(selectedItem);

            if (typeof copySelectedItem === 'object') {
                if (this.canChangeVatOfItem === false) {
                    copySelectedItem.VATPercentage = 0;
                }

                copySelectedItem.discount = 0;
                copySelectedItem.isDiscountPercentage = false;

                this.item = copySelectedItem;
            }
        },
        clickInvoiceitemSearch(item) {
            this.selectedItem = item;
            this.openSelectInvoiceitem();
        },
        handleSelectedUnit(unit) {
            if (this.item.amount == 1) {
                this.item.measuringUnit = unit.singular;
            } else {
                this.item.measuringUnit = unit.plural;
            }
            this.$refs.selectTableComponent.close();
        },
        searchUnit() {
            this.$refs.selectTableComponent.open();
        }
    }
};
</script>

<style lang="scss" scoped></style>
