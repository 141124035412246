<template>
    <base-bottom-sheet v-if="isOpen" v-model="isOpen" class="add-customer">
        <template #header>
            <bottom-sheet-header>
                <base-icon-right v-if="isCreatingNewCustomer" i18n-path="tooltips.addCustomer.type_of_customer">
                    {{ $t('customer.add_customer') }}
                </base-icon-right>
                <template v-else>{{ $t('customer.edit_customer') }}</template>
            </bottom-sheet-header>
        </template>
        <template #content>
            <AddCustomerForm v-model="currentCustomerCopy" @submit="handleSubmit" />
            <loader v-if="loading" />
        </template>
    </base-bottom-sheet>
</template>

<script>
// --- State ---
// --- Components ---
import BottomSheetHeader from '@/components/BottomSheets/BottomSheetHeader';
import Loader from '@/components/Loader';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { lookupCompanynumberMixin } from '@/mixins/lookupCompanynumberMixin';

import { apiErrorAndDisplay } from '@/helpers/errorHandling';

import { notify } from '@/helpers/successNotification';
import { cloneDeep } from 'lodash';
import AddCustomerForm from './addCustomer.form.vue';
import { sendCreateCustomerRequest, sendUpdateCustomerRequest } from '@/services/customer';

export default {
    components: {
        BottomSheetHeader,
        Loader,
        AddCustomerForm
    },

    mixins: [formRulesMixin, lookupCompanynumberMixin],

    props: {
        currentCustomer: {
            required: true
        },
        value: {
            required: true,
            type: Boolean
        }
    },

    data() {
        return {
            lookingUpCompany: false,
            valid: true,
            loading: false,
            currentCustomerCopy: null
        };
    },

    computed: {
        isOpen: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        isCreatingNewCustomer() {
            return Boolean(this.currentCustomer.id) === false;
        }
    },

    watch: {
        currentCustomer: {
            handler(newValue) {
                this.currentCustomerCopy = cloneDeep(newValue);
            },
            immediate: true
        }
    },

    methods: {
        async handleSubmit() {
            try {
                this.loading = true;

                const copyCustomer = this.setCorrectValues(this.currentCustomerCopy);

                if (this.isCreatingNewCustomer) {
                    await sendCreateCustomerRequest(copyCustomer);
                } else {
                    await sendUpdateCustomerRequest(copyCustomer);
                }
                notify.call(this, {
                    title: this.$i18n.t('customer.customer_succesfuly_added'),
                    text: this.$i18n.t('customer.customer_succesfuly_added')
                });

                this.$store.dispatch('customers/newCustomerCreated');

                // Close bottom sheet when submit successful.
                this.isOpen = false;
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        },
        setCorrectValues(currentCustomerCopy) {
            const copyCustomer = cloneDeep(currentCustomerCopy);

            return copyCustomer;
        }
    }
};
</script>
