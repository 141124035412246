export function newCustomer({
    companyNumber,
    typeOfCustomer,
    country
}: {
    country?: string | null;
    companyNumber?: string;
    typeOfCustomer?: number;
} = {}) {
    return {
        id: null,
        company: {
            name: null,
            number: companyNumber ?? null
        },
        address: {
            street: null,
            number: null,
            city: null,
            zipcode: null,
            country: country ?? null
        },
        type: typeOfCustomer ?? null,
        VATLiable: true,
        notes: null
    };
}
