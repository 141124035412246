<template>
    <span>
        <chip v-if="paid" color="green">
            <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                    <v-icon v-bind="attrs" small v-on="on">mdi-cash</v-icon>
                </template>
                <span> {{ $t('income.invoice_payed') }}</span>
            </v-tooltip>
        </chip>

        <chip v-if="!paid" color="grey">
            <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                    <v-icon v-bind="attrs" small v-on="on">mdi-cash</v-icon>
                </template>
                <span> {{ $t('income.invoice_not_payed') }}</span>
            </v-tooltip>
        </chip>
    </span>
</template>

<script>
import Chip from './Chip.vue';
export default {
    components: { Chip },
    props: ['paid']
};
</script>

<style lang="scss" scoped></style>
