import { changeIncomeErrorHandler } from '@/helpers/changeIncome/changeincome';
import { convertToLocaleValuta } from '@/helpers/number-helpers/numberHelpers';
import { changeIncomeCheck } from '@/services/proactivesmartnotifications';
import { calculateProratedVatExemptBorder } from '@dexxter/accountancy-tools';
import dayjs from 'dayjs';

export const invoiceProactiveSmartnotificationsMixin = {
    methods: {
        async invoiceProactiveSmartnotifications(next, invoice) {
            const amountEuroExclVAT = this.getTotalEuroExclVAT(invoice);

            const postChecks = await changeIncomeCheck({
                year: dayjs(invoice.date, 'YYYY-MM-DD').year(),
                amountEuroExclVAT
            }).catch(changeIncomeErrorHandler);

            if (postChecks.length === 0) {
                return next();
            }

            this.showDialog = true;

            this.setDialogAttributes(
                {
                    dataId: 'proactiveSmartNotificationsPopup',
                    title: this.$i18n.t('general.confirmation'),
                    message: postChecks
                        .map((code) => {
                            if (code === 'TOTAL_REVENUE_ABOVE_LIMIT_NONVATLIABLE') {
                                return this.$i18n.t(
                                    `proactiveSmartNotifications.addIncome.TOTAL_REVENUE_ABOVE_LIMIT_NONVATLIABLE`,
                                    {
                                        grens: convertToLocaleValuta(
                                            calculateProratedVatExemptBorder(
                                                25000,
                                                dayjs(invoice.date, 'YYYY-MM-DD').year(),
                                                this.currentUserData.settings.companyStartDate
                                            ),
                                            {
                                                currency: 'EUR'
                                            }
                                        )
                                    }
                                );
                            }

                            return this.$i18n.t(`proactiveSmartNotifications.addIncome.${code}`);
                        })
                        .join('\n\n'),
                    negativeButton: this.$i18n.t('general.cancel'),
                    positiveButton: this.$i18n.t('general.confirm'),
                    negativeColor: 'message',
                    positiveColor: 'orange'
                },
                {
                    clickPositive: () => {
                        this.clickPositive.call(this, next);
                    }
                }
            );
        },

        async deleteProactiveSmartnotifications(next, invoice) {
            const amountEuroExclVAT = -this.getTotalEuroExclVAT(invoice);

            const postChecks = await changeIncomeCheck({
                year: dayjs(invoice.date, 'YYYY-MM-DD').year(),
                amountEuroExclVAT
            }).catch(changeIncomeErrorHandler);

            if (postChecks.length === 0) {
                return next();
            }

            this.showDialog = true;

            this.setDialogAttributes(
                {
                    dataId: 'proactiveSmartNotificationsPopup',
                    title: this.$i18n.t('general.confirmation'),
                    message: postChecks
                        .map((code) => {
                            if (code === 'TOTAL_REVENUE_ABOVE_LIMIT_NONVATLIABLE') {
                                return this.$i18n.t(
                                    `proactiveSmartNotifications.addIncome.TOTAL_REVENUE_ABOVE_LIMIT_NONVATLIABLE`,
                                    {
                                        grens: convertToLocaleValuta(
                                            calculateProratedVatExemptBorder(
                                                25000,
                                                dayjs(invoice.date, 'YYYY-MM-DD').year(),
                                                this.currentUserData.settings.companyStartDate
                                            ),
                                            {
                                                currency: 'EUR'
                                            }
                                        )
                                    }
                                );
                            }

                            return this.$i18n.t(`proactiveSmartNotifications.addIncome.${code}`);
                        })
                        .join('\n\n'),
                    negativeButton: this.$i18n.t('general.cancel'),
                    positiveButton: this.$i18n.t('general.confirm'),
                    negativeColor: 'message',
                    positiveColor: 'orange'
                },
                {
                    clickPositive: () => {
                        this.clickPositive.call(this, next);
                    }
                }
            );
        },

        getTotalEuroExclVAT(invoice) {
            let amountEuroExclVAT = 0;

            if (this.previousInvoice) {
                amountEuroExclVAT = invoice.amountEuroExclVAT - this.previousInvoice.amountEuroExclVAT;
            } else {
                amountEuroExclVAT = invoice.amountEuroExclVAT;
            }

            return amountEuroExclVAT;
        }
    }
};
