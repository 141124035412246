<template>
    <div>
        <view-layout>
            <template #header>
                <page-header>
                    <template #title>
                        {{ $t($route.meta.title) }}
                        <sub>
                            <tooltip
                                :underlined="false"
                                :title="$t(`tooltips.addInvoicable.historicalcreditnoteTitle.title`)"
                                :content="$t(`tooltips.addInvoicable.historicalcreditnoteTitle.content`)"
                                ><template slot="activator">
                                    <v-icon small> mdi-information </v-icon></template
                                ></tooltip
                            >
                        </sub>
                    </template>
                </page-header>
            </template>
            <template #content>
                <InvoicableForm
                    v-if="currentUserData && loading === false"
                    ref="invoicableComponent"
                    type="creditnote"
                    :is-creating-new-document="isCreatingNew"
                    :historical="true"
                    :invoicable-number-text="$t('general.creditnote_number')"
                    :document-input="currentCreditnote"
                    :button-text-publish="$t('addIncome.insert_creditnote')"
                    @submit-edit="runMiddleware(editMiddleware, $event)"
                    @submit-create-historical="runMiddleware(createMiddleware, $event)"
                />
            </template>
        </view-layout>

        <confirmation-dialog
            v-if="showDialog"
            v-model="showDialog"
            v-bind="dialogAttributes.attributes"
            @click-positive="dialogAttributes.actions.clickPositive"
            @click-negative="dialogAttributes.actions.clickNegative"
        />
        <Loader v-if="loading || !currentUserData" />
    </div>
</template>

<script>
// --- State ---
import { mapState, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
// --- Components ---
import InvoicableForm from '@/components/Forms/InvoicableForm';
import Loader from '@/components/Loader';
// --- Mixins ---
import { invoicableFormMixin } from '@/mixins/invoicableFormMixin.js';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
// --- Helper ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { invoicableTransformCurrencyFieldToIntegers } from '@/helpers/invoicableHelper';
import { currentInvoicableNewInstance } from '../models/invoicable';
import { invoicableTypes } from '@/config/constants';
import ViewLayout from '@/components/ViewLayout';
import PageHeader from '@/components/PageHeader';
import dayjs from 'dayjs';

import _ from 'lodash';
import { getCreditnote } from '@/services/creditnote';
import { getInvoice } from '@/services/invoice';
import { middlewareMixin } from '@/mixins/middlewareMixin';
import { creditnoteProactiveSmartnotificationsMixin } from '@/mixins/creditnoteProactiveSmartnotificationsMixin';

export default {
    components: {
        Loader,
        InvoicableForm,
        ViewLayout,
        PageHeader
    },

    mixins: [middlewareMixin, formRulesMixin, invoicableFormMixin, creditnoteProactiveSmartnotificationsMixin],

    data() {
        return {
            loading: false,
            isCreatingNew: true,
            invoice: null,
            currentCreditnote: currentInvoicableNewInstance(invoicableTypes.CREDITNOTE),
            previousCreditnote: null,
            editMiddleware: [this.creditnoteProactiveSmartnotifications, this.handleEditSubmitMiddleware],
            createMiddleware: [this.creditnoteProactiveSmartnotifications, this.handleSubmitMiddleware]
        };
    },

    computed: {
        ...mapFields('bottomSheets', ['isAddCreditnoteOpen', 'isCreatingNewCreditnote']),
        ...mapState('auth', ['currentUserData'])
    },
    async created() {
        this.startLoading();
        this.loading = true;
        try {
            if (this.$route.params.id) {
                this.isCreatingNew = false;
                const fetchedCreditnote = await getCreditnote(this.$route.params.id);
                this.currentCreditnote = fetchedCreditnote;
                this.previousCreditnote = fetchedCreditnote;

                if (this.currentCreditnote.invoiceId) {
                    this.invoice = await getInvoice(this.currentCreditnote.invoiceId);
                }
            } else {
                this.isCreatingNew = true;
                if (this.$route.query?.invoiceId) {
                    this.invoice = await getInvoice(this.$route.query?.invoiceId);

                    this.currentCreditnote.invoiceId = this.invoice.id;
                    this.currentCreditnote.customer = this.invoice.customer;
                    this.currentCreditnote.items = this.invoice.items;
                    this.currentCreditnote.typeOfService = this.invoice.typeOfService;
                    this.currentCreditnote.invoiceNumber = this.invoice.invoiceNumber;
                }
                this.setInitialData();
            }
        } catch (e) {
            if (e.response && (e.response.status === 404 || e.response.status === 403)) {
                this.stopLoading();
                this.$router.push({ name: 'dashboard' });
            }
        } finally {
            this.stopLoading();
            this.loading = false;
        }
    },

    methods: {
        ...mapActions('incomes', ['createInvoicable', 'getCreditnoteById']),
        ...mapActions(['startLoading', 'stopLoading']),

        handleEditSubmitMiddleware(next, invoice) {
            return this.handleSubmitEdit(invoice);
        },
        handleSubmitMiddleware(next, invoice) {
            return this.handleSubmitCreateHistorical(invoice);
        },
        resetForm() {
            this.$refs.form.reset();
        },
        async handleSubmit(currentCreditnote) {
            this.loading = true;

            let creditnoteCopy = _.cloneDeep(currentCreditnote);

            try {
                creditnoteCopy = this.setCorrectValues(creditnoteCopy);

                await this.createInvoicable(creditnoteCopy);

                this.$notify({
                    title: this.$t('income.creditnote_created_success'),
                    text: this.$t('income.creditnote_created_success'),
                    type: 'success'
                });

                this.resetForm();
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        this.setInitialData();
                    });
                });

                // Increment the counter
                this.$store.dispatch('incomes/newQuotationCreated');
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        },
        setCorrectValues(creditnoteCopy) {
            creditnoteCopy.draft = false;
            creditnoteCopy.historical = true;
            creditnoteCopy.creator = this.currentUserData.company;

            creditnoteCopy.type = 'creditnote';

            return invoicableTransformCurrencyFieldToIntegers(creditnoteCopy, 'creditnote');
        },
        setInitialData() {
            this.currentCreditnote.date = dayjs.utc().format('YYYY-MM-DD');
        },
        handleAfterSubmit(newCreditnote) {
            this.$store.dispatch('incomes/newCreditnoteCreated');

            this.isCreatingNew = false;
            this.currentCreditnote = newCreditnote;

            this.$router.push({
                name: 'invoicable-view',
                params: {
                    id: this.currentCreditnote.id,
                    type: 'creditnote'
                },
                query: {
                    newlyCreated: true
                }
            }).catch;
        }
    }
};
</script>

<style lang="scss" scoped>
.items {
    width: 100%;
}
</style>
