<template>
    <v-row>
        <v-col cols="12" sm="6" order-md="2">
            <number-field
                id="amountEuroTotalExclVAT"
                v-model="currentExpense.amountEuroExclVAT"
                :label="$t('general.amount_excl_vat')"
                outlined
                prefix="€"
                :allow-negative="true"
                dense
                :rules="currentExpense.isCreditnote ? numberRulesNotRequired : numberRulesNotRequiredNegativePossible"
            />
        </v-col>
        <v-col cols="12" sm="6" order-md="2">
            <number-field
                id="amountEuroTotalInclVAT"
                v-model="currentExpense.amountEuroInclVAT"
                :label="$t('general.amount_incl_vat')"
                outlined
                prefix="€"
                :allow-negative="true"
                dense
                :rules="currentExpense.isCreditnote ? numberRulesNotRequired : numberRulesNotRequiredNegativePossible"
            />
        </v-col>
    </v-row>
</template>

<script>
import { formRulesMixin } from '@/mixins/formRulesMixin.js';

export default {
    mixins: [formRulesMixin],
    props: ['value'],

    computed: {
        currentExpense: {
            get() {
                return this.value;
            },
            set(localDocument) {
                this.$emit('input', localDocument);
            }
        }
    }
};
</script>
