<template>
    <div class="billed_income-table">
        <v-row class="filtering-container">
            <v-col cols="12" md="12" class="mb-4">
                <pagination-search v-model="query.q" />
            </v-col>
        </v-row>
        <pagination-data-table
            v-if="invoiceitems.data"
            ref="paginationDataTable"
            :headers="headers"
            :pagination="pagination"
            :pagination-data="invoiceitems"
            :loading="loading"
            sort-by="date"
            :class="{ clickableFirstItem: clickable }"
            @refetch-data="$_handlePaginationRefresh"
            @sort-by="$_filtering_handleSortColumn"
            @sort-desc="$_filtering_handleSortDirectionDesc"
            @click-row="handleClickRow"
        >
            <template #[`item.priceExclVAT`]="{ item }">
                {{ item.priceExclVAT | numericFormat | addEuroSign }}
            </template>
            <template #[`item.priceInclVAT`]="{ item }">
                {{ (item.priceExclVAT * (1 + item.VATPercentage / 100)) | numericFormat | addEuroSign }}
            </template>
            <template #[`item.VATPercentage`]="{ item }"> {{ item.VATPercentage }}% </template>

            <template #[`item.actions`]="{ item }">
                <v-btn data-action="editInvoiceitem" icon color="orange" @click.stop="editInvoiceitem(item)">
                    <v-icon small> mdi-pencil </v-icon>
                </v-btn>

                <v-btn
                    data-action="deleteInvoiceItem"
                    icon
                    color="red"
                    @click.stop="
                        {
                            dialog = true;
                            selectedInvoiceitem = item.id;
                        }
                    "
                >
                    <v-icon small> mdi-delete </v-icon>
                </v-btn></template
            >
        </pagination-data-table>

        <confirmation-dialog
            v-if="dialog"
            v-model="dialog"
            :title="$t('general.delete')"
            :message="$t('general.confirm_delete_message')"
            :loading="loading"
            :negative-button="$t('general.cancel')"
            :positive-button="$t('general.delete')"
            @click-positive="removeInvoiceitem"
            @click-negative="dialog = false"
        />
    </div>
</template>

<script>
// --- State ---
import { mapActions, mapState } from 'vuex';
// --- Components ---
import ConfirmationDialog from '../Dialogs/ConfirmationDialog.vue';
import PaginationSearch from '../PaginationSearch.vue';
// --- Helper ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { formatAddress } from '@/helpers/stringFormats';
import { notify } from '@/helpers/successNotification';
import { filteringMixin } from '../../mixins/filtering';
import PaginationDataTable from './PaginationDataTable.vue';
import { isVatLiable } from '@/helpers/VATHelper';

export default {
    components: {
        ConfirmationDialog,
        PaginationSearch,
        PaginationDataTable
    },
    mixins: [filteringMixin],

    props: {
        showActions: {
            type: Boolean,
            default: true
        },

        clickable: {
            type: Boolean,
            default: false
        }
    },

    data() {
        const sortableColumns = ['name', 'priceExclVAT', 'VATPercentage'];

        let headers = [
            {
                text: this.$t('addInvoiceitem.name'),
                value: 'name',
                sortable: sortableColumns.includes('name')
            },
            {
                text: this.$t('addInvoiceitem.VATPercentage'),
                value: 'VATPercentage',
                sortable: sortableColumns.includes('VATPercentage')
            },
            {
                text: this.$t('addInvoiceitem.measuringUnit'),
                value: 'measuringUnit',
                sortable: sortableColumns.includes('measuringUnit')
            }
        ];

        if (isVatLiable(this.$store.state.auth.currentUserData.settings.vatLiable)) {
            headers.splice(1, 0, {
                text: this.$t('addInvoiceitem.priceExclVAT'),
                value: 'priceExclVAT',
                sortable: sortableColumns.includes('priceExclVAT')
            });
            headers.splice(1, 0, {
                text: this.$t('addInvoiceitem.priceInclVAT'),
                value: 'priceInclVAT',
                sortable: sortableColumns.includes('priceInclVAT')
            });
        } else {
            headers.splice(1, 0, {
                text: this.$t('general.price'),
                value: 'priceExclVAT',
                sortable: sortableColumns.includes('priceExclVAT')
            });
        }

        if (this.showActions) {
            headers.push({
                text: this.$t('general.actions'),
                value: 'actions',
                sortable: false
            });
        }

        return {
            headers,
            dialog: false,
            loading: false,
            toDeleteUid: '',
            selectedInvoiceitem: '',
            invoiceitems: {
                data: []
            },
            sortableColumns
        };
    },

    created() {
        this.wrapperFunction();
    },

    computed: {
        ...mapState('invoiceitems', ['newInvoiceitemEvents']),
        ...mapState('auth', ['currentUserData'])
    },

    watch: {
        newInvoiceitemEvents: {
            deep: true,
            handler: function () {
                this.wrapperFunction();
            }
        }
    },

    methods: {
        ...mapActions('bottomSheets', ['editInvoiceitem']),
        ...mapActions(['startLoading', 'stopLoading']),
        ...mapActions('invoiceitems', ['deleteInvoiceitem', 'getAllInvoiceitemsPagination']),
        getFormattedAddress(address) {
            return formatAddress(address);
        },
        async wrapperFunction() {
            this.loading = true;
            this.invoiceitems = await this.getAllInvoiceitemsPagination({
                pagination: {
                    force: true,
                    currentPage: this.pagination.currentPage,
                    limit: this.pagination.limit,
                    q: this.pagination.search
                },
                query: this.query
            });
            this.loading = false;
        },
        async removeInvoiceitem() {
            this.loading = true;
            this.startLoading();
            try {
                await this.deleteInvoiceitem(this.selectedInvoiceitem);

                await this.wrapperFunction();

                notify.call(this, {
                    title: this.$t('general.succesfuly_saved'),
                    text: this.$t('general.succesfuly_saved')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
                this.dialog = false;
                this.stopLoading();
            }
        },
        handleClickRow(item) {
            this.$emit('click-row', item);
        }
    }
};
</script>

<style lang="scss" scoped>
a.link-decoration {
    text-decoration: underline;
}
</style>
