<template>
    <div>
        <view-layout>
            <template #header>
                <PageHeader>
                    <template #title>
                        {{ $t($route.meta.title) }}
                    </template>
                </PageHeader>
            </template>
            <template #content>
                <v-row>
                    <v-col cols="12" md="8">
                        <table-card class="mb-3 pa-5">
                            <template #content>
                                <v-form
                                    v-if="currentUserData"
                                    id="sendToDocumentsToAccountant"
                                    ref="form"
                                    v-model="valid"
                                >
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <v-select
                                                :items="dateOptions"
                                                :label="$t('sendToAccountant.select_period')"
                                                outlined
                                                item-text="label"
                                                dense
                                                return-object
                                                hide-details
                                                @change="changeDateOptions"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <date-field
                                                id="beginDate"
                                                v-model="sendToAccountantData.beginDate"
                                                :label="$t('sendToAccountant.start_date')"
                                                outlined
                                                persistent-hint
                                                dense
                                                required
                                                :rules="requiredRules"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <date-field
                                                id="endDate"
                                                v-model="sendToAccountantData.endDate"
                                                :label="$t('sendToAccountant.end_date')"
                                                outlined
                                                persistent-hint
                                                dense
                                                required
                                                :rules="requiredRules"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <text-field
                                                id="email"
                                                v-model="sendToAccountantData.sendToEmail"
                                                type="email"
                                                :label="$t('sendToAccountant.accountant_email')"
                                                outlined
                                                dense
                                                :rules="[...requiredRules, ...emailRules]"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-checkbox
                                                v-model="sendToAccountantData.sendIncomes"
                                                :label="$t('sendToAccountant.send_incomes')"
                                                outlined
                                                dense
                                                hide-details
                                            />

                                            <v-checkbox
                                                v-model="sendToAccountantData.sendExpenses"
                                                :label="$t('sendToAccountant.send_expenses')"
                                                outlined
                                                dense
                                                hide-details
                                            />

                                            <v-checkbox
                                                v-model="sendToAccountantData.sendCreditnotes"
                                                :label="$t('sendToAccountant.send_creditnotes')"
                                                outlined
                                                dense
                                                hide-details
                                            />

                                            <v-checkbox
                                                v-model="sendToAccountantData.sendAlreadySendDocuments"
                                                :label="$t('sendToAccountant.send_already_send_documents')"
                                                outlined
                                                dense
                                                hide-details
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <submit-button
                                                id="approve"
                                                color="primary"
                                                small
                                                :disabled="!valid"
                                                @click="sendDocuments"
                                                >{{ $t('general.send') }}</submit-button
                                            >
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </template>
                        </table-card>
                    </v-col>

                    <v-col cols="12" md="4">
                        <table-card class="mb-3 pa-5">
                            <template #content>
                                <h3>{{ $t('sendToAccountant.send_from_info') }}</h3>
                                <v-divider class="mb-4 mt-2" />
                                <p
                                    v-html="
                                        $t('sendToAccountant.send_from_info_text', {
                                            fromEmail
                                        })
                                    "
                                ></p>
                            </template>
                        </table-card>
                        <table-card class="mb-3 pa-5">
                            <template #content>
                                <h3>{{ $t('sendToAccountant.documents_to_send') }}</h3>
                                <v-divider class="mb-4 mt-2" />
                                <ul>
                                    <li>{{ $t('general.invoices') }}: {{ totalInvoicesNotSendToAccountant }}</li>
                                    <li>{{ $t('general.creditnotes') }}: {{ totalDocumentsToSend.creditnotes }}</li>
                                    <li>{{ $t('general.expenses') }}: {{ totalDocumentsToSend.expenses }}</li>
                                </ul>
                            </template>
                        </table-card>
                    </v-col>
                </v-row></template
            >
        </view-layout>

        <loader v-if="loading" />
    </div>
</template>

<script>
// --- Error handling ---
import ViewLayout from '@/components/ViewLayout.vue';
import Loader from '@/components/Loader.vue';
import TableCard from '@/components/TableCard.vue';
import { mapState } from 'vuex';
import PageHeader from '@/components/PageHeader';
import { formRulesMixin } from '@/mixins/formRulesMixin';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { sendDocumentsRequest } from '@/services/accountantemailbcc';
import { countInvoicesNotSendToAccountantRequest } from '@/services/invoice';
import { countExpensesNotSendToAccountantRequest } from '@/services/expenses';
import { countCreditnotesNotSendToAccountantRequest } from '@/services/creditnote';

import { getFromEmailAddress } from '@/businessServices/email';

export default {
    mixins: [formRulesMixin],

    data() {
        return {
            fromEmail: getFromEmailAddress(this.$store.state.auth.currentUserData.id),
            dateOptions: [
                {
                    beginDate: `${this.$store.state.selectedYear}-01-01`,
                    endDate: `${this.$store.state.selectedYear}-03-31`,
                    label: `${this.$t('general.quarter')} 1`
                },
                {
                    beginDate: `${this.$store.state.selectedYear}-04-01`,
                    endDate: `${this.$store.state.selectedYear}-06-30`,
                    label: `${this.$t('general.quarter')} 2`
                },
                {
                    beginDate: `${this.$store.state.selectedYear}-07-01`,
                    endDate: `${this.$store.state.selectedYear}-09-30`,
                    label: `${this.$t('general.quarter')} 3`
                },
                {
                    beginDate: `${this.$store.state.selectedYear}-10-01`,
                    endDate: `${this.$store.state.selectedYear}-12-31`,
                    label: `${this.$t('general.quarter')} 4`
                },
                {
                    beginDate: `${this.$store.state.selectedYear}-01-01`,
                    endDate: `${this.$store.state.selectedYear}-12-31`,
                    label: `${this.$t('general.current_year')}`
                },
                {
                    beginDate: `${this.$store.state.selectedYear - 1}-01-01`,
                    endDate: `${this.$store.state.selectedYear - 1}-12-31`,
                    label: `${this.$t('general.previous_year')}`
                }
            ],
            valid: false,
            loading: false,
            sendToAccountantData: {
                beginDate: null,
                endDate: null,
                sendIncomes: false,
                sendExpenses: false,
                sendCreditnotes: false,
                sendAlreadySendDocuments: false,
                sendToEmail: null
            },
            totalDocumentsToSend: {
                invoices: null,
                expenses: null,
                creditnotes: null
            }
        };
    },

    async created() {
        await this.getTotalDocumentsToSend();
    },
    methods: {
        changeDateOptions(selectedPeriod) {
            this.sendToAccountantData.beginDate = selectedPeriod.beginDate;
            this.sendToAccountantData.endDate = selectedPeriod.endDate;
        },
        async sendDocuments() {
            try {
                this.loading = true;
                await sendDocumentsRequest(this.sendToAccountantData);

                await this.getTotalDocumentsToSend();

                this.$notify({
                    title: this.$t('sendToAccountant.succesvol_sent'),
                    text: this.$t('sendToAccountant.succesvol_sent'),
                    type: 'success'
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        async getTotalDocumentsToSend() {
            [
                this.totalDocumentsToSend.invoices,
                this.totalDocumentsToSend.creditnotes,
                this.totalDocumentsToSend.expenses
            ] = await Promise.all([
                countInvoicesNotSendToAccountantRequest(),
                countCreditnotesNotSendToAccountantRequest(),
                countExpensesNotSendToAccountantRequest()
            ]);
        }
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        ...mapState(['selectedYear']),
        totalInvoicesNotSendToAccountant() {
            if (this.totalDocumentsToSend.invoices === null) {
                return '-';
            }

            return this.totalDocumentsToSend.invoices;
        }
    },
    components: {
        PageHeader,
        ViewLayout,
        Loader,
        TableCard
    }
};
</script>

<style lang="scss" scoped></style>
