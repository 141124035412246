<template>
    <div>
        <v-row>
            <v-col cols="12" md="6">
                <date-field
                    id="date"
                    v-model="document.date"
                    :label="$t('general.date')"
                    outlined
                    dense
                    clearable
                    :hint="$t(`addIncome.add_date_${type}`)"
                    persistent-hint
                    required
                />
            </v-col>

            <v-col v-if="canShowDueDate" cols="12" md="6">
                <date-field
                    id="dueDate"
                    v-model="document.dueDate"
                    :label="$t('general.due_date')"
                    outlined
                    dense
                    clearable
                    :hint="$t('addIncome.add_due_date_invoice')"
                    persistent-hint
                />
            </v-col>

            <v-col v-if="canShowUploadFileForm" cols="12" md="6">
                <v-file-input
                    id="fileUploadForm"
                    v-model="document.file"
                    :label="$t('addExpense.file_label')"
                    persistent-hint
                    :hint="isCreatingNewDocument || !document.url ? '' : $t('addExpense.file_update_hint')"
                    single
                    outlined
                    dense
                    prepend-icon=""
                    :rules="invoicableFileRules"
                    append-icon="mdi-paperclip"
                >
                    <template #[`selection`]="{ text }">
                        <v-chip small label color="primary">
                            {{ text }}
                        </v-chip>
                    </template>
                </v-file-input>
            </v-col>

            <v-col v-if="canShowInvoicableNumberForm" cols="12" md="6">
                <text-field
                    :id="`${type}NumberHistorical`"
                    v-model="document[`${type}Number`]"
                    :label="invoicableNumberText"
                    outlined
                    dense
                />
            </v-col>

            <v-col v-if="canShowInvoiceNumberForm" cols="12" md="6">
                <text-field
                    id="creditnoteForInvoiceNumber"
                    v-model="document.invoiceNumber"
                    :label="$t('general.invoice_number')"
                    :disabled="isCreatingNewDocument === false && document.historical === false"
                    :hint="$t('general.invoice_number_of_credited_invoice')"
                    persistent-hint
                    outlined
                    dense
                    required
                    :rules="invoiceNumberRules"
                />
            </v-col>

            <v-col cols="12" md="6">
                <search-input
                    :label="$t('general.search_customer')"
                    :value="document.customer ? document.customer.company.name : ''"
                    @clicked-search="searchCustomer"
                />
            </v-col>
            <v-col v-if="canShowBTWMedecontractantForm" cols="12" md="12">
                <v-checkbox
                    id="BTWMedecontractantForm"
                    v-model="document.btwMedecontractant"
                    data-form="btw-medecontractant"
                    :label="$t('accountantTerms.btw_medecontractant')"
                />
            </v-col>
        </v-row>

        <select-customer-modal
            ref="selectTableComponent"
            :add-button-text="$t('customer.add_customer')"
            @selected-item="handleSelectedCustomer"
        />
    </div>
</template>
<script>
// --- State ---
import { mapState } from 'vuex';
// --- Components ---
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';

// --- Constants ---
import { BELGIAN_COMPANY } from '@/config/constants';

import CustomersTable from '../DataTables/CustomersTable.vue';
import SearchInput from '../SearchInput.vue';
import { isVatLiable } from '@/helpers/VATHelper';
import { newCustomer } from '@/models/customer';
import SelectCustomerModal from '@/views/customers/tables/SelectCustomer.modal.vue';

export default {
    components: {
        SearchInput,
        SelectCustomerModal
    },

    mixins: [formRulesMixin, screenSizeMixin],

    props: {
        value: {
            required: true,
            type: Object
        },
        type: {
            required: true,
            type: String
        },
        historical: {
            default: false,
            type: Boolean
        },
        isCreatingNewDocument: {
            default: true,
            type: Boolean
        },
        invoicableNumberText: {
            default: 'Maken',
            type: String
        }
    },

    data() {
        return {
            customerTable: CustomersTable,
            openAddCustomerDialog: false,
            createNewCustomerData: null
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        isUserVATLiable() {
            return isVatLiable(this.currentUserData.settings.vatLiable);
        },
        document: {
            get() {
                return this.value;
            },
            set(localDocument) {
                this.$emit('input', localDocument);
            }
        },
        canShowDueDate() {
            return this.isDocumentCreditnote() === false;
        },
        canShowUploadFileForm() {
            return this.isDocumentHistoricalDocument();
        },
        canShowInvoicableNumberForm() {
            return this.isDocumentHistoricalDocument();
        },
        canShowInvoiceNumberForm() {
            return this.isDocumentCreditnote();
        },
        canShowBTWMedecontractantForm() {
            if (!this.document.customer) {
                return false;
            }

            return (
                this.document.customer.type === BELGIAN_COMPANY &&
                this.document.customer.VATLiable === true &&
                this.isUserVATLiable &&
                this.currentUserData.settings.btwMedecontractant
            );
        }
    },

    watch: {
        document: {
            handler(newVal) {
                this.$emit('input', newVal);
            },
            deep: true
        }
    },

    methods: {
        searchCustomer() {
            this.$refs.selectTableComponent.open();
        },
        handleSelectedCustomer(customer) {
            this.document.customer = customer;
        },
        isDocumentCreditnote() {
            return this.type === 'creditnote';
        },
        isDocumentHistoricalDocument() {
            return this.historical;
        },
        openAddNewCustomerModal() {
            this.createNewCustomerData = newCustomer();
            this.openAddCustomerDialog = true;
        }
    }
};
</script>
