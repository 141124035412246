<template>
    <span>
        <chip v-if="sendtocustomer" color="green">
            <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                    <v-icon v-bind="attrs" small @click="$emit('click', $event)" v-on="on"
                        >mdi-send-check-outline</v-icon
                    >
                </template>
                <span> {{ $t('income.invoice_send') }}</span>
            </v-tooltip>
        </chip>

        <chip v-if="!sendtocustomer" color="grey">
            <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                    <v-icon v-bind="attrs" small @click="$emit('click', $event)" v-on="on">mdi-send</v-icon>
                </template>
                <span> {{ $t('income.not_yet_send') }}</span>
            </v-tooltip>
        </chip>
    </span>
</template>

<script>
import Chip from './Chip.vue';
export default {
    components: { Chip },
    props: ['sendtocustomer']
};
</script>

<style lang="scss" scoped></style>
