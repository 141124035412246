export const parseNumberBE = (value: string | null): number => {
    if (value != null) {
        return parseFloat(value.replace(',', '.'));
    } else {
        return 0;
    }
};

export type Address = {
    street: string;
    number: string;
    zipcode: string;
    city: string;
};

export const formatAddress = (address: Partial<Address>): string => {
    return `${address.street || ''} ${address.number || ''} ${address.zipcode || ''} ${address.city || ''}`;
};
