<template>
    <component :is="amountComponentFactory" v-model="currentExpense" />
</template>

<script>
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { PRIVATE } from '@/config/constants';
import AmountsGeneralVue from './AmountsGeneral.vue';
import AmountForPrivateSupplier from './AmountForPrivateSupplier.vue';

export default {
    mixins: [formRulesMixin],
    props: ['value'],

    computed: {
        amountComponentFactory() {
            if (!this.currentExpense.supplier) {
                throw new Error('Must first pick supplier');
            }
            switch (this.currentExpense.supplier.type) {
                case PRIVATE:
                    return AmountForPrivateSupplier;
                default:
                    return AmountsGeneralVue;
            }
        },

        currentExpense: {
            get() {
                return this.value;
            },
            set(localDocument) {
                this.$emit('input', localDocument);
            }
        }
    }
};
</script>
