<template>
    <div class="SearchInput d-flex align-center">
        <text-field
            v-model="innerValue"
            outlined
            :label="label"
            dense
            hide-details="auto"
            :value="value"
            persistent-hint
            :hint="hint"
            @input="handleInputChange"
        />
        <v-icon class="icon" data-action="open-table-search" @click="handleClickedSearch"> mdi-magnify </v-icon>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number],
            default: ''
        },
        hint: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            innerValue: this.value
        };
    },

    watch: {
        value(newValue) {
            this.innerValue = newValue;
        }
    },

    methods: {
        handleClickedSearch() {
            this.$emit('clicked-search');
        },
        handleInputChange() {
            this.$emit('input', this.innerValue);
        }
    }
};
</script>

<style lang="scss" scoped>
.SearchInput {
    display: flex;
    align-items: center;
}

.icon {
    height: 44px;
    width: 44px;
}
</style>
