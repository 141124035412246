<template>
    <v-card class="dailyreceiptNotYetBooked_Card mb-4">
        <v-card-text>
            <p class="text-h4 text--primary">{{ $t('general.quarter') }} {{ quarter }}</p>
            <div class="text--primary">Er zijn {{ total }} dagontvangsten nog niet geboekt</div>
            <submit-button
                :data-action="`submit-quarter-${quarter}`"
                class="mt-4"
                small
                color="primary"
                :disabled="buttonDisabled"
                @click="$emit('book-dailyreceipts')"
            >
                Inboeken
            </submit-button>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        total: {
            type: Number
        },
        quarter: {
            type: Number
        }
    },

    computed: {
        buttonDisabled() {
            return this.total === 0;
        }
    }
};
</script>
