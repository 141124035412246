import axios from 'axios';
import { generateEndpoint } from '@/services/config';

const BASE_URL = generateEndpoint('/api/proactivesmartnotifications');

export function changeIncomeCheck({
    year,
    amountEuroExclVAT
}: {
    year: number;
    amountEuroExclVAT: number;
}): Promise<string[]> {
    return axios
        .get(`${BASE_URL}/changeIncome`, {
            params: {
                year,
                amountEuroExclVAT
            }
        })
        .then((response) => response.data);
}
