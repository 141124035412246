export const invoicableTransformCurrencyFieldToIntegers = (invoicable, type) => {
    let copyOfFile = invoicable.file;

    const invoicableCopy = JSON.parse(JSON.stringify(invoicable));

    invoicableCopy.discount = invoicableCopy.discount ? parseFloat(invoicableCopy.discount) : 0;

    if (invoicableCopy.historical === false) {
        invoicableCopy[`${type}Number`] = invoicableCopy[`${type}Number`]
            ? parseInt(invoicableCopy[`${type}Number`])
            : null;
    }

    invoicableCopy.items.forEach((item, index) => {
        invoicableCopy.items[index].amount = parseFloat(invoicableCopy.items[index].amount);
        invoicableCopy.items[index].discount = invoicableCopy.items[index].discount
            ? parseFloat(invoicableCopy.items[index].discount)
            : 0;
        invoicableCopy.items[index].price = parseFloat(invoicableCopy.items[index].price);
    });

    invoicableCopy.file = copyOfFile;
    invoicableCopy.type = type;

    return invoicableCopy;
};
