<template>
    <div>
        <view-layout>
            <template #content>
                <div v-if="currentExpense" id="rowWrapper">
                    <v-row>
                        <v-col id="formColumn" cols="12" :lg="hasFile ? 5 : 12">
                            <div v-if="numberOfToProcessFiles" class="d-flex justify-space-between align-center mb-3">
                                <p class="subtitle-1 pl-1">
                                    {{ totalNumberOfToProcessFiles - numberOfToProcessFiles }} van de
                                    {{ totalNumberOfToProcessFiles }} uitgaven verwerkt
                                </p>
                                <submit-button small @click="skipCurrentExpenseFile">Overslaan</submit-button>
                            </div>

                            <v-form v-if="currentUserData" id="expenseForm" ref="form" v-model="valid">
                                <v-stepper v-model="stepper" vertical>
                                    <div class="v-stepper-wrapper">
                                        <v-stepper-step
                                            editable
                                            :complete="stepper > 1"
                                            step="1"
                                            data-action="open-supplier-info"
                                        >
                                            {{ $t('addExpense.supplier_info') }}
                                        </v-stepper-step>
                                        <v-stepper-content
                                            :class="{ 'stepper-content-active': stepper == 1 }"
                                            :editable="stepGeneralDataComplete && stepSupplierDataComplete"
                                            step="1"
                                        >
                                            <v-row>
                                                <v-col cols="12" sm="12" order="10">
                                                    <search-input
                                                        id="searchSupplier"
                                                        :label="$t('general.search_supplier')"
                                                        :value="
                                                            currentExpense.supplier
                                                                ? currentExpense.supplier.company.name
                                                                : ''
                                                        "
                                                        @clicked-search="searchSupplier"
                                                    />
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="6" order="10">
                                                    <v-btn
                                                        :disabled="!stepSupplierDataComplete"
                                                        color="primary"
                                                        small
                                                        @click="stepper++"
                                                        >{{ $t('general.next') }}</v-btn
                                                    >
                                                </v-col>
                                            </v-row>
                                        </v-stepper-content>
                                    </div>

                                    <div class="v-stepper-wrapper">
                                        <v-stepper-step
                                            :editable="stepSupplierDataComplete"
                                            :complete="stepper > 2"
                                            step="2"
                                            data-action="open-basic-info"
                                        >
                                            <base-icon-right i18n-path="tooltips.addExpense.Basicinformation" small>
                                                {{ $t('general.basicinformation') }}
                                            </base-icon-right>
                                        </v-stepper-step>
                                        <v-stepper-content step="2" :class="{ 'stepper-content-active': stepper == 2 }">
                                            <v-row>
                                                <v-col cols="12" md="6">
                                                    <date-field
                                                        id="date"
                                                        v-model="currentExpense.documentDate"
                                                        :label="$t('general.invoice_date')"
                                                        outlined
                                                        persistent-hint
                                                        dense
                                                        required
                                                        :rules="requiredRules"
                                                    />
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <date-field-month-year
                                                        id="fiscaledate"
                                                        date-picker-id="fiscaledatePicker"
                                                        :value="formatDate(currentExpense.date)"
                                                        :label="$t('addExpense.fiscale_date')"
                                                        outlined
                                                        persistent-hint
                                                        dense
                                                        required
                                                        :rules="requiredRules"
                                                        @input="changeDate"
                                                    />
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <date-field
                                                        id="dueDate"
                                                        v-model="currentExpense.dueDate"
                                                        :label="$t('general.due_date')"
                                                        outlined
                                                        persistent-hint
                                                        dense
                                                    />
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <text-field
                                                        id="reference"
                                                        v-model="currentExpense.reference"
                                                        :label="$t('general.reference')"
                                                        outlined
                                                        persistent-hint
                                                        dense
                                                    />
                                                </v-col>

                                                <v-col cols="12" sm="12" order="10">
                                                    <div>
                                                        <base-icon-right i18n-path="tooltips.addExpense.isCreditnote">
                                                            <v-checkbox
                                                                id="isCreditnote"
                                                                v-model="currentExpense.isCreditnote"
                                                                hide-details="auto"
                                                                dense
                                                                :label="$t('expenses.is_creditnote')"
                                                            />
                                                        </base-icon-right>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="6" order="10">
                                                    <v-btn
                                                        :disabled="!stepGeneralDataComplete"
                                                        color="primary"
                                                        small
                                                        @click="stepper++"
                                                        >{{ $t('general.next') }}</v-btn
                                                    >
                                                </v-col>
                                            </v-row>
                                        </v-stepper-content>
                                    </div>

                                    <div class="v-stepper-wrapper">
                                        <v-stepper-step
                                            :editable="stepGeneralDataComplete"
                                            :complete="stepper > 3"
                                            step="3"
                                            data-action="open-amounts"
                                        >
                                            Bedragen
                                        </v-stepper-step>
                                        <v-stepper-content step="3" :class="{ 'stepper-content-active': stepper == 3 }">
                                            <amounts v-if="stepGeneralDataComplete" v-model="currentExpense" />
                                            <v-row>
                                                <v-col cols="12" sm="6" order="10">
                                                    <v-btn
                                                        :disabled="!stepAmountDataComplete"
                                                        color="primary"
                                                        small
                                                        @click="stepper++"
                                                        >{{ $t('general.next') }}</v-btn
                                                    >
                                                </v-col>
                                            </v-row>
                                        </v-stepper-content>
                                    </div>

                                    <div class="v-stepper-wrapper">
                                        <v-stepper-step
                                            :editable="stepAmountDataComplete"
                                            :complete="stepper > 4"
                                            step="4"
                                            data-action="open-deductability"
                                        >
                                            <base-icon-right i18n-path="tooltips.addExpense.deductability" small>
                                                {{ $t('addExpense.cost_categories') }}
                                            </base-icon-right>
                                        </v-stepper-step>
                                        <v-stepper-content step="4" :class="{ 'stepper-content-active': stepper == 4 }">
                                            <select-expense-category v-model="currentExpense" />

                                            <v-row>
                                                <v-col cols="12" sm="6" order="10">
                                                    <v-btn
                                                        :disabled="!stepSelectExpenseCategoryComplete"
                                                        color="primary"
                                                        small
                                                        @click="stepper++"
                                                        >{{ $t('general.next') }}</v-btn
                                                    >
                                                </v-col>
                                            </v-row>
                                        </v-stepper-content>
                                    </div>

                                    <div class="v-stepper-wrapper">
                                        <v-stepper-step
                                            :editable="stepSelectExpenseCategoryComplete"
                                            :complete="stepper > 5"
                                            :step="5"
                                            data-action="open-comments"
                                        >
                                            <base-icon-right i18n-path="tooltips.addExpense.extraInfo" small>
                                                {{ $t('addExpense.extra_info') }}
                                            </base-icon-right>
                                        </v-stepper-step>
                                        <v-stepper-content
                                            :step="5"
                                            :class="{
                                                'stepper-content-active': stepper == 5
                                            }"
                                        >
                                            <v-row>
                                                <v-col cols="12" md="12" order="12">
                                                    <text-area
                                                        v-model="currentExpense.description"
                                                        :label="$t('general.description')"
                                                        outlined
                                                        dense
                                                        auto-grow
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-stepper-content>
                                    </div>
                                </v-stepper>
                            </v-form>

                            <v-card class="pa-5 mt-10 mb-10">
                                <v-card-header>
                                    <template #title>
                                        {{ $t('general.status') }}
                                    </template>
                                </v-card-header>

                                <v-checkbox
                                    v-model="currentExpense.payed"
                                    :label="$t('general.payed')"
                                    outlined
                                    dense
                                    hide-details
                                />
                            </v-card>

                            <v-card class="pa-5">
                                <v-card-header>
                                    <template #title> {{ $t('general.actions') }} </template>
                                </v-card-header>
                                <v-col cols="12" md="12" class="pa-0">
                                    <submit-button
                                        v-if="numberOfToProcessFiles === 0"
                                        :data-action="
                                            isCreatingNewExpense
                                                ? 'create-expense-and-overview'
                                                : 'update-expense-and-overview'
                                        "
                                        block
                                        color="primary"
                                        size="large"
                                        :disabled="!valid || !checkValid"
                                        class="mb-3"
                                        @click="runMiddleware(createAndOverviewSubmitMiddleware)"
                                    >
                                        {{
                                            isCreatingNewExpense
                                                ? $t('addExpense.create_expense')
                                                : $t('addExpense.save_expense')
                                        }}
                                    </submit-button>
                                    <submit-button
                                        v-if="numberOfToProcessFiles > 0"
                                        :data-action="
                                            isCreatingNewExpense
                                                ? 'create-expense-and-overview'
                                                : 'update-expense-and-overview'
                                        "
                                        block
                                        color="primary"
                                        size="large"
                                        :disabled="!valid || !checkValid"
                                        class="mb-3"
                                        @click="runMiddleware(createAndGoToNextExpenseFileSubmitMiddleware)"
                                    >
                                        {{ $t('addExpense.create_expense') }}
                                    </submit-button>
                                </v-col>
                            </v-card>
                        </v-col>
                        <v-col v-if="hasFile" id="pdfColumn" cols="12" lg="7">
                            <component :is="viewComponent" :src="fileUrl" :file-name="fileName" />
                        </v-col>
                    </v-row>
                </div>
            </template>
        </view-layout>

        <loader v-if="loading" />
        <select-table-component
            ref="selectTableComponentSupplier"
            :title="$t('general.select_supplier')"
            :input-component="suppliersTable"
            :add-button-text="$t('general.create_supplier')"
            @selected-item="handleSelectedSupplier"
            @new-item="newSupplier"
        />

        <confirmation-dialog
            v-if="showDialog"
            v-model="showDialog"
            v-bind="dialogAttributes.attributes"
            @click-positive="dialogAttributes.actions.clickPositive"
            @click-negative="dialogAttributes.actions.clickNegative"
        />
    </div>
</template>

<script>
// --- State ---
import { mapActions, mapState } from 'vuex';

// --- Components ---
import Loader from '@/components/Loader';

// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { middlewareMixin } from '@/mixins/middlewareMixin';

// --- Helper ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import mime from 'mime-types';

// --- Constants ---
import { BELGIAN_COMPANY, COMPANY_INSIDE_EU, COMPANY_OUTSIDE_EU } from '@/config/constants';
import ViewLayout from '@/components/ViewLayout';

// --- Modules ---
import _ from 'lodash';
import SuppliersTable from '@/components/DataTables/SuppliersTable';
import { newExpense } from '../models/expense';
import SubmitButton from '@/components/SubmitButton.vue';
import SearchInput from '@/components/SearchInput.vue';
import SelectTableComponent from '@/components/SelectTableComponent.vue';
import NumberField from '@/components/FormComponents/NumberField.vue';
import VCardHeader from '@/components/vCardHeader.vue';
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import dayjs from 'dayjs';
import SelectExpenseCategory from './addExpense/components/SelectExpenseCategory.vue';
import BaseIconRight from '@/components/BaseIconRight.vue';
import PDFViewer from '@/components/PDFViewer.vue';

import { isVatLiable } from '@/helpers/VATHelper';
import FileViewer from '@/components/FileViewer.vue';
import { getExpenseFileById } from '@/services/expensefiles';
import { createExpense, getExpense, updateExpense } from '@/services/expenses';
import { expenseCheckIfFileExistMixin } from '@/mixins/expenses/expenseCheckIfFileExistMixin';
import DateFieldMonthYear from '@/components/FormComponents/DateFieldMonthYear.vue';
import Amounts from '@/views/addExpense/components/Amounts';
import { useSupplierStore } from '../store/modules/suppliers';

export default {
    components: {
        Loader,
        PDFViewer,
        ViewLayout,
        SearchInput,
        SubmitButton,
        SelectTableComponent,
        NumberField,
        ConfirmationDialog,
        VCardHeader,
        SelectExpenseCategory,
        BaseIconRight,
        Amounts,
        FileViewer,
        DateFieldMonthYear
    },

    mixins: [formRulesMixin, middlewareMixin, expenseCheckIfFileExistMixin],

    setup() {
        const { newSupplier } = useSupplierStore();

        return { newSupplier };
    },

    data() {
        const baseSubmitMiddleware = [this.checkDate, this.checkPriceExclHigherThanPriceIncl];
        const baseSubmitEndMiddleware = [this.confirmSubmit, this.submitForm];

        return {
            BELGIAN_COMPANY,
            COMPANY_OUTSIDE_EU,
            COMPANY_INSIDE_EU,
            baseVATLiableMiddleware: [
                ...baseSubmitMiddleware,
                this.checkPriceExclAndInclEUVATLiable,
                ...baseSubmitEndMiddleware
            ],
            baseNonVATLiableMiddleware: [
                ...baseSubmitMiddleware,
                this.checkPriceExclAndInclEUNotVATLiable,
                ...baseSubmitEndMiddleware
            ],
            showDialog: false,
            valid: true,
            loading: false,
            stepper: 1,
            suppliersTable: SuppliersTable,
            expensefile: null,
            currentExpense: null
        };
    },

    computed: {
        ...mapState(['selectedYear']),
        ...mapState('auth', ['currentUserData']),
        ...mapState('expensefiles', ['expenseFileIdsUserWantsToProcess', 'totalExpenseFileIdsUserWantsToProcess']),
        numberOfToProcessFiles() {
            return this.expenseFileIdsUserWantsToProcess.length;
        },
        totalNumberOfToProcessFiles() {
            return this.totalExpenseFileIdsUserWantsToProcess ?? 0;
        },
        fileName() {
            if (!this.expensefile) {
                return;
            }

            return this.expensefile.originalFileName;
        },
        fileUrl() {
            if (!this.expensefile) {
                return;
            }
            return this.expensefile.url;
        },
        hasFile() {
            return !!this.expensefile;
        },
        viewComponent() {
            if (!this.expensefile) {
                return;
            }

            if (this.isPDFFile(this.fileUrl)) {
                return PDFViewer;
            }

            return FileViewer;
        },
        isVATLiable() {
            return isVatLiable(this.currentUserData.settings.vatLiable);
        },
        createAndNewSubmitMiddleware() {
            if (this.isVATLiable) {
                return this.baseVATLiableMiddleware;
            } else {
                return this.baseNonVATLiableMiddleware;
            }
        },
        createAndOverviewSubmitMiddleware() {
            return [...this.createAndNewSubmitMiddleware, this.redirectToOverview];
        },
        createAndGoToNextExpenseFileSubmitMiddleware() {
            return [
                ...this.createAndNewSubmitMiddleware,
                this.markCurrentExpenseFileAsHandledAndRedirectToNextExpenseFile
            ];
        },
        isCreatingNewExpense() {
            return this.currentExpense.id === null;
        },
        totalExpenseAmountInclVATEuro() {
            return this.currentExpense.amountEuroInclVAT ?? 0;
        },
        totalVATEuro() {
            return this.totalExpenseAmountInclVATEuro - this.totalInvoiceAmountExclVATEuro;
        },
        totalInvoiceAmountExclVATEuro() {
            return this.currentExpense.amountEuroExclVAT ?? 0;
        },
        checkValid() {
            if (this.currentExpense.supplier === null) {
                return false;
            }

            if (this.currentExpense.expensecategoryId === null) {
                return false;
            }

            return true;
        },
        stepGeneralDataComplete() {
            return Boolean(this.currentExpense.date);
        },
        stepSupplierDataComplete() {
            return !!this.currentExpense.supplier;
        },
        stepSelectExpenseCategoryComplete() {
            let result = false;

            const currentExpense = this.currentExpense;

            result = currentExpense.expensecategoryId;

            return !!result;
        },
        stepAmountDataComplete() {
            if (!this.currentExpense.amountEuroInclVAT || !this.currentExpense.amountEuroExclVAT) {
                return false;
            }

            return true;
        },

        categoryName() {
            return this.currentExpense.expensecategory.name;
        }
    },

    watch: {
        'currentExpense.date': 'checkExpenseExists',
        'currentExpense.supplier': function (newValue, oldValue) {
            if (oldValue && newValue?.type !== oldValue?.type) {
                this.currentExpense = newExpense({
                    id: this.currentExpense.id
                });
                this.handleSelectedSupplier(newValue);
            }
        },
        'currentExpense.amountEuroInclVAT': 'checkExpenseExists'
    },

    async created() {
        this.startLoading();

        try {
            if (this.$route.params.id) {
                await this.fetchExpense();

                this.expensefile = this.currentExpense.expensefile;
            } else {
                if (!this.$route.query.expenseFileId) {
                    this.$router.push({ name: 'dashboard' });
                }

                this.expensefile = await getExpenseFileById(this.$route.query.expenseFileId);
                this.$set(this, 'currentExpense', newExpense());
            }
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
            if (e.response && (e.response.status === 404 || e.response.status === 403)) {
                this.$router.push({ name: 'dashboard' });
            }
        } finally {
            this.stopLoading();
        }
    },

    methods: {
        ...mapActions('expenses', ['newExpenseEvent']),
        ...mapActions('expensefiles', [
            'skipCurrentProcessExpenseFile',
            'markCurrentProcessExpenseFileAsHandled',
            'setToProcessExpenseFiles'
        ]),
        ...mapActions(['startLoading', 'stopLoading']),
        skipCurrentExpenseFile() {
            this.skipCurrentProcessExpenseFile();
            this.redirectToNextExpenseFile();
        },
        resetForm() {
            this.stepper = 1;
            this.$nextTick(() => {
                this.$refs.form.resetValidation();
            });
        },
        isPDFFile(fileURL) {
            if (_.isNil(fileURL)) {
                throw new Error('Empty file url');
            }
            return mime.lookup(fileURL) === 'application/pdf';
        },
        async fetchExpense() {
            this.$set(this, 'currentExpense', this.preprocessFetchedExpense(await getExpense(this.$route.params.id)));
        },
        preprocessFetchedExpense(expense) {
            return {
                ...expense,
                expensecategoryId: expense.expensecategoryId
            };
        },
        async submitForm(next) {
            try {
                this.loading = true;

                // Set correct values
                const expenseCopy = this.setCorrectValues(this.currentExpense);

                expenseCopy.supplierId = expenseCopy.supplier.id;
                delete expenseCopy.supplier;

                if (this.isCreatingNewExpense) {
                    expenseCopy.expenseFileId = this.expensefile.id;

                    await createExpense(expenseCopy);
                    this.resetForm();
                } else {
                    await updateExpense(expenseCopy);
                    await this.fetchExpense();
                }

                this.stepper = 1;

                this.$notify({
                    title: this.isCreatingNewExpense
                        ? this.$t('addExpense.expense_created_success')
                        : this.$t('addExpense.expense_updated_success'),
                    text: this.isCreatingNewExpense
                        ? this.$t('addExpense.expense_created_success')
                        : this.$t('addExpense.expense_updated_success'),
                    type: 'success'
                });

                this.newExpenseEvent();

                if (next) {
                    return next();
                }
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.showDialog = false;
                this.loading = false;
            }
        },
        setCorrectValues(expenseInput) {
            let expense = _.cloneDeep(expenseInput);

            expense.isCreditnote = !!expense.isCreditnote;
            expense.description = expense.description || '';

            expense = this.setNumbersToFloats(expense);

            return expense;
        },
        setNumbersToFloats(currentExpense) {
            const copy = _.cloneDeep(currentExpense);

            copy.amountEuroInclVAT = copy.amountEuroInclVAT || 0;
            copy.amountEuroExclVAT = copy.amountEuroExclVAT || 0;

            return copy;
        },
        searchSupplier() {
            this.$refs.selectTableComponentSupplier.open();
        },
        handleSelectedSupplier(supplier) {
            this.currentExpense.supplier = supplier;
        },
        confirmSubmit(next) {
            let message;

            if (
                this.currentExpense.supplier.type === COMPANY_OUTSIDE_EU ||
                this.currentExpense.supplier.type === COMPANY_INSIDE_EU ||
                !this.isVATLiable
            ) {
                message = this.$t('expense.confirm_no_vat', {
                    totalExpenseAmountInclVATEuro: this.$options.filters.numericFormat(
                        this.totalExpenseAmountInclVATEuro
                    ),
                    categoryName: this.categoryName
                });
            } else {
                message = this.$t('expense.confirm', {
                    totalExpenseAmountInclVATEuro: this.$options.filters.numericFormat(
                        this.totalExpenseAmountInclVATEuro
                    ),
                    totalVAT: this.$options.filters.numericFormat(this.totalVATEuro),
                    categoryName: this.categoryName
                });
            }

            this.setDialogAttributes(
                {
                    title: this.$t('general.confirmation'),
                    message
                },
                {
                    clickPositive: this.clickPositive.bind(this, next)
                }
            );
            this.showDialog = true;
        },
        checkDate(next) {
            const yearOfExpense = dayjs(this.currentExpense.date, 'YYYY-MM-DD').year();

            if (this.selectedYear == yearOfExpense) {
                return next();
            }

            const clickPositive = () => {
                this.showDialog = false;
                return next();
            };

            const clickNegative = () => {
                this.showDialog = false;
            };

            this.dialogAttributes = {
                attributes: {
                    title: this.$t('general.confirmation'),
                    message: `Is de aankoopdatum (${dayjs(this.currentExpense.date, 'YYYY-MM-DD').format(
                        'DD-MM-YYYY'
                    )}) zeker correct?`,
                    negativeButton: this.$t('general.cancel'),
                    positiveButton: this.$t('general.confirm'),
                    negativeColor: 'message',
                    positiveColor: 'green'
                },
                actions: {
                    clickPositive: clickPositive,
                    clickNegative: clickNegative
                }
            };
            this.showDialog = true;
        },
        checkPriceExclAndInclEUVATLiable(next) {
            function satisfyCondition() {
                if (
                    this.isVATLiable &&
                    this.currentExpense.supplier.type === COMPANY_INSIDE_EU &&
                    this.currentExpense.amountEuroInclVAT !== this.currentExpense.amountEuroExclVAT
                ) {
                    return true;
                }

                return false;
            }

            if (!satisfyCondition.call(this)) {
                return next();
            }

            this.setDialogAttributes(
                {
                    dataId: 'amountEuroInclAndExcl_VATLiable_EU_fictiveVAT',
                    title: this.$t(
                        'addExpense.validationMiddleware.amountEuroInclAndExcl_VATLiable_EU_fictiveVAT.title'
                    ),
                    message: this.$t(
                        'addExpense.validationMiddleware.amountEuroInclAndExcl_VATLiable_EU_fictiveVAT.content'
                    )
                },
                {
                    clickPositive: this.clickPositive.bind(this, next)
                }
            );

            this.showDialog = true;
        },
        checkPriceExclAndInclEUNotVATLiable(next) {
            function satisfyCondition() {
                if (
                    this.isVATLiable === false &&
                    this.currentExpense.supplier.type === COMPANY_INSIDE_EU &&
                    this.currentExpense.amountEuroInclVAT === this.currentExpense.amountEuroExclVAT
                ) {
                    return true;
                }

                return false;
            }

            if (!satisfyCondition.call(this)) {
                return next();
            }

            this.setDialogAttributes(
                {
                    dataId: 'amountEuroInclAndExcl_NOT_VATLiable_EU',
                    title: this.$t('addExpense.validationMiddleware.amountEuroInclAndExcl_NOT_VATLiable_EU.title'),
                    message: this.$t('addExpense.validationMiddleware.amountEuroInclAndExcl_NOT_VATLiable_EU.content'),
                    negativeColor: 'green',
                    positiveColor: 'red'
                },
                {
                    clickPositive: this.clickPositive.bind(this, next)
                }
            );

            this.showDialog = true;
        },
        checkPriceExclHigherThanPriceIncl(next) {
            function satisfyCondition() {
                if (this.currentExpense.amountEuroInclVAT < this.currentExpense.amountEuroExclVAT) {
                    return true;
                }

                return false;
            }

            if (!satisfyCondition.call(this)) {
                return next();
            }

            this.setDialogAttributes(
                {
                    dataId: 'amountEuroInclLowerThanIncl',
                    title: this.$t('addExpense.validationMiddleware.amountEuroInclLowerThanIncl.title'),
                    message: this.$t('addExpense.validationMiddleware.amountEuroInclLowerThanIncl.content'),
                    positiveColor: 'red',
                    positiveButton: this.$t('general.change')
                },
                {
                    clickPositive: this.clickNegative.bind(this, next),
                    clickNegative: this.clickNegative.bind(this, next)
                }
            );

            this.showDialog = true;
        },
        redirectToOverview() {
            this.$router.push({
                name: 'expenses'
            });
        },
        markCurrentExpenseFileAsHandledAndRedirectToNextExpenseFile() {
            this.markCurrentProcessExpenseFileAsHandled();
            this.redirectToNextExpenseFile();
        },
        redirectToNextExpenseFile() {
            if (this.expenseFileIdsUserWantsToProcess.length === 0) {
                return this.$router.replace({
                    name: 'expenses'
                });
            }

            this.$router.replace(`/add-expense?expenseFileId=${this.expenseFileIdsUserWantsToProcess[0]}`);
        },
        formatDate() {
            if (!this.currentExpense.date) {
                return null;
            }

            return dayjs(this.currentExpense.date, 'YYYY-MM-DD').format('YYYY-MM');
        },
        changeDate(value) {
            this.currentExpense.date = `${value}-01`;
        }
    },
    beforeRouteLeave(to, from, next) {
        if (from.name !== to.name) {
            this.setToProcessExpenseFiles([]);
        }

        return next();
    }
};
</script>
<style scoped>
#rowWrapper {
    max-height: calc(100vh - 148px);
    overflow: hidden;
}

#rowWrapper > .row {
    max-height: calc(100vh - 148px);
    height: calc(100vh - 148px);
}

#rowWrapper > .row > #formColumn {
    max-height: calc(100vh - 148px);
    height: calc(100vh - 148px);
    overflow: scroll;
}

#pdfColumn {
    max-height: calc(100vh - 148px);
    height: calc(100vh - 148px);
    overflow: hidden;
}

#pdfColumn,
#formColumn {
    padding-top: 0px !important;
}
#formColumn {
    padding-right: 5px !important;
}
</style>
