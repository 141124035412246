<template>
    <base-bottom-sheet
        v-if="currentUserData && isAddInvoiceitemOpen"
        v-model="isAddInvoiceitemOpen"
        transition="scale-transition"
        inset
        retain-focus
        scrollable
        eager
    >
        <template #header>
            <bottom-sheet-header>
                {{ isCreatingNewInvoiceitem ? $t('addInvoiceitem.title') : $t('addInvoiceitem.title_edit') }}
            </bottom-sheet-header>
        </template>
        <template #content>
            <add-invoiceitem-form
                :input-data="currentInvoiceitem"
                :is-creating-new-invoiceitem="isCreatingNewInvoiceitem"
                :can-user-have-vat="canHaveVAT(currentUserData.settings.vatLiable)"
                @submit="handleSubmit"
            />

            <loader v-if="loading" />
        </template>
    </base-bottom-sheet>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import BottomSheetHeader from './BottomSheetHeader';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import _ from 'lodash';
import AddInvoiceitemForm from '../AddInvoiceitemForm.vue';
import Loader from '../Loader.vue';
import { isVatLiable } from '@/helpers/VATHelper';

export default {
    components: {
        BottomSheetHeader,
        AddInvoiceitemForm,
        Loader
    },

    props: ['units'],

    data() {
        return {
            loading: false
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        ...mapFields('bottomSheets', ['isAddInvoiceitemOpen', 'currentInvoiceitem', 'isCreatingNewInvoiceitem'])
    },

    watch: {
        currentInvoiceitem: {
            immediate: true,
            handler() {
                if (!this.isCreatingNewInvoiceitem) {
                    this.currentInvoiceitem.priceInclVAT =
                        this.currentInvoiceitem.priceExclVAT * (1 + this.currentInvoiceitem.VATPercentage / 100);
                }
            }
        }
    },

    methods: {
        ...mapActions('invoiceitems', ['createInvoiceitem', 'updateInvoiceitem']),
        ...mapActions('bottomSheets', ['closeAddInvoiceitem']),
        canHaveVAT: isVatLiable,
        async handleSubmit() {
            const invoiceitemCopy = _.cloneDeep(this.currentInvoiceitem);

            try {
                this.loading = true;
                if (this.isCreatingNewInvoiceitem) {
                    await this.createInvoiceitem(invoiceitemCopy);
                    notify.call(this, {
                        title: this.$t('general.succesfuly_created'),
                        text: this.$t('general.succesfuly_created')
                    });
                } else {
                    await this.updateInvoiceitem(invoiceitemCopy);
                    notify.call(this, {
                        title: this.$t('general.succesfuly_saved'),
                        text: this.$t('general.succesfuly_saved')
                    });
                }

                this.$store.dispatch('invoiceitems/newInvoiceitemEvent');

                // Close bottom sheet when submit successful.
                this.closeAddInvoiceitem();
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
