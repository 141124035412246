import _ from 'lodash';
import { IInvoicableItem, Invoicable } from './types';

// total = amount * price
// totalExclVAT = amount * price * discount
// totalVAT = total VAT
// totalAllIncl = amount & price & discount & VAT

/**
 * Calculates the total of an item incl discount and VAT for the given item
 */
export const calculateTotalWithDiscountAndVATForOneItem = (item: IInvoicableItem): number => {
    const total = totalWithoutDiscountAndWithoutVAT(item);

    if (item.isDiscountPercentage) {
        return total * (1 - item.discount / 100) * (1 + item.VATPercentage / 100);
    }

    return (total - item.discount) * (1 + item.VATPercentage / 100);
};

/**
 * Calculates the total of an item incl discount but excl VAT
 */
export const calculateTotalWithDiscountForOneItem = (item: IInvoicableItem): number => {
    const total = item.priceExclVAT * item.amount;

    if (item.isDiscountPercentage) {
        return total * (1 - item.discount / 100);
    }

    return total - item.discount;
};

/**
 * Calculates the total of an invoicable incl discount but excl VAT
 * @param invoicable
 * @returns number
 */
export const calculateTotalDiscountSubstractedFromItemsWithoutInvoiceDiscount = (invoicable: Invoicable): number => {
    return invoicable.items.reduce((total, item) => total + calculateTotalWithDiscountForOneItem(item), 0);
};

/**
 * Calculates the total of the invoice without discounts and without vat
 */
export const calculateTotal = (invoicable: Invoicable): number => {
    return invoicable.items.reduce((total, item) => total + totalWithoutDiscountAndWithoutVAT(item), 0);
};

/**
 * Calcualte the discount amount of one item
 */
export const calculateDiscountItem = (item: IInvoicableItem): number => {
    if (item.isDiscountPercentage) {
        return (totalWithoutDiscountAndWithoutVAT(item) * item.discount) / 100;
    }

    return item.discount;
};

function totalWithoutDiscountAndWithoutVAT(item: IInvoicableItem) {
    return item.amount * item.priceExclVAT;
}

/**
 * Calculates discount on the items
 */
export const calculateTotalItemDiscount = (items: IInvoicableItem[]): number => {
    return items.reduce((accumulator, item) => accumulator + calculateDiscountItem(item), 0);
};

/**
 * Calculates total discount on the invoice (TOTAL, so not the discount on each item individually)
 */
export const calculateGlobalDiscount = (invoicable: Invoicable): number => {
    if (!invoicable.discount) {
        return 0;
    }

    if (invoicable.isDiscountPercentage) {
        return (
            (calculateTotal(invoicable) - calculateTotalItemDiscount(invoicable.items)) * (invoicable.discount / 100)
        );
    }
    return invoicable.discount;
};

function itemsAllSameVat(items: IInvoicableItem[]) {
    const vats = _.uniq(items.map((item) => item.VATPercentage));

    if (vats.length === 1) {
        return true;
    }

    return false;
}

function getAllItemsVat(items: IInvoicableItem[]) {
    const vats = _.uniq(items.map((item) => item.VATPercentage));
    return vats[0];
}

export const calculateTotalVAT = (invoicable: Invoicable): number => {
    if (itemsAllSameVat(invoicable.items)) {
        // All items have the same VAT so we can multiply with one number
        return calculateTotalExclVAT(invoicable) * (getAllItemsVat(invoicable.items) / 100);
    } else {
        if (invoicable.discount !== 0) {
            throw new Error('cannot have global discount on invoice with different VAT percentages');
        }
        return calculateTotalVATForItems(invoicable.items);
    }
};

const calculateTotalVATForItems = (items: IInvoicableItem[]) => {
    return items.reduce(
        (acc, item) =>
            acc + (totalWithoutDiscountAndWithoutVAT(item) - calculateDiscountItem(item)) * (item.VATPercentage / 100),
        0
    );
};

export const calculateTotalInclVAT = (invoicable: Invoicable): number => {
    const totalExclVatWithoutDiscount = calculateTotal(invoicable);
    const totalVATOnInvoice = calculateTotalVAT(invoicable);
    const discountItems = calculateTotalItemDiscount(invoicable.items);
    const totalDiscount = calculateGlobalDiscount(invoicable);
    return totalExclVatWithoutDiscount - discountItems - totalDiscount + totalVATOnInvoice;
};

export const calculateTotalExclVAT = (invoicable: Invoicable): number => {
    const totalExclVatWithoutDiscount = calculateTotal(invoicable);
    const discountItems = calculateTotalItemDiscount(invoicable.items);
    const totalDiscount = calculateGlobalDiscount(invoicable);
    return totalExclVatWithoutDiscount - discountItems - totalDiscount;
};
