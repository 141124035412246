<template>
    <div class="invoice-table">
        <pagination-data-table
            v-if="invoiceIncomes.data"
            id="invoicesTable"
            ref="paginationDataTable"
            :loading="loading"
            :headers="headers"
            :pagination="pagination"
            :pagination-data="invoiceIncomes"
            @refetch-data="$_handlePaginationRefresh"
            @sort-by="$_filtering_handleSortColumn"
            @sort-desc="$_filtering_handleSortDirectionDesc"
            @click-row="handleClickRow"
        >
            <template #[`item.invoiceNumber`]="{ item }">
                {{ item.draft === false ? item.invoiceNumber : '-' }}
            </template>

            <template #[`item.date`]="{ item }">
                {{ item.date | formatDate }}
            </template>
            <template #[`item.amountEuroExclVAT`]="{ item }">
                € {{ item.amountEuroExclVAT || 0 | numericFormat }}
            </template>
            <template #[`item.amountEuroInclVAT`]="{ item }">
                € {{ item.amountEuroInclVAT || 0 | numericFormat }}
            </template>
            <template #[`item.total`]="{ item }"> € {{ item.amountEuroInclVAT || 0 | numericFormat }} </template>

            <template #[`item.actions`]="{ item }">
                <v-menu bottom left>
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" data-action="open-menu" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-list dense>
                            <v-list-item v-if="item.url && item.draft === false" @click="downloadIncomePDF(item.url)">
                                <v-list-item-title>
                                    {{ $t('general.download') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="item.historical === false" :to="/edit-invoice/ + item.id">
                                <v-list-item-title>
                                    {{ $t('general.edit') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="item.historical" :to="/edit-historical-invoice/ + item.id">
                                <v-list-item-title>
                                    {{ $t('general.edit') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="!item.payed && item.draft === false" @click="togglePayment(item)">
                                <v-list-item-title>
                                    {{ $t('income.add_payment') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="item.payed && item.draft === false" @click="togglePayment(item)">
                                <v-list-item-title>
                                    {{ $t('income.remove_payment') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="!item.sendtocustomer && item.draft === false"
                                @click="toggleSendStatus(item)"
                            >
                                <v-list-item-title>
                                    {{ $t('income.did_send_to_customer') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="item.sendtocustomer && item.draft === false"
                                @click="toggleSendStatus(item)"
                            >
                                <v-list-item-title>
                                    {{ $t('income.did_not_send_to_customer') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                data-action="delete"
                                @click.stop="
                                    {
                                        dialog = true;
                                        selectedInvoice = item.id;
                                    }
                                "
                            >
                                <v-list-item-title class="red--text">
                                    {{ $t('general.delete') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </template>
        </pagination-data-table>

        <confirmation-dialog
            v-if="dialog"
            v-model="dialog"
            :title="$t('general.delete')"
            :message="$t('general.confirm_delete_message')"
            :negative-button="$t('general.cancel')"
            :positive-button="$t('general.delete')"
            @click-positive="removeInvoice"
            @click-negative="dialog = false"
        />
    </div>
</template>

<script>
// --- State ---
import { mapActions, mapState } from 'vuex';
// --- Components ---
import ConfirmationDialog from '../Dialogs/ConfirmationDialog.vue';
// --- Helpers ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';

import { filteringMixin } from '../../mixins/filtering';
import PaginationDataTable from './PaginationDataTable.vue';
import { isVatLiable } from '@/helpers/VATHelper';
import {
    deleteInvoice,
    getNotYetBookedInvoicesPagination,
    togglePayedInvoice,
    toggleSendToCustomerInvoice
} from '@/services/invoice';

export default {
    components: {
        ConfirmationDialog,
        PaginationDataTable
    },

    mixins: [filteringMixin],

    props: {
        filtering: {
            type: Object,
            required: false,
            default: () => {
                return null;
            }
        },
        clickable: {
            type: Boolean,
            default: false
        }
    },

    data() {
        const currentUserData = this.$store.state.auth.currentUserData;
        const VATLiable = isVatLiable(currentUserData.settings.vatLiable);
        let sortableColumns = ['date'];

        if (VATLiable) {
            sortableColumns.push('amountEuroInclVAT', 'amountEuroExclVAT');
        } else {
            sortableColumns.push('total');
        }

        const headers = [
            {
                text: this.$t('general.invoice_number'),
                align: 'start',
                value: 'invoiceNumber',
                sortable: sortableColumns.includes('invoiceNumber')
            },
            {
                text: this.$t('general.client'),
                value: 'customer.company.name',
                sortable: sortableColumns.includes('customer.company.name')
            },
            { text: this.$t('general.date'), value: 'date', sortable: sortableColumns.includes('date') },
            VATLiable
                ? [
                      {
                          text: this.$t('general.total_excl_vat'),
                          value: 'amountEuroExclVAT',
                          sortable: sortableColumns.includes('amountEuroExclVAT')
                      },
                      {
                          text: this.$t('general.total_incl_vat'),
                          value: 'amountEuroInclVAT',
                          sortable: sortableColumns.includes('amountEuroInclVAT')
                      }
                  ]
                : {
                      text: this.$t('general.total'),
                      value: 'amountEuroExclVAT',
                      sortable: sortableColumns.includes('total')
                  },
            {
                text: this.$t('general.actions'),
                value: 'actions',
                sortable: false
            }
        ].flat();

        return {
            headers,
            dialog: false,
            dialogDeletePayment: false,
            loading: false,
            invoiceIncomes: {
                data: []
            },
            query: {
                status: null
            },
            sortableColumns
        };
    },

    computed: {
        ...mapState('incomes', ['newInvoiceEvents']),
        ...mapState(['selectedYear'])
    },

    watch: {
        newInvoiceEvents: {
            handler() {
                this.wrapperFunction();
            },
            deep: true
        }
    },
    created() {
        this.wrapperFunction();
    },
    methods: {
        ...mapActions(['startLoading', 'stopLoading']),
        async wrapperFunction() {
            this.loading = true;
            try {
                this.invoiceIncomes = await getNotYetBookedInvoicesPagination(this.selectedYear, {
                    pagination: {
                        force: true,
                        currentPage: this.pagination.currentPage,
                        limit: this.pagination.limit
                    },
                    query: this.query
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        downloadIncomePDF(url) {
            window.open(url);
        },
        async togglePayment(item) {
            try {
                this.startLoading();
                await togglePayedInvoice(item.id);
                item.payed = !item.payed;

                notify.call(this, {
                    title: this.$t('income.payed_success'),
                    text: this.$t('income.payed_success')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
                this.dialogDeletePayment = false;
            }
        },
        async toggleSendStatus(item) {
            try {
                this.startLoading();
                await toggleSendToCustomerInvoice(item.id);
                item.sendtocustomer = !item.sendtocustomer;

                notify.call(this, {
                    title: this.$t('income.updated_success'),
                    text: this.$t('income.updated_success')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
                this.dialogDeletePayment = false;
            }
        },
        async removeInvoice() {
            this.startLoading();
            this.dialog = false;
            try {
                await deleteInvoice(this.selectedInvoice);
                await this.wrapperFunction();
                notify.call(this, {
                    title: this.$t('income.deleted_invoice_succesfuly'),
                    text: this.$t('income.deleted_invoice_succesfuly')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
            }
        },
        handleClickRow(item) {
            this.$emit('click-row', item);
        }
    }
};
</script>

<style lang="scss" scoped>
.v-card-header {
    display: flex;
}
</style>
