<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template></page-header
            >
        </template>
        <template #content>
            <v-tabs v-model="tab">
                <v-tab>{{ $t('general.invoices') }}</v-tab>
                <v-tab>{{ $t('general.creditnotes') }}</v-tab>
                <v-tab>{{ $t('general.expenses') }}</v-tab>
                <v-tab>{{ $t('general.dailyreceipts') }}</v-tab>
            </v-tabs>

            <TableCard>
                <template #content>
                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <InvoiceIncomeTableNotYetBooked clickable @click-row="openInvoice" />
                        </v-tab-item>
                        <v-tab-item>
                            <CreditnoteIncomeTableNotYetBooked @click-row="openCreditnote" />
                        </v-tab-item>
                        <v-tab-item>
                            <ExpensesTableNotYetBooked @click-row="openExpense" />
                        </v-tab-item>
                        <v-tab-item>
                            <DailyreceiptsNotYetBooked />
                        </v-tab-item>
                    </v-tabs-items>
                </template>
            </TableCard>
        </template>
    </view-layout>
</template>

<script>
// --- Components ---
import InvoiceIncomeTableNotYetBooked from '@/components/DataTables/InvoiceIncomeTableNotYetBooked';
import CreditnoteIncomeTableNotYetBooked from '@/components/DataTables/CreditnoteIncomeTableNotYetBooked';
import ExpensesTableNotYetBooked from '@/components/DataTables/ExpensesTableNotYetBooked';
import DailyreceiptsNotYetBooked from '@/components/DailyreceiptsNotYetBooked';
import PageHeader from '@/components/PageHeader';
import { screenSizeMixin } from '../mixins/screenSizeMixin';
import TableCard from '@/components/TableCard';

// --- State ---
import ViewLayout from '@/components/ViewLayout.vue';

const INVOICE_TAB = 0;
const CREDITNOTE_TAB = 1;
const EXPENSE_TAB = 2;
const DAILYRECEIPTS_TAB = 3;

export default {
    components: {
        InvoiceIncomeTableNotYetBooked,
        CreditnoteIncomeTableNotYetBooked,
        ExpensesTableNotYetBooked,
        DailyreceiptsNotYetBooked,
        PageHeader,
        TableCard,
        ViewLayout
    },
    mixins: [screenSizeMixin],

    data() {
        return {
            tab: 0,
            filtering: {
                status: null,
                q: null
            }
        };
    },

    created() {
        if (this.$route.query?.page === 'expenses') {
            this.tab = EXPENSE_TAB;
        } else if (this.$route.query?.page === 'invoices') {
            this.tab = INVOICE_TAB;
        } else if (this.$route.query?.page === 'creditnotes') {
            this.tab = CREDITNOTE_TAB;
        } else if (this.$route.query?.page === 'dailyreceipts') {
            this.tab = DAILYRECEIPTS_TAB;
        }
    },

    methods: {
        openCreditnote(creditnote) {
            let name;

            if (!creditnote.historical) {
                name = 'book-creditnote';
            } else {
                name = 'book-historical-creditnote';
            }

            return this.$router.push({
                name,
                params: {
                    id: creditnote.id
                }
            });
        },
        openInvoice(invoice) {
            let name;

            if (!invoice.historical) {
                name = 'book-invoice';
            } else {
                name = 'book-historical-invoice';
            }

            return this.$router.push({
                name,
                params: {
                    id: invoice.id
                }
            });
        },
        openExpense(expense) {
            return this.$router.push({
                name: 'book-expense',
                params: {
                    id: expense.id
                }
            });
        }
    }
};
</script>
