<template>
    <div data-id="SuppliersTable" class="billed_income-table">
        <v-row class="filtering-container">
            <v-col cols="12" md="12">
                <pagination-search v-model="query.q" data-action="search" />
            </v-col>
        </v-row>
        <pagination-data-table
            v-if="suppliers.data"
            ref="paginationDataTable"
            :headers="headers"
            :loading="loading"
            :pagination="pagination"
            :pagination-data="suppliers"
            sort-by="date"
            :class="{ clickableFirstItem: clickable }"
            @refetch-data="$_handlePaginationRefresh"
            @sort-by="$_filtering_handleSortColumn"
            @sort-desc="$_filtering_handleSortDirectionDesc"
            @click-row="handleClickRow"
        >
            <template #[`item.address`]="{ item }">
                {{ getFormattedAddress(item.address) }}
            </template>
            <template #[`item.company.number`]="{ item }">
                <template v-if="item.type !== PRIVATE"> {{ item.address.country }}{{ item.company.number }} </template>
            </template>
            <template #[`item.actions`]="{ item }">
                <v-menu bottom left>
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-list dense>
                            <v-list-item @click="store.editSupplier(item)">
                                <v-list-item-title>
                                    {{ $t('general.edit') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                @click.stop="
                                    {
                                        dialog = true;
                                        selectedSupplier = item.id;
                                    }
                                "
                            >
                                <v-list-item-title class="red--text">
                                    {{ $t('general.delete') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </template>
        </pagination-data-table>

        <confirmation-dialog
            v-if="dialog"
            v-model="dialog"
            :title="$t('general.delete')"
            :message="$t('general.confirm_delete_message')"
            :loading="loading"
            :negative-button="$t('general.cancel')"
            :positive-button="$t('general.delete')"
            @click-positive="removeSupplier"
            @click-negative="dialog = false"
        />
    </div>
</template>

<script>
import ConfirmationDialog from '../Dialogs/ConfirmationDialog.vue';
import PaginationSearch from '../PaginationSearch.vue';
import { formatAddress } from '@/helpers/stringFormats';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { filteringMixin, tableName } from '../../mixins/filtering';
import { clickableRowTableMixin } from '../../mixins/clickableRowTableMixin';
import PaginationDataTable from './PaginationDataTable.vue';
import { PRIVATE } from '@/config/constants';
import { useSupplierStore } from '@/store/modules/suppliers';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        ConfirmationDialog,
        PaginationSearch,
        PaginationDataTable
    },
    mixins: [filteringMixin, clickableRowTableMixin],

    props: {
        search: {
            type: String,
            default: null
        },

        clickable: {
            type: Boolean,
            default: false
        }
    },

    setup() {
        const store = useSupplierStore();
        return { store };
    },

    data() {
        const sortableColumns = [];

        return {
            PRIVATE,
            headers: [
                {
                    text: this.$t('general.company_name'),
                    align: 'start',
                    value: 'company.name',
                    sortable: sortableColumns.includes('company.name')
                },
                {
                    text: this.$t('general.company_number'),
                    value: 'company.number',
                    sortable: sortableColumns.includes('company.number')
                },
                {
                    text: this.$t('general.email'),
                    value: 'company.email',
                    sortable: sortableColumns.includes('company.email')
                },
                { text: this.$t('general.address'), value: 'address', sortable: sortableColumns.includes('address') },
                {
                    text: this.$t('general.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            dialog: false,
            loading: false,
            toDeleteUid: '',
            [tableName]: 'supplierTable',
            selectedSupplier: '',
            suppliers: {
                data: []
            },
            sortableColumns
        };
    },

    watch: {
        'store.newSupplierEvents': {
            deep: true,
            handler: function () {
                this.wrapperFunction();
            }
        }
    },

    created() {
        this.wrapperFunction();
    },

    methods: {
        getFormattedAddress(address) {
            return formatAddress(address);
        },
        async removeSupplier() {
            this.loading = true;
            try {
                await this.store.deleteSupplier(this.selectedSupplier);

                await this.wrapperFunction();
                notify.call(this, {
                    title: this.$t('supplier.supplier_succesfuly_deleted'),
                    text: this.$t('supplier.supplier_succesfuly_deleted')
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
                this.dialog = false;
            }
        },
        async wrapperFunction() {
            this.loading = true;
            this.suppliers = await this.store.getAllSuppliersPagination({
                pagination: {
                    force: true,
                    currentPage: this.pagination.currentPage,
                    limit: this.pagination.limit
                },
                query: this.query
            });
            this.loading = false;
        },
        handleClickRow(item) {
            this.$emit('click-row', item);
        }
    }
});
</script>
