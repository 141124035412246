<template>
    <div>
        <select-table-component
            ref="selectTableComponent"
            :title="$t('general.select_customer')"
            :input-component="customerTable"
            :add-button-text="$t('customer.add_customer')"
            @selected-item="handleSelectedCustomer"
            @new-item="openAddNewCustomerModal"
        />

        <add-customer-modal
            v-if="openAddCustomerDialog"
            v-model="openAddCustomerDialog"
            :current-customer="createNewCustomerData"
        />
    </div>
</template>

<script>
import SelectTableComponent from '@/components/SelectTableComponent.vue';
import CustomersTable from '@/components/DataTables/CustomersTable.vue';
import { newCustomer } from '@/models/customer';
import AddCustomerModal from '../add/components/AddCustomer.modal.vue';

export default {
    components: { SelectTableComponent, AddCustomerModal },
    inheritAttrs: false,

    data() {
        return {
            customerTable: CustomersTable,
            openAddCustomerDialog: false,
            createNewCustomerData: null
        };
    },

    methods: {
        open() {
            this.$refs.selectTableComponent.open();
        },
        openAddNewCustomerModal() {
            this.createNewCustomerData = newCustomer();
            this.openAddCustomerDialog = true;
        },
        handleSelectedCustomer(value) {
            this.$emit('selected-item', value);
        }
    }
};
</script>
