import axios from 'axios';
import { initializePaginationVars } from '@/store/utils';
import { generateEndpoint } from '@/services/config';
import { filteringOptions, PaginationResponse } from '@/common/types';
import { IExpenseFileViewModel } from 'dexxter-types';

const BASE_URL = generateEndpoint('/api/expensefiles');

export function createExpenseFile(file: string | Blob): Promise<IExpenseFileViewModel> {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(`${BASE_URL}`, formData).then((response) => response.data);
}

export function getExpenseFilesPagination(
    options: filteringOptions
): Promise<PaginationResponse<IExpenseFileViewModel>> {
    return axios
        .get(`${BASE_URL}/withoutExpense/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}

export function deleteExpenseFile(id: number): Promise<void> {
    return axios.delete(`${BASE_URL}/${id}`).then((response) => response.data);
}

export function getExpenseFileById(id: number): Promise<IExpenseFileViewModel> {
    return axios.get(`${BASE_URL}/${id}`).then((response) => response.data);
}

export function sendGetTotalUnprocessedExpenseFilesRequest(): Promise<number> {
    return axios.get(`${BASE_URL}/unprocessedFiles/total`).then((response) => response.data);
}
