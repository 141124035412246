<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                    <sub>
                        <tooltip :underlined="false" i18n-path="tooltips.invoiceOverview.title"
                            ><template slot="activator"> <v-icon small> mdi-information </v-icon></template></tooltip
                        >
                    </sub>
                </template>

                <template #menu>
                    <submit-button @click="$router.push('/add-invoice')">
                        <v-icon class="mr-2">mdi-plus</v-icon>
                        {{ $t('income.new_invoice') }}
                    </submit-button>
                    <submit-button
                        :class="{ 'ml-2': isSmallDesktopAndUp }"
                        @click="$router.push('/add-invoice-historical')"
                    >
                        <v-icon class="mr-2">mdi-cloud-upload-outline</v-icon>
                        {{ $t('income.upload_invoice') }}
                    </submit-button>
                </template></page-header
            >
        </template>
        <template #content>
            <TableCard>
                <template #content>
                    <invoice-income-table clickable :filtering="filtering" @click-row="openClick" />
                </template>
            </TableCard>
        </template>
    </view-layout>
</template>

<script>
// --- Components ---
import PageHeader from '@/components/PageHeader';
import TableCard from '@/components/TableCard';
import { screenSizeMixin } from '../../../mixins/screenSizeMixin';
import InvoiceIncomeTable from './components/InvoiceIncomeTable';

// --- State ---
import SubmitButton from '@/components/SubmitButton.vue';
import ViewLayout from '@/components/ViewLayout.vue';

export default {
    components: {
        InvoiceIncomeTable,
        PageHeader,
        TableCard,
        ViewLayout,
        SubmitButton
    },
    mixins: [screenSizeMixin],

    data() {
        return {
            filtering: {
                status: null,
                q: null
            }
        };
    },

    created() {
        if (this.$route.query?.status) {
            this.filtering.status = this.$route.query?.status;
        }
    },

    methods: {
        openClick(invoice) {
            if (invoice.historical && !invoice.url) {
                return this.$router.push({
                    name: 'edit-historical-invoice',
                    params: {
                        id: invoice.id
                    }
                });
            }

            return this.$router.push({
                name: 'invoicable-view',
                params: {
                    type: 'invoice',
                    id: invoice.id
                }
            });
        }
    }
};
</script>
