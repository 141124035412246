<template>
    <div>
        <template v-if="item.draft === true">
            <table-draft-status />
        </template>
        <template v-else>
            <table-send-status :sendtocustomer="item.sendtocustomer" @click.stop="$emit('click-send-icon')" />

            <chip v-if="item.hasCreditnotes" small color="grey">{{ $t('income.invoice_credited') }}</chip>

            <chip v-if="item.hasCreditnotes" small color="grey">{{ $t('income.invoice_credited') }}</chip>

            <chip v-if="invoicePayedInTime(item) === false" color="red">
                <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                        <v-icon v-bind="attrs" small v-on="on">mdi-cash</v-icon>
                    </template>
                    <span> {{ $t('income.invoice_not_payed_in_time') }}</span>
                </v-tooltip>
            </chip>
            <table-paid-status v-else :paid="item.payed" />
        </template>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import Chip from './Chip.vue';
import TableDraftStatus from './TableDraftStatus.vue';
import TableSendStatus from './TableSendStatus.vue';
import TablePaidStatus from './TablePaidStatus.vue';
export default {
    components: { Chip, TableDraftStatus, TableSendStatus, TablePaidStatus },
    props: ['item'],

    methods: {
        invoicePayedInTime(invoice) {
            if (invoice.dueDate && invoice.payed === false) {
                const dueDate = dayjs(invoice.dueDate, 'YYYY-MM-DD');
                const today = dayjs();

                return today.isSameOrBefore(dueDate);
            }

            return true;
        }
    }
};
</script>

<style lang="scss" scoped></style>
