import { findExistingExpense } from '@/services/expenses';
import { apiWarningAndDisplay } from '@/helpers/warningHandling';
import dayjs from 'dayjs';

export const expenseCheckIfFileExistMixin = {
    watch: {
        'currentExpense.date': 'checkExpenseExists',
        'currentExpense.supplier': 'checkExpenseExists',
        'currentExpense.amountEuroInclVAT': 'checkExpenseExists',
        'currentExpense.amountEuroExclVAT': 'checkExpenseExists'
    },

    methods: {
        checkExpenseExists() {
            if (!this.isCreatingNewExpense) {
                return;
            }

            if (
                !this.currentExpense.id &&
                Boolean(this.currentExpense.supplier) &&
                Boolean(this.currentExpense.documentDate) &&
                dayjs(this.currentExpense.documentDate, 'YYYY-MM-DD', true).isValid() &&
                Boolean(this.currentExpense.amountEuroInclVAT)
            ) {
                this.validateExpense();
            }
        },
        validateExpense() {
            findExistingExpense(this.currentExpense).then((result) => {
                if (result.found === true) {
                    apiWarningAndDisplay.call(
                        this,
                        this.$i18n.t('addExpense.found_a_possible_duplicate'),
                        'expense-may-already-exist'
                    );
                }
            });
        }
    }
};
