<template>
    <div id="dailyreceiptNotYetBooked_Wrapper" data-page="book-dailyreceipts">
        <dailyreceipts-not-yet-booked-quarter-card
            v-for="quarter in quarters"
            :key="quarter"
            :total="totalNotYetBookedDailyreceipts[quarter]"
            :quarter="quarter"
            @book-dailyreceipts="bookDailyreceipts(quarter)"
        />

        <loader v-if="loading" />
    </div>
</template>

<script>
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import DailyreceiptsNotYetBookedQuarterCard from './DailyreceiptsNotYetBookedQuarterCard.vue';
import { bookAllExistingDailyreceipts, countNotYetBookedPerQuarter } from '@/services/dailyreceipt';
import { mapState } from 'vuex';
import { Q1, Q2, Q3, Q4 } from '@/config/constants';
// --- State ---
export default {
    components: { DailyreceiptsNotYetBookedQuarterCard },

    data() {
        return {
            loading: false,
            quarters: [1, 2, 3, 4],
            totalNotYetBookedDailyreceipts: {
                1: 0,
                2: 0,
                3: 0,
                4: 0
            }
        };
    },

    computed: {
        ...mapState(['selectedYear'])
    },

    async created() {
        try {
            this.loading = true;

            await this.getDailyreceiptCountData();
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
        } finally {
            this.loading = false;
        }
    },

    methods: {
        async bookDailyreceipts(quarter) {
            try {
                this.loading = true;

                await bookAllExistingDailyreceipts(this.selectedYear, quarter);

                this.$notify({
                    title: this.$t('dailyreceiptsToBook.messages.successfullyBooked.title'),
                    text: this.$t('dailyreceiptsToBook.messages.successfullyBooked.content'),
                    type: 'success'
                });

                await this.getDailyreceiptCountData();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },

        async getDailyreceiptCountData() {
            const result = await countNotYetBookedPerQuarter(this.selectedYear);

            this.totalNotYetBookedDailyreceipts[1] = result[Q1];
            this.totalNotYetBookedDailyreceipts[2] = result[Q2];
            this.totalNotYetBookedDailyreceipts[3] = result[Q3];
            this.totalNotYetBookedDailyreceipts[4] = result[Q4];
        }
    }
};
</script>

<style lang="scss" scoped>
.dailyreceiptNotYetBooked_Wrapper :deep() {
    .dailyreceiptNotYetBooked_Card {
    }
}
</style>
