import { invoicableTypes } from '@/config/constants';

export function currentInvoicableNewInstance(type: 'invoice' | 'creditnote' | 'quotation') {
    const base = {
        id: null,
        [`${type}Number`]: null,
        date: null,
        creator: null,
        customer: null,
        comment: null,
        draft: false,
        items: [],
        discount: null,
        isDiscountPercentage: false,
        amountEuroInclVAT: null,
        vatOnInvoicable: false,
        itemsAllSameVat: false,
        allItemsVat: 0,
        btwMedecontractant: false,
        typeOfService: null,
        createPaymentlink: false
    };

    if (type === invoicableTypes.CREDITNOTE) {
        return {
            ...base,
            invoiceId: null
        };
    }

    if (type === invoicableTypes.CREDITNOTE || type === invoicableTypes.INVOICE) {
        return {
            ...base,
            sendToAccountant: false
        };
    }

    return base;
}

export function newInvoicableItem({
    name = null,
    description = null,
    priceExclVAT = null,
    amount = 1,
    measuringUnit = null,
    VATPercentage = 0,
    discount = null,
    isDiscountPercentage = true
} = {}) {
    return {
        name,
        description,
        priceExclVAT,
        amount,
        VATPercentage,
        measuringUnit,
        discount,
        isDiscountPercentage
    };
}
