import axios from 'axios';
import { generateEndpoint } from '@/services/config';
import { IDailyreceiptViewModel } from 'dexxter-types';

const BASE_URL = generateEndpoint('/api/dailyreceipteincomes');

export function markDays(days: { date: string; status: string }[]): Promise<void> {
    return axios
        .post(`${BASE_URL}/markDays`, {
            days
        })
        .then((response) => response.data);
}

export function getAllDays(): Promise<{ date: string; status: string }[]> {
    return axios.get(`${BASE_URL}/status/all`).then((response) => response.data);
}

export function getDailyreceiptsOfDate(date: string): Promise<IDailyreceiptViewModel[]> {
    return axios.get(`${BASE_URL}/date/${date}`).then((response) => response.data);
}

export function canFillInDayRequest(date: string): Promise<boolean> {
    return axios.get(`${BASE_URL}/${date}/canFillIn`).then((response) => response.data);
}

export function countNotYetBookedPerQuarter(year: number): Promise<{ Q1: number; Q2: number; Q3: number; Q4: number }> {
    return axios.get(`${BASE_URL}/count-not-yet-booked-per-quarter/year/${year}`).then((response) => response.data);
}

export function bookAllExistingDailyreceipts(year: number, quarter: number): Promise<void> {
    return axios.post(`${BASE_URL}/book/${year}/${quarter}`).then((response) => response.data);
}
