<template>
    <chip color="grey">
        <v-tooltip bottom>
            <template #activator="{ on, attrs }">
                <v-icon v-bind="attrs" small v-on="on">mdi-pencil</v-icon>
            </template>
            <span> {{ $t('general.draft') }}</span>
        </v-tooltip>
    </chip>
</template>

<script>
import Chip from './Chip.vue';
export default {
    components: { Chip }
};
</script>

<style lang="scss" scoped></style>
