import { IPopup } from '@/store/types/informationpopup';
import _ from 'lodash';
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { MapStateToComputed } from '../helpers/types';
import { AuthState } from '../store/types/auth';

export const informationpopupMixin = Vue.extend({
    computed: {
        ...(mapState('auth', ['isUserImpersonating']) as MapStateToComputed<{
            isUserImpersonating: AuthState['isUserImpersonating'];
        }>),
        ...mapState('informationpopup', ['allInformationpopups']),
        ...mapFields<{ currentInformationpopup: IPopup | null }>('informationpopup', ['currentInformationpopup']),
        informationpopupPriority(): string[] {
            return [];
        }
    },

    watch: {
        allInformationpopups: {
            immediate: true,
            deep: true,
            handler(newInformationpopups: IPopup[]) {
                if (!this.canSetCurrentInformationpopup(newInformationpopups)) {
                    return;
                }

                this.setCurrentInformationpopupBasedOnPrioritylist(newInformationpopups, this.informationpopupPriority);
            }
        }
    },

    methods: {
        ...mapActions('informationpopup', ['setCurrentInformationpopup']),
        canSetCurrentInformationpopup(popups: IPopup[]) {
            return (
                _.isEmpty(popups) === false &&
                _.isEmpty(this.informationpopupPriority) === false &&
                !this.isUserImpersonating
            );
        },
        setCurrentInformationpopupBasedOnPrioritylist(allPopups: IPopup[], priorityOrder: string[]) {
            for (const priority of priorityOrder) {
                const popupWithHighestPriority = allPopups.find((popup) => popup.name === priority);
                if (popupWithHighestPriority) {
                    this.currentInformationpopup = popupWithHighestPriority;
                    return;
                }
            }

            this.currentInformationpopup = null;
        }
    }
});
