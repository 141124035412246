<template>
    <div class="invoice-table">
        <v-row class="filtering-container">
            <v-col cols="12" md="3">
                <v-select
                    v-model="query.status"
                    single-line
                    :items="statusOptions"
                    :label="$t('general.status')"
                    item-text="label"
                    item-value="value"
                    clearable
                    hide-details
                    dense
                    outlined
                />
            </v-col>
            <v-col cols="12" md="9">
                <pagination-search v-model="query.q" />
            </v-col>
        </v-row>
        <pagination-data-table
            v-if="invoiceIncomes.data"
            id="invoicesTable"
            ref="paginationDataTable"
            :loading="loading"
            :headers="headers"
            :pagination="pagination"
            :pagination-data="invoiceIncomes"
            @refetch-data="$_handlePaginationRefresh"
            @sort-by="$_filtering_handleSortColumn"
            @sort-desc="$_filtering_handleSortDirectionDesc"
            @click-row="handleClickRow"
        >
            <template #[`item.invoiceNumber`]="{ item }">
                {{ item.draft === false ? item.invoiceNumber : '-' }}
                <v-icon v-if="invoiceHasTimeregistration(item)">mdi-timer-outline</v-icon>
            </template>

            <template #[`item.date`]="{ item }">
                {{ item.date | formatDate }}
            </template>
            <template #[`item.dueDate`]="{ item }">
                {{ item.dueDate | formatDate }}
            </template>
            <template #[`item.amountEuroExclVAT`]="{ item }">
                € {{ item.amountEuroExclVAT || 0 | numericFormat }}
            </template>
            <template #[`item.amountEuroInclVAT`]="{ item }">
                € {{ item.amountEuroInclVAT || 0 | numericFormat }}
            </template>
            <template #[`item.total`]="{ item }"> € {{ item.amountEuroInclVAT || 0 | numericFormat }} </template>

            <template #[`item.status`]="{ item }">
                <invoice-table-status :item="item" @click-send-icon="navigateToEmailHistory(item)" />
            </template>

            <template #[`item.actions`]="{ item }">
                <v-menu bottom left>
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" data-action="open-menu" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-list dense>
                            <v-list-item v-if="item.url && item.draft === false" @click="downloadIncomePDF(item.url)">
                                <v-list-item-title>
                                    {{ $t('general.download') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item data-action="copyInvoice" @click="copyInvoice(item)">
                                <v-list-item-title>
                                    {{ $t('general.copy') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="item.historical === false" :to="/edit-invoice/ + item.id">
                                <v-list-item-title>
                                    {{ $t('general.edit') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="item.historical" :to="/edit-historical-invoice/ + item.id">
                                <v-list-item-title>
                                    {{ $t('general.edit') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="!item.draft" @click="openCreditNota(item)">
                                <v-list-item-title>
                                    {{ $t('income.make_creditnote') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="!item.payed && item.draft === false" @click="togglePayment(item)">
                                <v-list-item-title>
                                    {{ $t('income.add_payment') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="item.payed && item.draft === false" @click="togglePayment(item)">
                                <v-list-item-title>
                                    {{ $t('income.remove_payment') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="!item.sendtocustomer && item.draft === false"
                                @click="toggleSendStatus(item)"
                            >
                                <v-list-item-title>
                                    {{ $t('income.did_send_to_customer') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="item.sendtocustomer && item.draft === false"
                                @click="toggleSendStatus(item)"
                            >
                                <v-list-item-title>
                                    {{ $t('income.did_not_send_to_customer') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item data-action="delete" @click.stop="startDeleteMiddleware(item)">
                                <v-list-item-title class="red--text">
                                    {{ $t('general.delete') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </template>
        </pagination-data-table>
        <loader v-if="loading" />

        <confirmation-dialog
            v-if="showDialog"
            v-model="showDialog"
            v-bind="dialogAttributes.attributes"
            @click-positive="dialogAttributes.actions.clickPositive"
            @click-negative="dialogAttributes.actions.clickNegative"
        />
    </div>
</template>

<script>
// --- State ---
import { mapActions, mapState } from 'vuex';
// --- Components ---
import PaginationSearch from '@/components/PaginationSearch.vue';
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
// --- Helpers ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';

import _ from 'lodash';

import { filteringMixin } from '@/mixins/filtering';
import PaginationDataTable from '@/components/DataTables/PaginationDataTable.vue';
import { isVatLiable } from '@/helpers/VATHelper';
import {
    deleteInvoice,
    getInvoicesPagination,
    togglePayedInvoice,
    toggleSendToCustomerInvoice
} from '@/services/invoice';
import InvoiceTableStatus from '@/components/InvoiceTableStatus.vue';
import { middlewareMixin } from '@/mixins/middlewareMixin';
import { invoiceProactiveSmartnotificationsMixin } from '@/mixins/invoiceProactiveSmartnotificationsMixin';

export default {
    components: {
        PaginationSearch,
        ConfirmationDialog,
        PaginationDataTable,
        InvoiceTableStatus
    },

    mixins: [filteringMixin, middlewareMixin, invoiceProactiveSmartnotificationsMixin],

    props: {
        filtering: {
            type: Object,
            required: false,
            default: () => {
                return null;
            }
        },
        clickable: {
            type: Boolean,
            default: false
        }
    },

    data() {
        const currentUserData = this.$store.state.auth.currentUserData;
        const VATLiable = isVatLiable(currentUserData.settings.vatLiable);
        let sortableColumns = ['date', 'dueDate', 'invoiceNumber'];

        if (VATLiable) {
            sortableColumns.push('amountEuroInclVAT');
        } else {
            sortableColumns.push('total');
        }

        const headers = [
            {
                text: this.$t('general.invoice_number'),
                align: 'start',
                value: 'invoiceNumber',
                sortable: sortableColumns.includes('invoiceNumber')
            },
            {
                text: this.$t('general.client'),
                value: 'customer.company.name',
                sortable: sortableColumns.includes('customer.company.name')
            },
            { text: this.$t('general.date'), value: 'date', sortable: sortableColumns.includes('date') },
            { text: this.$t('general.due_date'), value: 'dueDate', sortable: sortableColumns.includes('dueDate') },
            VATLiable
                ? [
                      {
                          text: this.$t('general.total_incl_vat'),
                          value: 'amountEuroInclVAT',
                          sortable: sortableColumns.includes('amountEuroInclVAT')
                      }
                  ]
                : {
                      text: this.$t('general.total'),
                      value: 'amountEuroExclVAT',
                      sortable: sortableColumns.includes('total')
                  },
            { text: this.$t('general.status'), value: 'status', sortable: sortableColumns.includes('status') },

            {
                text: this.$t('general.actions'),
                value: 'actions',
                sortable: false
            }
        ].flat();

        return {
            deleteInvoiceMiddleware: [
                this.confirmationDeleteInvoice,
                this.deleteProactiveSmartnotifications,
                this.removeInvoice
            ],
            headers,
            selectedIncome: '',
            selectedIncomePaymentDelete: '',
            showDialog: false,
            dialogDeletePayment: false,
            loading: false,
            invoiceIncomes: {
                data: []
            },
            selectedExpense: '',
            query: {
                status: null
            },
            statusOptions: [
                {
                    label: this.$t('general.published'),
                    value: 'published'
                },
                {
                    label: this.$t('general.draft'),
                    value: 'draft'
                },
                {
                    label: this.$t('general.payed'),
                    value: 'payed'
                },
                {
                    label: this.$t('general.unpaid'),
                    value: 'unpaid'
                },
                {
                    label: this.$t('general.expired'),
                    value: 'expired'
                },
                {
                    label: this.$t('general.sent'),
                    value: 'sent'
                },
                {
                    label: this.$t('general.not_sent'),
                    value: 'notsent'
                }
            ],
            sortableColumns
        };
    },

    computed: {
        ...mapState('accountantEmailBCC', ['accountantEmailBCCs']),
        ...mapState('incomes', ['newInvoiceEvents']),
        ...mapState(['selectedYear']),
        hasUserAccountantEmailBCCs() {
            if (!this.accountantEmailBCCs) {
                return false;
            }

            return this.accountantEmailBCCs.length > 0;
        }
    },

    watch: {
        filtering: {
            immediate: true,
            handler(newFiltering) {
                if (
                    _.find(this.statusOptions, (option) => {
                        return option.value === newFiltering.status;
                    })
                ) {
                    this.query.status = newFiltering.status;
                }
            }
        },
        newInvoiceEvents: {
            handler() {
                this.wrapperFunction();
            },
            deep: true
        }
    },
    async created() {
        try {
            this.loading = true;
            await Promise.all([this.wrapperFunction()]);
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
        } finally {
            this.loading = false;
        }
    },
    methods: {
        ...mapActions(['startLoading', 'stopLoading']),
        async startDeleteMiddleware(invoice) {
            this.runMiddleware(this.deleteInvoiceMiddleware, invoice);
        },
        copyInvoice(invoice) {
            this.$router.push({
                name: 'add-invoice',
                query: {
                    copyFromInvoiceId: invoice.id
                }
            });
        },
        confirmationDeleteInvoice(next) {
            this.openConfirmationDialog();

            this.setDialogAttributes(
                {
                    title: this.$t('general.delete'),
                    message: this.$t('general.confirm_delete_message')
                },
                {
                    clickPositive: () => {
                        this.clickPositive.call(this, next);
                    }
                }
            );
        },
        navigateToEmailHistory(item) {
            this.$router.push({
                name: 'customer-mail',
                params: {
                    type: 'invoice',
                    id: item.id
                }
            });
        },
        async wrapperFunction() {
            this.loading = true;
            try {
                this.invoiceIncomes = await getInvoicesPagination(this.selectedYear, {
                    pagination: {
                        force: true,
                        currentPage: this.pagination.currentPage,
                        limit: this.pagination.limit
                    },
                    query: this.query
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        downloadIncomePDF(url) {
            window.open(url);
        },
        openCreditNota(item) {
            this.isCreatingNewCreditnote = false;
            this.isAddCreditnoteOpen = true;

            this.$router.push({
                path: `/add-creditnote`,
                query: {
                    invoiceId: item.id
                }
            });
        },
        async togglePayment(item) {
            try {
                this.startLoading();
                await togglePayedInvoice(item.id);
                item.payed = !item.payed;

                notify.call(this, {
                    title: this.$t('income.payed_success'),
                    text: this.$t('income.payed_success')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
                this.dialogDeletePayment = false;
            }
        },
        async toggleSendStatus(item) {
            try {
                this.startLoading();
                await toggleSendToCustomerInvoice(item.id);
                item.sendtocustomer = !item.sendtocustomer;

                notify.call(this, {
                    title: this.$t('income.updated_success'),
                    text: this.$t('income.updated_success')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
                this.dialogDeletePayment = false;
            }
        },
        invoiceHasTimeregistration(invoice) {
            return Array.isArray(invoice.timeregistrations) && invoice.timeregistrations.length > 0;
        },
        async removeInvoice(next, invoice) {
            this.startLoading();
            this.showDialog = false;
            try {
                await deleteInvoice(invoice.id);
                await this.wrapperFunction();
                notify.call(this, {
                    title: this.$t('income.deleted_invoice_succesfuly'),
                    text: this.$t('income.deleted_invoice_succesfuly')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
            }
        },
        handleClickRow(item) {
            this.$emit('click-row', item);
        }
    }
};
</script>

<style lang="scss" scoped>
.v-card-header {
    display: flex;
}
</style>
