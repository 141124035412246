<template>
    <div>
        <v-row data-id="ExpensecategoryTable" class="filtering-container">
            <v-col cols="12" md="3">
                <v-select
                    v-model="query.parentId"
                    :items="parentCategories"
                    :label="$t('expenseCategories.parent_name')"
                    item-text="label"
                    item-value="value"
                    clearable
                    hide-details
                    dense
                    outlined
                />
            </v-col>
            <v-col cols="12" md="9">
                <pagination-search v-model="query.q" />
            </v-col>
        </v-row>
        <pagination-data-table
            ref="paginationDataTable"
            :headers="headers"
            :pagination-data="expensesubcategories"
            :pagination="pagination"
            :loading="loading"
            sort-by="number"
            sort-asc
            dense
            @refetch-data="$_handlePaginationRefresh"
            @sort-by="$_filtering_handleSortColumn"
            @sort-desc="$_filtering_handleSortDirectionDesc"
            @click-row="handleClickRow"
        >
            <template #[`item.name`]="{ item }">
                {{ item.name }}
            </template>
            <template #[`item.parentId`]="{ item }">
                {{ item.parentCategory.name }}
            </template>

            <template #[`item.actions`]="{ item }">
                <v-btn icon color="orange" @click.stop="editExpenseCategory(item)">
                    <v-icon small> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                    v-if="item.deletable === true"
                    icon
                    color="red"
                    @click.stop="
                        {
                            dialog = true;
                            selectedExpenseCategory = item.id;
                        }
                    "
                >
                    <v-icon small> mdi-delete </v-icon>
                </v-btn>
            </template>
        </pagination-data-table>
        <confirmation-dialog
            v-if="dialog"
            v-model="dialog"
            :title="$t('general.delete')"
            :message="$t('general.confirm_delete_message')"
            :loading="loading"
            :negative-button="$t('general.cancel')"
            :positive-button="$t('general.delete')"
            @click-positive="removeExpenseCategory"
            @click-negative="dialog = false"
        />

        <add-expense-category @new-item="wrapperFunction" @updated-item="wrapperFunction" />
    </div>
</template>

<script>
// --- State ---
import { mapActions, mapState } from 'vuex';
// --- Mixins ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { clickableRowTableMixin } from '@/mixins/clickableRowTableMixin';
import { filteringMixin } from '@/mixins/filtering';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import PaginationSearch from '../PaginationSearch.vue';

// --- Components ---
import ConfirmationDialog from '../Dialogs/ConfirmationDialog.vue';

// --- MOdules
import _ from 'lodash';

// --- Constants ---
import { isVatLiable } from '@/helpers/VATHelper';
import AddExpenseCategory from '../BottomSheets/AddExpenseCategory.vue';
import PaginationDataTable from './PaginationDataTable.vue';

export default {
    components: {
        ConfirmationDialog,
        PaginationDataTable,
        AddExpenseCategory,
        PaginationSearch
    },

    mixins: [formRulesMixin, clickableRowTableMixin, filteringMixin],

    data() {
        let sortableColumns = ['name'];

        const headers = [
            {
                text: this.$t('expenseCategories.name'),
                align: 'start',
                value: 'name',
                sortable: sortableColumns.includes('name')
            },
            {
                text: this.$t('expenseCategories.parent_name'),
                value: 'parentId',
                sortable: sortableColumns.includes('parentId')
            },
            {
                text: this.$t('general.actions'),
                value: 'actions',
                sortable: sortableColumns.includes('actions')
            }
        ];

        return {
            expensesubcategories: {
                data: []
            },
            parentCategories: [],
            dialog: false,
            selectedExpenseCategory: '',
            loading: false,
            headers,
            query: {
                parentId: null
            },
            sortableColumns
        };
    },

    watch: {
        async newExpenseCategoryEvents() {
            this.loading = true;
            try {
                await this.wrapperFunction();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        }
    },

    computed: {
        ...mapState('expenseCategories', ['newExpenseCategoryEvents'])
    },

    async created() {
        this.parentCategories = (await this.getExpenseCategories())
            .map((_parentCategory) => {
                return {
                    label: _parentCategory.name,
                    value: _parentCategory.id
                };
            })
            .sort(this.byName);
        this.wrapperFunction();
    },

    methods: {
        ...mapActions('expenseCategories', [
            'getExpenseSubCategoriesPagination',
            'deleteExpenseCategory',
            'toggleVisibleExpenseCategories',
            'getExpenseCategories'
        ]),
        ...mapActions('bottomSheets', ['editExpenseCategory']),
        byName(a, b) {
            return a.label > b.label ? 1 : -1;
        },
        cloneExpensecategories(expensecategories) {
            // Make a deep clone of the original percentages to keep track of changed values.
            return _.cloneDeep(expensecategories);
        },
        canHaveVAT: isVatLiable,
        async wrapperFunction() {
            this.loading = true;

            this.expensesubcategories = await this.getExpenseSubCategoriesPagination({
                pagination: {
                    force: true,
                    currentPage: this.pagination.currentPage,
                    limit: this.pagination.limit
                },
                query: this.query
            });

            this.loading = false;
        },
        async removeExpenseCategory() {
            this.loading = true;
            try {
                await this.deleteExpenseCategory(this.selectedExpenseCategory);

                notify.call(this, {
                    title: this.$t('settings.professional_use_succesfuly_saved'),
                    text: this.$t('settings.professional_use_succesfuly_saved')
                });
                this.dialog = false;

                await this.wrapperFunction();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
                this.dialog = false;
            }
        },
        async toggleExpensecategory(expensecategoryId) {
            this.loading = true;
            try {
                await this.toggleVisibleExpenseCategories(expensecategoryId);

                notify.call(this, {
                    title: this.$t('settings.professional_use_succesfuly_saved'),
                    text: this.$t('settings.professional_use_succesfuly_saved')
                });

                await this.wrapperFunction();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        handleClickRow(item) {
            this.$emit('click-row', item);
        }
    }
};
</script>
<style lang="scss" scoped>
span.subCategory {
    padding-left: 10px;
}
.td-actions {
    display: flex;
}
</style>
