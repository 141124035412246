<template>
    <div>
        <component
            :is="invoicableLayoutComponent"
            v-model="document"
            :historical="historical"
            :is-creating-new-document="isCreatingNewDocument"
            :type="type"
            :calculations="calculations"
            @add-new-item="addNewItem"
            @delete-item="openDeleteItemDialog"
            @edit-item="editItem"
        />

        <confirmation-dialog
            v-if="deleteItemDialog"
            v-model="deleteItemDialog"
            :title="$t('general.delete')"
            :message="$t('general.confirm_delete_message')"
            :negative-button="$t('general.cancel')"
            :positive-button="$t('general.delete')"
            @click-positive="removeItem"
            @click-negative="deleteItemDialog = false"
            @click-outside="deleteItemDialog = false"
        />
        <create-invoicable-item
            ref="createInvoicableitemRef"
            :can-change-vat-of-item="canUserChangeTheVATOfAnItem()"
            :invoiceitem-input="mobileCreateInvoice.invoiceitemModal"
            :creating="mobileCreateInvoice.creatingNewInvoiceitem"
            :index="mobileCreateInvoice.index"
            @create="handleInsertInvoiceitem"
            @update="handleEditInvoiceitem"
        />
    </div>
</template>
<script>
// --- State ---
import { mapState } from 'vuex';
// --- Components ---
// --- Mixins ---
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
// --- Helper ---
import { newInvoicableItem } from '../../models/invoicable';

// --- Constants ---
import { COMPANY_INSIDE_EU, COMPANY_OUTSIDE_EU } from '@/config/constants';

import CreateInvoicableItem from '../BottomSheets/createInvoicableItem.vue';
import NumberField from '../FormComponents/NumberField.vue';
import { isVatLiable } from '@/helpers/VATHelper';
import InvoicableFormInvoicableItemsDesktop from './InvoicableFormInvoicableItemsDesktop.vue';
import InvoicableFormInvoicableItemsPhone from './InvoicableFormInvoicableItemsPhone.vue';

import { calculateTotal, calculateTotalInclVAT, calculateTotalItemDiscount, calculateTotalVAT } from '@/common';

export default {
    components: {
        CreateInvoicableItem,
        NumberField
    },

    mixins: [formRulesMixin, screenSizeMixin],

    props: {
        value: {
            required: true,
            type: Object
        },
        type: {
            required: true,
            type: String
        },
        historical: {
            default: false,
            type: Boolean
        },
        isCreatingNewDocument: {
            default: true,
            type: Boolean
        }
    },

    data() {
        return {
            mobileCreateInvoice: {
                creatingNewInvoiceitem: false,
                invoiceitemModal: null,
                index: -1
            },
            deleteItemDialog: false,
            calculations: {
                discountItems: 0,
                totalVATOnInvoice: 0,
                totalExclVatWithoutDiscount: 0,
                totalAmountInclVatWithDiscount: 0
            }
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        invoicableLayoutComponent() {
            if (this.isPhone) {
                return InvoicableFormInvoicableItemsPhone;
            }

            return InvoicableFormInvoicableItemsDesktop;
        },
        document: {
            get() {
                return this.value;
            },
            set(localDocument) {
                this.$emit('input', localDocument);
            }
        },
        isVATLiable() {
            return isVatLiable(this.currentUserData.settings.vatLiable);
        },
        vatPercentageOnItem() {
            if (!this.document.customer) {
                return 0;
            }

            if (!this.isVATLiable) {
                return 0;
            }

            if (this.document.btwMedecontractant) {
                return 0;
            }

            if (
                this.document.customer.type === COMPANY_INSIDE_EU ||
                this.document.customer.type === COMPANY_OUTSIDE_EU
            ) {
                return 0;
            }

            return this.currentUserData.settings.defaultVATPercentage;
        }
    },

    watch: {
        document: {
            handler(newVal) {
                this.$emit('input', newVal);
            },
            deep: true
        },
        'document.discount': {
            handler: function () {
                this.recalculateTotals();
            }
        },
        'document.isDiscountPercentage': {
            handler: function () {
                this.recalculateTotals();
            }
        },
        'document.items': {
            deep: true,
            immediate: true,

            handler: function () {
                this.itemsVatChanges();
                this.recalculateTotals();
            }
        }
    },

    methods: {
        recalculateTotals() {
            if (this.document && this.document.items) {
                this.calculations.discountItems = calculateTotalItemDiscount(this.document.items);
                this.calculations.totalVATOnInvoice = calculateTotalVAT(this.document);
                this.calculations.totalExclVatWithoutDiscount = calculateTotal(this.document);
                this.calculations.totalAmountInclVatWithDiscount = calculateTotalInclVAT(this.document);
            }
        },
        createNewItem() {
            return newInvoicableItem({
                VATPercentage: this.vatPercentageOnItem
            });
        },
        openDeleteItemDialog(index) {
            this.deleteItemDialog = true;
            this.deletedItemIndex = index;
        },
        addNewItem() {
            this.mobileCreateInvoice.index = -1;
            this.mobileCreateInvoice.invoiceitemModal = this.createNewItem();
            this.mobileCreateInvoice.creatingNewInvoiceitem = true;
            this.$refs.createInvoicableitemRef.openModal();
        },
        editItem(index) {
            this.mobileCreateInvoice.invoiceitemModal = this.document.items[index];
            this.mobileCreateInvoice.index = index;
            this.mobileCreateInvoice.creatingNewInvoiceitem = false;
            this.$refs.createInvoicableitemRef.openModal();
        },
        removeItem() {
            if (this.document.items.length > 0) {
                this.document.items = this.document.items.filter((item, index) => index !== this.deletedItemIndex);
            }

            this.deleteItemDialog = false;
        },
        handleInsertInvoiceitem({ item }) {
            this.document.items.push(item);
            this.$refs.createInvoicableitemRef.closeModal();
        },
        handleEditInvoiceitem({ item, index }) {
            this.$set(this.document.items, index, item);
            this.$refs.createInvoicableitemRef.closeModal();
        },
        itemsVatChanges() {
            if (this.doItemsHaveSameVat()) {
                this.document.itemsAllSameVat = true;
                if (this.document.items.length > 0) {
                    this.document.allItemsVat = this.document.items[0].VATPercentage;
                }
            } else {
                this.document.itemsAllSameVat = false;

                // Reset total discount because this will disappear
                this.document.discount = 0;
            }
        },
        canUserChangeTheVATOfAnItem() {
            if (!this.document.customer) {
                return false;
            }

            if (!this.isVATLiable) {
                return false;
            }

            const customerType = this.document.customer.type;

            if (this.document.btwMedecontractant) {
                return false;
            }

            if (customerType === COMPANY_INSIDE_EU || customerType === COMPANY_OUTSIDE_EU) {
                return false;
            }

            return true;
        },
        doItemsHaveSameVat() {
            let result = true;
            if (this.document.items.length === 0) {
                result = false;
            } else {
                let previousVatPercentage = this.document.items[0].VATPercentage;
                this.document.items.forEach((item) => {
                    if (previousVatPercentage !== item.VATPercentage) {
                        result = false;
                    }
                    previousVatPercentage = item.VATPercentage;
                });
            }

            return result;
        }
    }
};
</script>
