export function newExpense({ id = null } = { id: null }) {
    return {
        id,
        invoiceNumber: null,
        documentDate: null,
        date: null,
        dueDate: null,
        category: null, // Accountant code
        description: null,
        expensecategory: {
            name: null
        },
        expensecategoryId: null, // Type: service, good or investment
        isCreditnote: false,
        amountEuroInclVAT: null,
        amountEuroExclVAT: null,
        payed: false,
        supplier: null,
        reference: null
    };
}
