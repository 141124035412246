import { getDefaultInvoicableLanguages } from '@/helpers/language';
import { reportError } from '@/helpers/logging';
import axios from 'axios';
import { generateEndpoint } from '@/services/config';
import { ILanguageViewModel } from '@/services/invoicableLanguages.types';

const BASE_URL = generateEndpoint('/api/invoicablelanguages');

export function sendGetAllInvoicableLanguagesRequest(): Promise<ILanguageViewModel[]> {
    return axios.get(`${BASE_URL}`).then((response) => response.data);
}

export async function sendGetAllInvoicableLanguagesRequestWithDefaultsIfFail(): Promise<
    Omit<ILanguageViewModel, 'id'>[]
> {
    try {
        return await sendGetAllInvoicableLanguagesRequest();
    } catch (e) {
        reportError(e);
        return getDefaultInvoicableLanguages();
    }
}
