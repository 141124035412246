
import { mapActions, mapState } from 'vuex';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog.vue';
import { notify } from '@/helpers/successNotification';
import { defineComponent } from 'vue';
import { Unit } from '../../services/units.types';

export interface UnitTableEmits {
    'click-row': Unit;
}

export default defineComponent({
    components: {
        ConfirmationDialog
    },

    data() {
        return {
            headers: [
                {
                    text: this.$t('units.singular') as string,
                    align: 'start',
                    value: 'singular'
                },
                { text: this.$t('units.plural') as string, value: 'plural' },
                {
                    text: this.$t('general.actions'),
                    value: 'actions',
                    sortable: false
                }
            ] as const,
            dialog: false,
            loading: false,
            toDeleteUid: '',
            selectedUnit: '',
            units: [] as Unit[]
        };
    },

    computed: {
        ...mapState('units', ['newUnitEvents'])
    },

    watch: {
        newUnitEvents: {
            deep: true,
            handler() {
                this.fetchUnits();
            }
        }
    },
    created() {
        this.fetchUnits();
    },

    methods: {
        ...mapActions('bottomSheets', ['editUnit']),
        ...mapActions('units', ['getUnits', 'deleteUnit']),
        async removeUnit() {
            this.loading = true;
            try {
                await this.deleteUnit(this.selectedUnit);

                notify.call(this, {
                    title: this.$t('settings.succesfuly_saved'),
                    text: this.$t('settings.succesfuly_saved')
                });

                this.fetchUnits();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
                this.dialog = false;
            }
        },
        fetchUnits() {
            this.loading = true;
            this.getUnits()
                .then((units) => {
                    this.units = units;
                })
                .finally(() => (this.loading = false));
        },
        handleClickRow(item: Unit) {
            this.$emit('click-row', item);
        }
    }
});
