<template>
    <div>
        <v-col cols="12" md="12">
            <v-simple-table id="invoiceItemTable" dense>
                <template #default>
                    <thead>
                        <th scope="col" class="text-left"></th>
                        <th scope="col" class="text-left">
                            {{ $t('general.description') }}
                        </th>
                        <th scope="col" class="text-right">
                            {{ $t('general.price') }}
                        </th>
                        <th scope="col" class="text-right">
                            {{ $t('general.amount') }}
                        </th>
                        <th scope="col" class="text-right">
                            {{ $t('general.unitMeasurements') }}
                        </th>
                        <th scope="col" class="text-right">
                            {{ $t('general.VAT') }}
                        </th>
                        <th scope="col" class="text-right">
                            {{ $t('general.discount') }}
                        </th>
                        <th scope="col" class="text-center">
                            {{ isVATLiable ? $t('addInvoiceitem.priceExclVATShort') : $t('addInvoiceitem.priceTotal') }}
                        </th>
                        <th scope="col" class="text-right"></th>
                    </thead>
                    <draggable
                        v-model="document.items"
                        tag="tbody"
                        group="invoiceitems"
                        @start="drag = true"
                        @end="drag = false"
                    >
                        <tr v-for="(item, index) in document.items" :key="item.key" :data-row="index" class="mt-3">
                            <td>
                                <v-icon>mdi-drag-horizontal-variant</v-icon>
                            </td>
                            <td>
                                <span v-html="item.name"></span>
                            </td>
                            <td class="text-right">
                                {{ (item.priceExclVAT || 0) | numericFormat | addEuroSign }}
                            </td>

                            <td class="text-right">
                                {{ item.amount || 0 }}
                            </td>
                            <td class="text-right">
                                {{ item.measuringUnit }}
                            </td>
                            <td class="text-right">{{ item.VATPercentage || 0 }}%</td>
                            <td class="text-right">
                                {{ !item.isDiscountPercentage ? '€' : '' }}{{ (item.discount || 0) | numericFormat
                                }}{{ item.isDiscountPercentage ? '%' : '' }}
                            </td>

                            <td class="text-center td-price">
                                {{ calculateTotalPriceExclVATItem(item) | numericFormat | addEuroSign }}
                            </td>

                            <td class="td-action">
                                <div>
                                    <v-btn
                                        data-action="edit-invoiceitem"
                                        icon
                                        color="primary"
                                        @click.stop="editInvoiceitem(index)"
                                    >
                                        <v-icon small> mdi-pencil </v-icon>
                                    </v-btn>
                                    <v-btn
                                        icon
                                        color="primary"
                                        data-action="delete-invoiceItem"
                                        @click.stop="deleteItem(index)"
                                    >
                                        <v-icon small> mdi-delete </v-icon>
                                    </v-btn>
                                </div>
                            </td>
                        </tr>
                    </draggable>

                    <tbody>
                        <tr v-if="document.items.length === 0">
                            <td colspan="8" class="text-center no-items-td">
                                {{
                                    type === 'quotation'
                                        ? $t('invoiceitem.no_quotation_items')
                                        : $t('invoiceitem.no_invoice_items')
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td class="add-item-td" colspan="8">
                                <v-btn id="add-item" small outlined @click.stop="addNewItem">
                                    {{ addInvoicableItemText(type) }}
                                </v-btn>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="7" class="text-right">
                                {{ $t('general.amount_money') }}
                            </td>
                            <td class="text-center">
                                <h3>
                                    {{ calculations.totalExclVatWithoutDiscount | numericFormat | addEuroSign }}
                                </h3>
                            </td>
                            <td></td>
                        </tr>

                        <tr>
                            <td colspan="7" class="text-right">
                                {{ $t('general.discount_items') }}
                            </td>
                            <td class="text-center">
                                <h3>
                                    {{ calculations.discountItems | numericFormat | addEuroSign }}
                                </h3>
                            </td>
                            <td></td>
                        </tr>
                        <tr v-if="document.itemsAllSameVat">
                            <td colspan="7" class="text-right">
                                {{
                                    type === 'quotation'
                                        ? $t('general.discount_quotation')
                                        : $t('general.discount_invoice')
                                }}
                            </td>
                            <td class="text-center">
                                <number-field
                                    v-model="document.discount"
                                    outlined
                                    dense
                                    :prepend-inner-icon="
                                        document.isDiscountPercentage ? 'mdi-percent' : 'mdi-currency-eur'
                                    "
                                    :rules="discountRules"
                                    @click:prepend-inner="
                                        document.isDiscountPercentage = !document.isDiscountPercentage
                                    "
                                />
                            </td>
                            <td></td>
                        </tr>
                        <template v-if="isVATLiable">
                            <tr>
                                <td colspan="7" class="text-right">
                                    {{ $t('general.amount_excl_vat') }}
                                </td>
                                <td class="text-center">
                                    <h3>
                                        {{
                                            (calculations.totalAmountInclVatWithDiscount -
                                                calculations.totalVATOnInvoice)
                                                | numericFormat
                                                | addEuroSign
                                        }}
                                    </h3>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colspan="7" class="text-right">
                                    {{ $t('accountantTerms.btw_amount') }}
                                </td>
                                <td class="text-center">
                                    <h3>
                                        {{ calculations.totalVATOnInvoice | numericFormat | addEuroSign }}
                                    </h3>
                                </td>
                                <td></td>
                            </tr>
                        </template>
                        <tr>
                            <td colspan="7" class="text-right">
                                {{ isVATLiable ? $t('general.amount_incl_vat') : $t('general.total') }}
                            </td>
                            <td class="text-center">
                                <h3>
                                    {{ calculations.totalAmountInclVatWithDiscount | numericFormat | addEuroSign }}
                                </h3>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </div>
</template>
<script>
// --- State ---
import { mapState } from 'vuex';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
// --- Helper ---
import { calculateDiscountItem } from '@/common';

// --- Constants ---
import { BELGIAN_COMPANY, COMPANY_INSIDE_EU, COMPANY_OUTSIDE_EU, PRIVATE } from '@/config/constants';

import NumberField from '../FormComponents/NumberField.vue';
import { isVatLiable } from '@/helpers/VATHelper';
import draggable from 'vuedraggable';

export default {
    components: {
        NumberField,
        draggable
    },

    mixins: [formRulesMixin],

    props: {
        value: {
            required: true,
            type: Object
        },
        calculations: {
            required: true,
            type: Object
        },
        type: {
            required: true,
            type: String
        },
        historical: {
            default: false,
            type: Boolean
        },
        isCreatingNewDocument: {
            default: true,
            type: Boolean
        }
    },

    data() {
        return {
            PRIVATE: PRIVATE,
            BELGIAN_COMPANY: BELGIAN_COMPANY,
            COMPANY_INSIDE_EU: COMPANY_INSIDE_EU,
            COMPANY_OUTSIDE_EU: COMPANY_OUTSIDE_EU
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        document: {
            get() {
                return this.value;
            },
            set(localDocument) {
                this.$emit('input', localDocument);
            }
        },
        isVATLiable() {
            return isVatLiable(this.currentUserData.settings.vatLiable);
        }
    },

    watch: {
        document: {
            handler(newVal) {
                this.$emit('input', newVal);
            },
            deep: true
        }
    },

    methods: {
        addInvoicableItemText(type) {
            if (type === 'quotation') {
                return this.$t('addIncome.addInvoicable.add_quotation_item');
            }

            return this.$t('addIncome.addInvoicable.add_item');
        },
        deleteItem(index) {
            this.$emit('delete-item', index);
        },
        addNewItem() {
            this.$emit('add-new-item');
        },
        editInvoiceitem(index) {
            this.$emit('edit-item', index);
        },
        calculateTotalPriceExclVATItem(item) {
            return item.priceExclVAT * item.amount - calculateDiscountItem(item);
        }
    }
};
</script>

<style lang="scss" scoped>
.items {
    width: 100%;
}

#invoiceItemTable {
    table {
        th {
            padding: 5px 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        }
        td {
            padding: 8px 10px;
        }
    }
}

tbody {
    tr:hover {
        background-color: transparent !important;
    }
}

td.td-price {
    padding-left: 0px;
    padding-right: 0px;
}

td.td-action {
    vertical-align: baseline;
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 1%;
    white-space: nowrap;
}

td.no-items-td {
    padding: 20px !important;
}

td.add-item-td {
    padding: 10px !important;
}
</style>
