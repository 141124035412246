<template>
    <div>
        <v-row>
            <v-col cols="12" sm="12">
                <search-input
                    id="searchExpensecategory"
                    :label="$t('general.search_expense_category')"
                    :value="currentExpense.expensecategoryId ? currentExpense.expensecategory.name : ''"
                    @clicked-search="searchExpenseCategory"
                />
            </v-col>
        </v-row>

        <select-table-component
            ref="selectTableComponentExpenseCategory"
            i18n-path="tooltips.addExpense.deductability"
            :add-button-text="$t('general.create_expense_category')"
            :title="$t('general.select_expense_category')"
            :input-component="typeOfServiceTable"
            @selected-item="handleSelectedExpenseCategory"
            @new-item="newExpenseCategory"
        />
    </div>
</template>

<script>
// --- State ---
import { mapActions } from 'vuex';

// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';

// --- Helper ---

// --- Modules ---
import ExpensecategoriesTableOnlyCategory from '@/components/DataTables/ExpensecategoriesTableOnlyCategory';
import SearchInput from '@/components/SearchInput.vue';
import SelectTableComponent from '@/components/SelectTableComponent.vue';
export default {
    components: {
        SearchInput,
        SelectTableComponent
    },

    mixins: [formRulesMixin],
    props: ['value'],

    data() {
        return {
            typeOfServiceTable: ExpensecategoriesTableOnlyCategory
        };
    },

    computed: {
        currentExpense: {
            get() {
                return this.value;
            },
            set(localDocument) {
                this.$emit('input', localDocument);
            }
        }
    },

    methods: {
        ...mapActions('bottomSheets', ['newExpenseCategory']),
        searchExpenseCategory() {
            this.$refs.selectTableComponentExpenseCategory.open();
        },
        handleSelectedExpenseCategory(expenseCategory) {
            this.$set(this.currentExpense, 'expensecategory', expenseCategory);
            this.currentExpense.expensecategoryId = expenseCategory.id;
        }
    }
};
</script>
